<app-filter-edit-overlay
  [dataSource]="dataSource"
  [filters]="filters"
  [filter]="item"
  [origin]="dropdownTrigger"
  [theme]="theme"
  (filtersUpdated)="filtersUpdated.emit($event)"
  #filter_edit_overlay="appFilterEditOverlay"
></app-filter-edit-overlay>

<div
  class="filters-item"
  [class.filters-item_bright]="bright"
  [class.filters-item_active]="filter_edit_overlay.isOpened()"
  [appTintColor]="'accentColor' | appThemeColorHexOption: true | async"
  [appTintColorStyle]="tintStyles.Default"
  cdkOverlayOrigin
  #dropdownTrigger="cdkOverlayOrigin"
>
  <div class="filters-item__main">
    <div
      *ngIf="valueFieldDescription"
      class="filters-item__icon"
      [ngClass]="'icon-' + valueFieldDescription.icon"
    ></div>

    <div class="filters-item__title">
      <ng-container *ngIf="!valueLoading">
        <ng-container *ngIf="item.lookup">
          {{ item.lookup.str(fieldPath.join(' '), valueDisplay, item.exclude) }}
        </ng-container>

        <ng-container *ngIf="!item.lookup"> {{ fieldPath.join(' ') }} {{ valueDisplay }} </ng-container>
      </ng-container>

      <span *ngIf="valueLoading" class="stub-text">
        valueLoading
      </span>
    </div>
  </div>

  <div class="filters-item__right">
    <div class="filters-item__action icon-arrow_down_2"></div>
  </div>
</div>
