var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { UniversalAnalyticsService } from '@modules/analytics';
import { FieldElementStyles, FilterElementItem, ListElementItem, TableSettings, VALUE_OUTPUT, ViewContext, ViewContextElement } from '@modules/customize';
import { Input as FieldInput, InputValueType } from '@modules/fields';
import { isSet } from '@shared';
// TODO: Refactor import
import { ElementComponentsService } from '../../../customize-elements/services/element-components/element-components.service';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarService } from '../../services/customize-bar/customize-bar.service';
import { CustomizeBarFilterEditForm } from '../customize-bar-filter-edit/customize-bar-filter-edit.form';
var CustomizeBarFilterEditItemsComponent = /** @class */ (function () {
    function CustomizeBarFilterEditItemsComponent(customizeBarService, customizeBarContext, elementComponentsService, cd, analyticsService) {
        this.customizeBarService = customizeBarService;
        this.customizeBarContext = customizeBarContext;
        this.elementComponentsService = elementComponentsService;
        this.cd = cd;
        this.analyticsService = analyticsService;
        this.addItems = [];
    }
    CustomizeBarFilterEditItemsComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.form.filterElement$(), this.form.inputFieldProvider.value$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var element = _a[0], providerItems = _a[1];
            _this.filterElement = element;
            _this.updateAddItems(providerItems);
            _this.cd.markForCheck();
        });
    };
    CustomizeBarFilterEditItemsComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarFilterEditItemsComponent.prototype.filterNotUsed = function (providerItems) {
        var _this = this;
        if (!this.filterElement || !this.filterElement.layouts[0] || !this.filterElement.layouts[0].dataSource) {
            return providerItems;
        }
        var elementInputs = this.filterElement.layouts[0].dataSource.queryInputs;
        return providerItems
            .filter(function (providerItem) {
            if (!providerItem.field) {
                return true;
            }
            return !elementInputs.find(function (input) { return input.name == providerItem.field.name && input.contextValueStartsWith(['elements', _this.element.uid]); });
        })
            .map(function (providerItem) {
            if (providerItem.children) {
                providerItem.children = _this.filterNotUsed(providerItem.children);
            }
            return providerItem;
        });
    };
    CustomizeBarFilterEditItemsComponent.prototype.updateAddItems = function (providerItems) {
        var mapItem = function (item) {
            if (item.children) {
                return {
                    button: {
                        label: item.label,
                        icon: item.icon
                    },
                    children: item.children
                        .map(function (i) { return mapItem(i); })
                        .sort(function (lhs, rhs) {
                        var lhsSubtitle = lhs.subtitle ? 1 : 0;
                        var rhsSubtitle = rhs.subtitle ? 1 : 0;
                        return lhsSubtitle - rhsSubtitle;
                    })
                };
            }
            else if (item.field) {
                return {
                    option: {
                        value: item,
                        name: item.label,
                        icon: item.icon
                    },
                    subtitle: item.field.name.startsWith('exclude__') ? 'Exclude' : undefined
                };
            }
        };
        this.addItems = this.filterNotUsed(providerItems).map(function (item) { return mapItem(item); });
        this.cd.markForCheck();
    };
    CustomizeBarFilterEditItemsComponent.prototype.getFilterElementComponent = function () {
        return this.filterElement ? this.elementComponentsService.get(this.filterElement) : undefined;
    };
    CustomizeBarFilterEditItemsComponent.prototype.addItem = function (providerItem) {
        var component = this.getFilterElementComponent();
        if (!component) {
            return;
        }
        var element = cloneDeep(this.filterElement);
        var settings = element.layouts[0];
        var input = new FieldInput();
        input.name = providerItem.field.name;
        input.valueType = InputValueType.Context;
        input.contextValue = ['elements', this.element.uid, providerItem.field.name, VALUE_OUTPUT];
        settings.dataSource.queryInputs = settings.dataSource.queryInputs.filter(function (item) { return item.name != providerItem.field.name; }).concat([
            input
        ]);
        component.onCustomized(element);
    };
    CustomizeBarFilterEditItemsComponent.prototype.removeItem = function (name) {
        var component = this.getFilterElementComponent();
        if (!component) {
            return;
        }
        var element = cloneDeep(this.filterElement);
        var settings = element.layouts[0];
        settings.dataSource.queryInputs = settings.dataSource.queryInputs.filter(function (item) { return item.name != name; });
        component.onCustomized(element);
    };
    CustomizeBarFilterEditItemsComponent.prototype.onAddOptionClick = function (option) {
        this.addItem(option.value);
    };
    CustomizeBarFilterEditItemsComponent.prototype.editItem = function (control) {
        var initialValue = cloneDeep(control.controls.settings.value);
        var currentElementStyles = new FieldElementStyles({
            labelStyle: control.controls.label_style.serialize(),
            labelAdditionalStyle: control.controls.label_additional_style.serialize()
        });
        this.customizeBarService
            .customizeColumn({
            context: this.customizeBarContext,
            column: __assign({}, control.controls.settings.value, { field: control.field.field, verboseName: control.controls.settings.value && isSet(control.controls.settings.value['verboseName'])
                    ? control.controls.settings.value['verboseName']
                    : control.field.verboseName, editable: true, 
                // TODO: Refactor workaround for default label additional
                required: true }),
            configurable: {
                verboseName: true,
                labelAdditional: true,
                field: false,
                editable: true,
                editableOnly: true,
                visible: true,
                disable: true,
                params: true,
                value: true,
                elementStyles: true
            },
            title: control.field.verboseName,
            labelAdditional: control.controls.label_additional.value,
            elementStyles: currentElementStyles,
            tooltip: control.controls.tooltip.value,
            tooltipEditable: true,
            viewContext: this.context,
            viewContextElement: this.contextElement,
            append: true
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.type == CustomizeBarEditEventType.Updated) {
                var field = e.args['result'];
                var labelAdditional = e.args['label_additional'];
                var tooltip = e.args['tooltip'];
                var elementStyles = e.args['element_styles'];
                if (field.verboseName == control.field.verboseName) {
                    field.verboseName = undefined;
                }
                control.controls.settings.patchValue(field);
                control.controls.label_style.deserialize(elementStyles.labelStyle);
                control.controls.label_additional.patchValue(labelAdditional);
                control.controls.label_additional_style.deserialize(elementStyles.labelAdditionalStyle);
                control.controls.tooltip.patchValue(tooltip);
                control.markAsDirty();
            }
            else if (e.type == CustomizeBarEditEventType.Canceled) {
                control.controls.settings.patchValue(initialValue);
                control.markAsDirty();
            }
        });
    };
    CustomizeBarFilterEditItemsComponent.prototype.dragDropOption = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.controls.element_inputs.controls, event.previousIndex, event.currentIndex);
            this.form.controls.element_inputs.updateValueAndValidity();
        }
    };
    return CustomizeBarFilterEditItemsComponent;
}());
export { CustomizeBarFilterEditItemsComponent };
