<div class="columns">
  <div class="columns__item" [style.flex-grow]="1">
    <app-element-wrapper>
      <div class="element element_padding-h_normal element_padding-v_normal">
        <div class="element__content">
          <div class="text-block text-block_no-margin-collapse">
            <div class="ql-container ql-snow ql-text-paragraphs ql-theme">
              <div class="ql-editor">
                <h1>Page title</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Dolor sed viverra ipsum nunc aliquet bibendum enim.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-element-wrapper>

    <app-element-wrapper>
      <div class="element element_align-horizontal_left element_padding-h_normal element_padding-v_normal">
        <div class="element__content">
          <div class="collapse-container collapse-container_theme">
            <div class="collapse-container__item">
              <div class="collapse-section collapse-section_theme" [class.collapse-section_opened]="true">
                <div class="collapse-section__header collapse-section__header_top">
                  <div class="collapse-section__header-left">
                    <div class="collapse-section__header-icon icon-payments"></div>
                  </div>
                  <div class="collapse-section__header-main">
                    <div class="collapse-section__header-title">Collapse container</div>
                  </div>
                  <div class="collapse-section__header-right">
                    <div class="collapse-section__header-arrow icon-arrow_up_2"></div>
                  </div>
                </div>
                <div class="collapse-section__content" [class.collapse-section__content_hidden]="false">
                  <div class="collapse-section__content-separator"></div>

                  <app-element-wrapper>
                    <div
                      class="element element_inline element_align-horizontal_left element_padding-h_normal element_padding-v_normal"
                      style="width: 350px;"
                    >
                      <div class="element__content">
                        <div class="field field_manual-margin field_fill-vertical field_theme">
                          <label class="field__label">
                            <span
                              class="field__label-text"
                              [appTextStyle]="fieldElementStyles?.labelStyle"
                              [appTextStyleGlobal]="'fieldLabelTextStyle'"
                            >
                              User E-mail
                            </span>
                          </label>

                          <div class="field__content">
                            <div class="input-icon-wrapper">
                              <div class="input-icon input-icon_left input-icon_theme icon-email"></div>
                              <input
                                class="input input_fill select_fill input_icon-left input_theme input_styles"
                                type="text"
                                placeholder="test@example.com"
                                [appFieldElementStyles]="fieldElementStyles"
                                [appFieldElementStylesApplyGlobal]="false"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </app-element-wrapper>

                  <app-element-wrapper>
                    <div
                      class="element element_inline element_align-horizontal_left element_padding-h_normal element_padding-v_normal"
                      style="width: 350px;"
                    >
                      <div class="element__content">
                        <div class="field field_manual-margin field_fill-vertical field_theme">
                          <label class="field__label">
                            <span
                              class="field__label-text"
                              [appTextStyle]="fieldElementStyles?.labelStyle"
                              [appTextStyleGlobal]="'fieldLabelTextStyle'"
                            >
                              Documents
                            </span>
                            <span
                              class="field__label-optional"
                              [appTextStyle]="fieldElementStyles?.labelAdditionalStyle"
                              [appTextStyleGlobal]="'fieldLabelAdditionalTextStyle'"
                            >
                              Optional
                            </span>
                          </label>

                          <div class="field__content">
                            <div class="upload upload_empty upload_uploadable upload_theme">
                              <div class="upload__placeholder">
                                <div class="upload__placeholder-icon icon-cloud_upload"></div>
                                <div class="upload__placeholder-label">
                                  Drop file here or
                                  <label
                                    class="upload__placeholder-button"
                                    [appTintColor]="
                                      ('accentColor' | appThemeColorHexOption: true | async) || defaultAccentColor
                                    "
                                    [appTintColorStyle]="tintStyles.Default"
                                  >
                                    Choose file
                                  </label>
                                </div>
                              </div>
                              <div class="upload__controls"></div>
                              <label class="upload__overlay"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </app-element-wrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-element-wrapper>
  </div>
  <div class="columns__item" [style.flex-basis.px]="300">
    <app-element-wrapper>
      <div class="element element_padding-h_normal element_padding-v_normal">
        <div class="element__content">
          <div class="list" [class.list_theme]="true">
            <div class="list__header list__header_active inactive">
              <div class="list-header">
                <div class="list-header__row">
                  <div class="list-header__main">
                    <h1 class="list__title">Reviews</h1>
                  </div>
                </div>
              </div>
            </div>

            <div class="list__content list__content_border list__viewport">
              <div class="grid grid_theme">
                <div class="grid__item-inner">
                  <div class="model-card model-card_theme">
                    <div class="model-card__item">
                      <label
                        class="model-card__item-label"
                        [appTextStyle]="fieldElementStyles?.labelStyle"
                        [appTextStyleGlobal]="'fieldLabelTextStyle'"
                      >
                        Name
                      </label>
                      <div class="model-card__item-value">
                        Text fields
                      </div>
                    </div>

                    <div class="model-card__item">
                      <label
                        class="model-card__item-label"
                        [appTextStyle]="fieldElementStyles?.labelStyle"
                        [appTextStyleGlobal]="'fieldLabelTextStyle'"
                      >
                        Status
                      </label>
                      <div class="model-card__item-value">
                        <span class="status color_green_1 background-color_green_2 status_theme">
                          Success
                        </span>
                      </div>
                    </div>

                    <div class="model-card__item">
                      <label
                        class="model-card__item-label"
                        [appTextStyle]="fieldElementStyles?.labelStyle"
                        [appTextStyleGlobal]="'fieldLabelTextStyle'"
                      >
                        Rating
                      </label>
                      <div class="model-card__item-value">
                        <div class="rating">
                          <div class="rating__item">
                            <app-star
                              *ngFor="let item of [true, true, true, true, false]"
                              [size]="16"
                              [leftActive]="item"
                              [rightActive]="item"
                            ></app-star>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-element-wrapper>

    <app-element-wrapper>
      <div class="element element_padding-h_normal element_padding-v_normal">
        <div class="element__content" [style.height.px]="170">
          <div class="widget-element widget-element_manual-margin widget-element_flexible-height">
            <div class="widget widget_theme">
              <div class="widget__header dashboard-item__draggable">
                <div class="widget__header-main">
                  <div class="widget__title">
                    <span class="widget__title-text">Chart</span>
                  </div>
                </div>

                <div class="widget__header-right widget__header-controls">
                  <div class="widget__header-button widget__header-button_icon widget__header-control">
                    <span class="widget__header-button-icon icon-repeat"></span>
                  </div>
                </div>
              </div>

              <div class="widget__body widget__body_responsive-height">
                <app-pie-chart2
                  [datasets]="sampleDatasets"
                  [doughnut]="true"
                  [defaultColors]="defaultChartColors"
                  [animate]="false"
                  [gradient]="false"
                  [interactive]="false"
                  [theme]="true"
                  style="display: block; height: 100%;"
                ></app-pie-chart2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-element-wrapper>
  </div>
</div>

<app-element-wrapper>
  <div class="element element_padding-h_normal element_padding-v_normal">
    <span class="element__content">
      <div class="steps steps_theme">
        <div class="steps__progress steps-progress steps-progress_disable-animations steps-progress_theme">
          <div class="steps-progress__track">
            <div class="steps-progress__track-item"></div>
            <div class="steps-progress__track-item"></div>
            <div class="steps-progress__track-item"></div>
            <div class="steps-progress__track-item"></div>
          </div>
          <div
            *ngIf="('accentColor' | appThemeColorHexOption: true | async) || defaultAccentColor as accentColor"
            class="steps-progress__fill-container"
          >
            <div class="steps-progress__fill-start" [style.background-color]="accentColor"></div>
            <div
              class="steps-progress__fill"
              [style.width.%]="stepsProgress * 100"
              [style.background-color]="accentColor"
            >
              <div class="steps-progress__fill-thumb" [style.border-color]="accentColor"></div>
            </div>
          </div>
        </div>
        <div class="steps__items">
          <div class="steps__item">
            <div class="steps__item-info">
              <div class="steps__item-title">Ordered</div>
            </div>
          </div>
          <div class="steps__item steps__item_current">
            <div class="steps__item-info">
              <div class="steps__item-title">Packed</div>
            </div>
          </div>
          <div class="steps__item steps__item_current">
            <div class="steps__item-info">
              <div class="steps__item-title">Shipped</div>
            </div>
          </div>
          <div class="steps__item steps__item_active">
            <div class="steps__item-info">
              <div class="steps__item-title">Delivered</div>
            </div>
          </div>
        </div>
      </div>
    </span>
  </div>
</app-element-wrapper>

<app-element-wrapper>
  <div class="tabs2">
    <div class="element-wrapper">
      <div class="element-wrapper__inner">
        <div class="tabs2-navigation tabs2-navigation_border tabs2-navigation_theme">
          <div class="tabs2-navigation__item tabs2-navigation__item_active">
            General
          </div>
          <div class="tabs2-navigation__item">
            Metrics
          </div>
          <div class="tabs2-navigation__item">
            Activity
          </div>
        </div>
      </div>
    </div>

    <div class="tabs2__item tabs2__item_active">
      <app-element-wrapper>
        <div class="element element_padding-h_normal element_padding-v_normal">
          <div class="element__content">
            <app-table-stub
              [title]="'Example data'"
              [data]="tableData"
              [rowActions]="true"
              [rowActionsButtons]="['Edit', 'Delete']"
              [selection]="true"
              [textStub]="false"
              [animating]="false"
              [scrollable]="false"
              [theme]="true"
              [actionElementStylesPrimary]="actionElementStylesPrimary"
              [actionElementStylesDefault]="actionElementStylesDefault"
              [fieldElementStyles]="fieldElementStyles"
              [stylesApplyGlobal]="false"
            ></app-table-stub>
          </div>
        </div>
      </app-element-wrapper>
    </div>
  </div>
</app-element-wrapper>
