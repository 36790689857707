import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { FieldElementStyles, TextStyle, TextStyleGlobalName } from '@modules/customize';
import { FieldLabelButton, FormField } from '@modules/fields';

@Component({
  selector: 'app-auto-field-stub',
  templateUrl: './auto-field-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoFieldStubComponent implements OnInit {
  @Input() field: FormField;
  @Input() readonly = false;
  @Input() requiredAsterisk = false;
  @Input() label = true;
  @Input() labelStyle: TextStyle;
  @Input() labelStyleGlobal: TextStyleGlobalName;
  @Input() labelAdditional: string;
  @Input() labelAdditionalStyle: TextStyle;
  @Input() labelAdditionalStyleGlobal: TextStyleGlobalName;
  @Input() elementStyles: FieldElementStyles;
  @Input() errors = true;
  @Input() autofocus = false;
  @Input() truncate = false;
  @Input() manualMargin = false;
  @Input() labelButtons: FieldLabelButton[] = [];

  constructor() {}

  ngOnInit() {}
}
