<app-element-wrapper *ngFor="let item of fieldTypes">
  <app-element-wrapper>
    <div class="element element_inline element_padding-h_normal element_padding-v_normal">
      <div class="element__content">
        <div class="page-title page-title_font-size_s">
          {{ item.label }}
        </div>
      </div>
    </div>
  </app-element-wrapper>

  <hr class="hr_theme" />

  <div class="columns">
    <div class="columns__item" [style.flex-grow]="1">
      <app-element-wrapper [alignHorizontal]="alignHorizontals.Left">
        <div class="element element_inline element_padding-h_normal element_padding-v_normal">
          <div class="element__content">
            <div
              class="field"
              [class.field_manual-margin]="false"
              [class.field_fill-vertical]="false"
              [class.field_theme]="true"
            >
              <div class="field__label">
                <span
                  class="field__label-text"
                  [appTextStyle]="elementStyles?.labelStyle"
                  [appTextStyleGlobal]="'fieldLabelTextStyle'"
                >
                  Normal
                </span>
              </div>
              <div class="field__content">
                <div class="input-icon-wrapper">
                  <input
                    class="input input_styles"
                    [class.input_error]="false"
                    [class.input_wide]="false"
                    [class.input_icon-right]="item.value | appIsSet"
                    [class.input_theme]="true"
                    [appFieldElementStyles]="elementStyles"
                    type="text"
                    [placeholder]="'Enter value...'"
                    [value]="item.value"
                  />

                  <span
                    *ngIf="item.value | appIsSet"
                    class="input-icon input-icon_interactive input-icon_right icon-close"
                    [class.input-icon_theme]="true"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-element-wrapper>
    </div>

    <div class="columns__item" [style.flex-grow]="1">
      <app-element-wrapper [alignHorizontal]="alignHorizontals.Left">
        <div class="element element_inline element_padding-h_normal element_padding-v_normal">
          <div class="element__content">
            <div
              class="field"
              [class.field_manual-margin]="false"
              [class.field_fill-vertical]="false"
              [class.field_theme]="true"
            >
              <div class="field__label">
                <span
                  class="field__label-text"
                  [appTextStyle]="hoverElementStyles?.labelStyle"
                  [appTextStyleGlobal]="'fieldLabelTextStyle'"
                >
                  Hover
                </span>
              </div>
              <div class="field__content">
                <div class="input-icon-wrapper">
                  <input
                    class="input input_styles"
                    [class.input_hover]="true"
                    [class.input_error]="false"
                    [class.input_wide]="false"
                    [class.input_icon-right]="item.value | appIsSet"
                    [class.input_theme]="true"
                    [appFieldElementStyles]="hoverElementStyles"
                    type="text"
                    [placeholder]="'Enter value...'"
                    [value]="item.value"
                  />

                  <span
                    *ngIf="item.value | appIsSet"
                    class="input-icon input-icon_interactive input-icon_right icon-close"
                    [class.input-icon_theme]="true"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-element-wrapper>
    </div>

    <div class="columns__item" [style.flex-grow]="1">
      <app-element-wrapper [alignHorizontal]="alignHorizontals.Left">
        <div class="element element_inline element_padding-h_normal element_padding-v_normal">
          <div class="element__content">
            <div
              class="field"
              [class.field_manual-margin]="false"
              [class.field_fill-vertical]="false"
              [class.field_theme]="true"
            >
              <div class="field__label">
                <span
                  class="field__label-text"
                  [appTextStyle]="focusElementStyles?.labelStyle"
                  [appTextStyleGlobal]="'fieldLabelTextStyle'"
                >
                  Focus
                </span>
              </div>
              <div class="field__content">
                <div class="input-icon-wrapper">
                  <input
                    class="input input_styles"
                    [class.input_focus]="true"
                    [class.input_error]="false"
                    [class.input_wide]="false"
                    [class.input_icon-right]="item.value | appIsSet"
                    [class.input_theme]="true"
                    [appFieldElementStyles]="focusElementStyles"
                    type="text"
                    [placeholder]="'Enter value...'"
                    [value]="item.value"
                  />

                  <span
                    *ngIf="item.value | appIsSet"
                    class="input-icon input-icon_interactive input-icon_right icon-close"
                    [class.input-icon_theme]="true"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-element-wrapper>
    </div>

    <div class="columns__item" [style.flex-grow]="1">
      <app-element-wrapper [alignHorizontal]="alignHorizontals.Left">
        <div class="element element_inline element_padding-h_normal element_padding-v_normal">
          <div class="element__content">
            <div
              class="field"
              [class.field_manual-margin]="false"
              [class.field_fill-vertical]="false"
              [class.field_theme]="true"
            >
              <div class="field__label">
                <span
                  class="field__label-text"
                  [appTextStyle]="errorElementStyles?.labelStyle"
                  [appTextStyleGlobal]="'fieldLabelTextStyle'"
                >
                  Error
                </span>
              </div>
              <div class="field__content">
                <div class="input-icon-wrapper">
                  <input
                    class="input input_styles"
                    [class.input_error]="true"
                    [class.input_wide]="false"
                    [class.input_icon-right]="item.value | appIsSet"
                    [class.input_theme]="true"
                    [appFieldElementStyles]="errorElementStyles"
                    type="text"
                    [placeholder]="'Enter value...'"
                    [value]="item.value"
                  />

                  <span
                    *ngIf="item.value | appIsSet"
                    class="input-icon input-icon_interactive input-icon_right icon-close"
                    [class.input-icon_theme]="true"
                  ></span>
                </div>
              </div>
              <div class="field__errors">
                <div class="field__error">This is error example</div>
              </div>
            </div>
          </div>
        </div>
      </app-element-wrapper>
    </div>
  </div>
</app-element-wrapper>
