import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as Color from 'color';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { colors, parseColor } from '@modules/colors';
import { isColorHex, isColorRgb, isSet, numberToHex, TypedChanges } from '@shared';

interface Item {
  value: string;
  color: string;
  predefined?: boolean;
}

@Component({
  selector: 'app-color-selector',
  templateUrl: './color-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorSelectorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() current: string;
  @Input() colors: string[];
  @Input() customColors = false;
  @Input() allowEmpty = false;
  @Input() emptyColor: string;
  @Input() defaultColor: string;
  @Input() alphaEnabled = false;
  @Input() accentColor: string;
  @Input() theme = false;
  @Input() rowSize = 6;
  @Output() selected = new EventEmitter<string>();
  @Output() finish = new EventEmitter<void>();

  items: Item[];
  customColorControl: FormControl;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.customColorControl = new FormControl(this.defaultColor || '#2B50ED');

    this.updateItems();
    this.setInitialCustomColor();

    this.initCustomColor();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ColorSelectorComponent>): void {
    if (changes.colors) {
      this.updateItems();
    }
  }

  updateItems() {
    const items: Item[] = [];

    if (this.allowEmpty) {
      items.push({
        value: undefined,
        color: isSet(this.emptyColor) ? this.emptyColor : null
      });
    }

    if (this.colors) {
      items.push(
        ...this.colors
          .map(value => {
            if (isColorHex(value) || isColorRgb(value)) {
              const clr = Color(value);
              return {
                value: clr.hex(),
                color: clr.hex(),
                predefined: false
              };
            } else {
              const predefined = colors.find(item => item.name == value);
              if (predefined) {
                const clr = Color(predefined.hex);
                return {
                  value: predefined.name,
                  color: clr.hex(),
                  predefined: true
                };
              } else {
                return {
                  value: value,
                  color: value
                };
              }
            }
          })
          .filter(item => isSet(item))
      );
    } else {
      items.push(
        ...colors.map(item => {
          const clr = Color(item.hex);
          return {
            value: item.name,
            color: clr.hex(),
            predefined: true
          };
        })
      );
    }

    this.items = items;
    this.cd.markForCheck();
  }

  get width() {
    return 12 + this.rowSize * (8 + 26 + 8) + 12;
  }

  select(item: Item) {
    if (this.customColors) {
      this.customColorControl.patchValue(item.color ? item.color : '');
    }

    this.selected.emit(item.value);
    this.finish.emit();
  }

  initCustomColor() {
    this.customColorControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (this.customColors) {
        this.selected.emit(value);
      }
    });
  }

  setInitialCustomColor() {
    if (!isSet(this.current)) {
      return;
    }

    if (isColorHex(this.current)) {
      this.customColorControl.patchValue(this.current);
      return;
    }

    const color = colors.find(item => item.name == this.current);
    if (color) {
      const hex = '#' + numberToHex(color.hex);
      this.customColorControl.patchValue(hex);
    }
  }

  getContrastColor(color: string): string {
    const colorHex = isColorHex(color)
      ? color.substring(1)
      : colors.filter(item => item.name == color).map(item => numberToHex(item.hex))[0];
    const clr = colorHex ? parseColor('#' + colorHex) : undefined;

    if (!clr) {
      return null;
    }

    const colorContrast = clr.contrast(Color('white'));
    const isDark = colorContrast >= 2;

    return isDark ? '#fff' : clr.darken(0.8).string();
  }
}
