<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <app-audio-upload
    *ngIf="!field.params['multiple']"
    [name]="field.name"
    [control]="control"
    [outputFormat]="field.params['output_format']"
    [storageResourceName]="field.params['storage_resource']"
    [storageName]="field.params['storage_name']"
    [path]="storagePath"
    [autofocus]="autofocus"
    [background]="field.params['background']"
    [context]="context"
    [contextElement]="contextElement"
    [compact]="compact"
    [accentColor]="accentColor"
    [theme]="field.params['theme']"
    (uploadedUpdated)="onUploadedUpdated()"
  ></app-audio-upload>

  <app-multiple-audio-upload
    *ngIf="field.params['multiple']"
    [name]="field.name"
    [control]="control"
    [outputFormat]="field.params['output_format']"
    [storageResourceName]="field.params['storage_resource']"
    [storageName]="field.params['storage_name']"
    [path]="storagePath"
    [autofocus]="autofocus"
    [background]="field.params['background']"
    [context]="context"
    [contextElement]="contextElement"
    [compact]="compact"
    [accentColor]="accentColor"
    [theme]="field.params['theme']"
    (uploadedUpdated)="onUploadedUpdated()"
  ></app-multiple-audio-upload>
</app-field-wrapper>

<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <app-audio-preview
    *ngIf="!field.params['multiple']"
    class="field__preview field__preview_fill"
    [preview]="uploadedFile?.url"
  ></app-audio-preview>

  <div
    *ngIf="field.params['multiple']"
    class="multiple-files-uploader"
    [class.multiple-files-uploader_compact]="compact"
  >
    <div
      *ngFor="let uploadedFile of compact ? uploadedFiles.slice(0, 3) : uploadedFiles"
      class="multiple-files-uploader__item"
    >
      <app-audio-preview
        *ngIf="!compact"
        class="field__preview field__preview_fill"
        [preview]="uploadedFile?.url"
      ></app-audio-preview>

      <app-file-preview
        *ngIf="compact"
        class="field__preview field__preview_fill field__preview_padding"
        [class.field__preview_file]="!(uploadedFile?.filename | appIsSet)"
        [class.field__preview_compact]="compact"
        [previewFilename]="uploadedFile?.filename"
        [previewExtension]="uploadedFile?.extension"
        [compact]="compact"
        [theme]="field.params['theme']"
      ></app-file-preview>
    </div>

    <div *ngIf="compact && uploadedFiles.length > 3" class="multiple-files-uploader__item">
      <div class="multiple-files-uploader__spoiler">+{{ uploadedFiles.length - 3 }}</div>
    </div>
  </div>
</app-field-wrapper>
