import { CdkConnectedOverlay, ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subscription } from 'rxjs';

import { getColorHexStr, GradientType } from '@modules/colors';
import { FillSettingsControl } from '@modules/colors-components';
import { FillType, Frame } from '@modules/customize';
import { ThemeService } from '@modules/theme';
import { isSet } from '@shared';

import { stylesEditPopoverPositions } from '../styles-edit/styles-edit-popover-positions';

@Component({
  selector: 'app-styles-edit-fill',
  templateUrl: './styles-edit-fill.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesEditFillComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: FillSettingsControl;
  @Input() subtitle = 'Background';
  @Input() additional: string;
  @Input() contrast = false;
  @Input() disabled = false;
  @Input() added = false;
  @Input() removeEnabled = true;
  @Input() source: any;
  @Output() remove = new EventEmitter<void>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  valueStr: string;
  fillBackground: SafeStyle;
  dropdownOpened = false;
  popoverPositions = stylesEditPopoverPositions;
  popoverPositionsSubscription: Subscription;

  constructor(public themeService: ThemeService, private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    combineLatest(this.control.serialize$(), this.themeService.isDarkTheme$)
      .pipe(untilDestroyed(this))
      .subscribe(([fillSettings, isDarkTheme]) => {
        const fill = isDarkTheme ? fillSettings.fillDark : fillSettings.fill;
        const background = fill.css({ frame: new Frame({ width: 16, height: 16 }) }).background;

        if (fill.type == FillType.Color) {
          this.valueStr = isSet(fill.color) ? getColorHexStr(fill.color) : undefined;
        } else if (fill.type == FillType.Gradient && fill.gradient) {
          if (fill.gradient.type == GradientType.Linear) {
            this.valueStr = 'Linear gradient';
          } else if (fill.gradient.type == GradientType.Radial) {
            this.valueStr = 'Radial gradient';
          } else if (fill.gradient.type == GradientType.Angular) {
            this.valueStr = 'Angular gradient';
          } else if (fill.gradient.type == GradientType.Diamond) {
            this.valueStr = 'Diamond gradient';
          } else {
            this.valueStr = undefined;
          }
        } else {
          this.valueStr = undefined;
        }

        this.fillBackground = background ? this.sanitizer.bypassSecurityTrustStyle(background) : undefined;
        this.cd.markForCheck();
      });

    if (this.added) {
      this.setDropdownOpened(true);
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}
