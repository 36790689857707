import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';

import { ProjectSettingsStore } from '@modules/all-project-settings';
import { getColorHexStr } from '@modules/colors';
import { TextStyle, TextStyleGlobalName } from '@modules/customize';
import { getFontFamilyVariable } from '@modules/styles';
import { ThemeService } from '@modules/theme';
import { isSet, TypedChanges } from '@shared';

@Directive({
  selector: '[appTextStyle]'
})
export class TextStyleDirective implements OnInit, OnDestroy, OnChanges {
  @Input('appTextStyle') customStyle: TextStyle;
  @Input('appTextStyleGlobal') global: TextStyleGlobalName;
  @Input('appTextStyleColor') customColor: string;
  @Input('appTextStyleBackgroundColor') customBackgroundColor: string;

  globalStyle: TextStyle;

  get style(): TextStyle {
    return this.customStyle || this.globalStyle;
  }

  @HostBinding('style.font-family') get fontFamily(): string | SafeStyle {
    if (this.style && isSet(this.style.fontFamily)) {
      const variable = getFontFamilyVariable(this.style.fontFamily);

      if (isSet(variable)) {
        return this.sanitizer.bypassSecurityTrustStyle(`var(--${variable})`);
      } else {
        return this.style.fontFamily;
      }
    } else {
      return null;
    }
  }

  @HostBinding('style.font-size.px') get fontSizePx(): number {
    if (this.style && isSet(this.style.fontSize)) {
      return this.style.fontSize;
    } else {
      return null;
    }
  }

  @HostBinding('style.font-weight') get fontWeight(): number {
    if (this.style && isSet(this.style.fontWeight)) {
      return this.style.fontWeight;
    } else {
      return null;
    }
  }

  @HostBinding('style.font-style') get fontStyle(): string {
    if (this.style && isSet(this.style.fontStyle)) {
      return this.style.cssFontStyle();
    } else {
      return null;
    }
  }

  @HostBinding('style.color') color: string = null;

  @HostBinding('style.background-color') backgroundColor: string = null;

  @HostBinding('class.text-background') get textBackgroundCls(): boolean {
    return isSet(this.backgroundColor);
  }

  @HostBinding('class.text-background_theme') get textBackgroundThemeCls(): boolean {
    return isSet(this.backgroundColor);
  }

  @HostBinding('style.letter-spacing.px') get letterSpacingPx(): number {
    if (this.style && isSet(this.style.letterSpacing)) {
      return this.style.letterSpacing;
    } else {
      return null;
    }
  }

  @HostBinding('style.text-transform') get textTransform(): string {
    if (this.style && isSet(this.style.transform)) {
      return this.style.cssTextTransform();
    } else {
      return null;
    }
  }

  @HostBinding('style.text-decoration') get textDecoration(): string {
    if (this.style && isSet(this.style.decoration)) {
      return this.style.cssTextDecoration();
    } else {
      return null;
    }
  }

  constructor(
    @Optional() private projectSettingsStore: ProjectSettingsStore,
    @Optional() private themeService: ThemeService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // const settings$ = this.projectSettingsStore
    //   ? this.projectSettingsStore.getAllSettings$()
    //   : of<AllProjectSettings>(undefined);
    //
    // settings$.pipe(untilDestroyed(this)).subscribe(settings => {
    //   this.globalStyle = settings ? (settings[this.globalSetting] as TextStyle) : undefined;
    //   this.cd.markForCheck();
    //   this.updateColor();
    // });

    const theme$ = this.themeService ? this.themeService.theme$ : of(undefined);

    theme$.pipe(untilDestroyed(this)).subscribe(theme => {
      this.updateColorForTheme(theme);
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<TextStyleDirective>): void {
    if (changes.customStyle && !changes.customStyle.firstChange) {
      this.updateColor();
    }

    if (changes.customColor && !changes.customColor.firstChange) {
      this.updateColor();
    }

    if (changes.customBackgroundColor && !changes.customBackgroundColor.firstChange) {
      this.updateColor();
    }
  }

  updateColorForTheme(theme: string) {
    if (isSet(this.customColor)) {
      this.color = getColorHexStr(this.customColor);
    } else if (theme == 'dark' && this.style && isSet(this.style.colorDark)) {
      this.color = this.style.colorDark;
    } else if (theme != 'dark' && this.style && isSet(this.style.color)) {
      this.color = this.style.color;
    } else {
      this.color = null;
    }

    if (theme == 'dark' && this.style && isSet(this.style.backgroundColorDark)) {
      if (isSet(this.customBackgroundColor)) {
        this.backgroundColor = getColorHexStr(this.customBackgroundColor);
      } else {
        this.backgroundColor = this.style.backgroundColorDark;
      }
    } else if (theme != 'dark' && this.style && isSet(this.style.backgroundColor)) {
      if (isSet(this.customBackgroundColor)) {
        this.backgroundColor = getColorHexStr(this.customBackgroundColor);
      } else {
        this.backgroundColor = this.style.backgroundColor;
      }
    } else {
      this.backgroundColor = null;
    }

    this.cd.markForCheck();
  }

  updateColor() {
    const theme = this.themeService ? this.themeService.theme : 'dark';
    this.updateColorForTheme(theme);
  }
}
