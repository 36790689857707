import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Color from 'color';
import toPairs from 'lodash/toPairs';

import { TintStyle } from '@modules/actions';
import { getColorHex, getColorHexAStr, parseColor } from '@modules/colors';
import { isSet } from '@shared';

export function getTintColors(
  color: string,
  style: TintStyle
): Partial<{
  backgroundColor?: Color;
  backgroundHoverColor?: Color;
  backgroundActiveColor?: Color;
  textColor?: Color;
  textHoverColor?: Color;
  textActiveColor?: Color;
}> {
  const hex = getColorHex(color);
  const clr = isSet(hex) ? parseColor(hex) : undefined;

  if (clr) {
    const isDark = clr.contrast(Color('white')) >= 2;

    if (style == TintStyle.Primary) {
      return {
        backgroundColor: clr,
        backgroundHoverColor: clr.darken(0.2),
        backgroundActiveColor: clr.darken(0.5),
        textColor: isDark ? Color('#fff') : clr.darken(0.6),
        textHoverColor: isDark ? Color('#fff') : clr.darken(0.6),
        textActiveColor: isDark ? Color('#fff') : clr.darken(0.6)
      };
    } else if (style == TintStyle.Transparent) {
      return {
        backgroundHoverColor: clr.darken(0.2).alpha(0.2),
        backgroundActiveColor: clr.darken(0.5).alpha(0.2),
        textColor: clr.darken(0.3),
        textHoverColor: clr.darken(0.3),
        textActiveColor: clr.darken(0.3)
      };
    } else {
      return {
        backgroundColor: clr.alpha(0.1),
        backgroundHoverColor: clr.darken(0.2).alpha(0.2),
        backgroundActiveColor: clr.darken(0.5).alpha(0.2),
        textColor: clr.darken(0.3),
        textHoverColor: clr.darken(0.3),
        textActiveColor: clr.darken(0.3)
      };
    }
  }
}

@Directive({
  selector: '[appTintColor]'
})
export class TintColorDirective implements OnChanges {
  @Input() appTintColor: string;
  @Input() appTintColorStyle: TintStyle;

  styleVars: string[] = [];

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const vars = {};

    const colors = getTintColors(this.appTintColor, this.appTintColorStyle);
    if (colors) {
      Object.assign(vars, {
        ...(colors.backgroundColor && {
          'background-color': getColorHexAStr(colors.backgroundColor)
        }),
        ...(colors.backgroundHoverColor && {
          'background-hover-color': getColorHexAStr(colors.backgroundHoverColor)
        }),
        ...(colors.backgroundActiveColor && {
          'background-active-color': getColorHexAStr(colors.backgroundActiveColor)
        }),
        ...(colors.textColor && {
          'text-color': getColorHexAStr(colors.textColor)
        }),
        ...(colors.textHoverColor && {
          'text-hover-color': getColorHexAStr(colors.textHoverColor)
        }),
        ...(colors.textActiveColor && {
          'text-active-color': getColorHexAStr(colors.textActiveColor)
        })
      });
    }

    toPairs(vars).forEach(([k, v]) => this.el.nativeElement.style.setProperty(`--${k}`, v));
    this.styleVars.filter(k => !isSet(vars[k])).forEach(k => this.el.nativeElement.style.removeProperty(`--${k}`));

    this.styleVars = toPairs(vars).map(([k]) => k);
  }
}
