/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./table-actions-item/table-actions-item.component.ngfactory";
import * as i2 from "./table-actions-item/table-actions-item.component";
import * as i3 from "../../../customize/services/customize/customize.service";
import * as i4 from "../../../action-queries/services/action/action.service";
import * as i5 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i6 from "../../../../common/notifications/services/notification/notification.service";
import * as i7 from "../../../../ui/components/checkbox/checkbox.component.ngfactory";
import * as i8 from "../../../../ui/components/checkbox/checkbox.component";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@angular/common";
import * as i11 from "../../../../common/localize/pipes/localize/localize.pipe";
import * as i12 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i13 from "./table-actions.component";
import * as i14 from "../../../projects/stores/current-project.store";
import * as i15 from "@angular/router";
import * as i16 from "../../../../common/popups/services/popup/popup.service";
var styles_TableActionsComponent = [];
var RenderType_TableActionsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TableActionsComponent, data: {} });
export { RenderType_TableActionsComponent as RenderType_TableActionsComponent };
function View_TableActionsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" (", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedItemsLength; _ck(_v, 1, 0, currVal_0); }); }
function View_TableActionsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-table-actions-item", [["class", "table-actions__item"]], [[2, "element__indicator-wrapper", null], [2, "hidden", null]], null, null, i1.View_TableActionsItemComponent_0, i1.RenderType_TableActionsItemComponent)), i0.ɵdid(2, 770048, null, 0, i2.TableActionsItemComponent, [i3.CustomizeService, i4.ActionService, i5.ActionControllerService, i6.NotificationService, i0.Injector, i0.ChangeDetectorRef], { action: [0, "action"], selectedItems: [1, "selectedItems"], context: [2, "context"], contextElement: [3, "contextElement"], accentColor: [4, "accentColor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit; var currVal_3 = _co.selectedItems; var currVal_4 = _co.context; var currVal_5 = _co.contextElement; var currVal_6 = _co.accentColor; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 2).hidden; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_TableActionsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "table-actions inverse"]], [[2, "table-actions_theme", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-checkbox", [["class", "table-actions__checkbox"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelectAllClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_CheckboxComponent_0, i7.RenderType_CheckboxComponent)), i0.ɵdid(2, 770048, null, 0, i8.CheckboxComponent, [i9.DomSanitizer, i0.ChangeDetectorRef], { value: [0, "value"], square: [1, "square"], dash: [2, "dash"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "table-actions__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", ""])), i0.ɵppd(5, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TableActionsComponent_2)), i0.ɵdid(7, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(8, 1), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "table-actions__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableActionsComponent_3)), i0.ɵdid(11, 278528, null, 0, i10.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = _co.selectedAll; var currVal_3 = !_co.selectedAll; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v.parent, 1), _co.selectedItemsLength)); _ck(_v, 7, 0, currVal_5); var currVal_6 = _co.settings.modelActions; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.theme; _ck(_v, 0, 0, currVal_0); var currVal_4 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "Selected")); _ck(_v, 4, 0, currVal_4); }); }
export function View_TableActionsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i11.LocalizePipe, []), i0.ɵpid(0, i12.IsSetPipe, []), i0.ɵqud(402653184, 1, { root: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableActionsComponent_1)), i0.ɵdid(4, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedItemsLength; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_TableActionsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-table-actions", [], null, null, null, View_TableActionsComponent_0, RenderType_TableActionsComponent)), i0.ɵdid(1, 770048, null, 0, i13.TableActionsComponent, [i14.CurrentProjectStore, i0.Injector, i15.ActivatedRoute, i16.PopupService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableActionsComponentNgFactory = i0.ɵccf("app-table-actions", i13.TableActionsComponent, View_TableActionsComponent_Host_0, { listTitle: "listTitle", settings: "settings", selectedItems: "selectedItems", selectedInverse: "selectedInverse", displayItems: "displayItems", totalItems: "totalItems", context: "context", contextElement: "contextElement", accentColor: "accentColor", theme: "theme" }, { selectAllRequested: "selectAllRequested", deselectRequested: "deselectRequested", reordered: "reordered" }, []);
export { TableActionsComponentNgFactory as TableActionsComponentNgFactory };
