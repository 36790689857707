import { BorderSettings, Corners, FillSettings, IconSettings, Margin, Shadow, TextStyle } from '@modules/customize';
import { TextDecoration, TextTransform } from '@modules/styles';
import { isSet } from '@shared';

export function setTextStyle(vars: Object, textStyle: TextStyle, key: (key: string) => string) {
  if (!textStyle) {
    return;
  }

  const font = textStyle.cssFont();
  if (isSet(font)) {
    vars[key('font')] = textStyle.cssFont();
    vars[key('font-small')] = textStyle.cssFont({ sizeMultiplier: 0.915 });

    if (isSet(textStyle.fontSize)) {
      vars[key('font-size')] = `${textStyle.fontSize}px`;
      vars[key('font-size-small')] = `${textStyle.fontSize * 0.915}px`;
    }
  }

  if (isSet(textStyle.color)) {
    vars[key('color')] = textStyle.color;
  }

  if (isSet(textStyle.colorDark)) {
    vars[key('color-dark')] = textStyle.colorDark;
  }

  if (isSet(textStyle.letterSpacing)) {
    vars[key('letter-spacing')] = `${textStyle.letterSpacing}px`;
  }

  if (textStyle.transform && textStyle.transform != TextTransform.None) {
    vars[key('transform')] = textStyle.cssTextTransform();
  }

  if (textStyle.decoration && textStyle.decoration != TextDecoration.None) {
    vars[key('decoration')] = textStyle.cssTextDecoration();
  }
}

export function setIconSettingsVars(vars: Object, icon: IconSettings, key: (key: string) => string) {
  if (!icon) {
    return;
  }

  if (isSet(icon.color)) {
    vars[key('color')] = icon.color;
  }

  if (isSet(icon.colorDark)) {
    vars[key('color-dark')] = icon.colorDark;
  }

  if (isSet(icon.size)) {
    vars[key('size')] = `${icon.size}px`;
    vars[key('size-small')] = `${icon.size * 0.875}px`;
  }
}

export function setFillVars(vars: Object, fillSettings: FillSettings, key: (key: string) => string) {
  if (!fillSettings) {
    return;
  }

  const fill = fillSettings.fill ? fillSettings.fill.css() : undefined;
  if (fill && fill.background) {
    vars[key('background')] = fill.background;
  }

  const fillDark = fillSettings.fillDark ? fillSettings.fillDark.css() : undefined;
  if (fillDark && fillDark.background) {
    vars[key('background-dark')] = fillDark.background;
  }
}

export function setBorderVars(vars: Object, borderSettings: BorderSettings, key: (key: string) => string) {
  setBorderThemeVars(vars, borderSettings, false, key);
  setBorderThemeVars(vars, borderSettings, true, themeKey => key(`${themeKey}-dark`));
}

export function setBorderThemeVars(
  vars: Object,
  borderSettings: BorderSettings,
  isDarkTheme: boolean,
  key: (key: string) => string
) {
  if (!borderSettings) {
    return;
  }

  if (borderSettings.isSidesSet()) {
    const borderTop = borderSettings.borderTop ? borderSettings.borderTop.cssBorder(isDarkTheme) : undefined;
    if (borderTop) {
      vars[key('border-top')] = borderTop;
    }

    const borderRight = borderSettings.borderRight ? borderSettings.borderRight.cssBorder(isDarkTheme) : undefined;
    if (borderRight) {
      vars[key('border-right')] = borderRight;
    }

    const borderBottom = borderSettings.borderBottom ? borderSettings.borderBottom.cssBorder(isDarkTheme) : undefined;
    if (borderBottom) {
      vars[key('border-bottom')] = borderBottom;
    }

    const borderLeft = borderSettings.borderLeft ? borderSettings.borderLeft.cssBorder(isDarkTheme) : undefined;
    if (borderLeft) {
      vars[key('border-left')] = borderLeft;
    }
  } else {
    const border = borderSettings.border ? borderSettings.border.cssBorder(isDarkTheme) : undefined;
    if (border) {
      vars[key('border-top')] = border;
      vars[key('border-right')] = border;
      vars[key('border-bottom')] = border;
      vars[key('border-left')] = border;
    }
  }
}

export function setBorderRadiusVars(vars: Object, value: Corners, key: string) {
  if (!value) {
    return;
  }

  const values = [value.topLeft, value.topRight, value.bottomRight, value.bottomLeft];
  if (values.some(item => isSet(item))) {
    vars[key] = values
      .map(item => (isSet(item) ? item : 0))
      .map(item => `${item}px`)
      .join(' ');
  } else if (isSet(value.all)) {
    vars[key] = `${value.all}px`;
  }
}

export function setShadowVars(vars: Object, shadow: Shadow, key: (key: string) => string) {
  if (!shadow) {
    return;
  }

  const boxShadow = shadow.cssBoxShadow({ dark: false });
  if (boxShadow) {
    vars[key('shadow')] = boxShadow;
  }

  const boxShadowDark = shadow.cssBoxShadow({ dark: true });
  if (boxShadowDark) {
    vars[key('shadow-dark')] = boxShadowDark;
  }
}

export function setMarginVars(
  vars: Object,
  value: Margin,
  key: (key: string) => string,
  options: { horizontalMultiplier?: number; verticalMultiplier?: number } = {}
) {
  if (!value) {
    return;
  }

  const values = [value.top, value.right, value.bottom, value.left];
  if (values.every(item => !isSet(item))) {
    return;
  }

  if (isSet(value.top)) {
    const px = isSet(options.verticalMultiplier) ? value.top * options.verticalMultiplier : value.top;
    vars[key('top')] = `${px}px`;
  }

  if (isSet(value.right)) {
    const px = isSet(options.horizontalMultiplier) ? value.right * options.horizontalMultiplier : value.right;
    vars[key('right')] = `${px}px`;
  }

  if (isSet(value.bottom)) {
    const px = isSet(options.verticalMultiplier) ? value.bottom * options.verticalMultiplier : value.bottom;
    vars[key('bottom')] = `${px}px`;
  }

  if (isSet(value.left)) {
    const px = isSet(options.horizontalMultiplier) ? value.left * options.horizontalMultiplier : value.left;
    vars[key('left')] = `${px}px`;
  }
}
