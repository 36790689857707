var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { AfterViewInit, ApplicationRef, ChangeDetectorRef, ComponentFactoryResolver, ElementRef, EmbeddedViewRef, EventEmitter, Injector, OnChanges, OnDestroy, OnInit, TemplateRef, Type, ViewContainerRef } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Quill from 'quill';
import Delta from 'quill-delta';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ignoreDragDrop } from '@common/drag-drop';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { FieldInputControl, FieldInputRequiredValidator } from '@modules/parameters';
import { ThemeService } from '@modules/theme';
import { KeyboardEventKeyCode, removeElement } from '@shared';
// TODO: Refactor imports
import { InlineInputEditComponent } from '../../../../modules/parameters-components/components/inline-input-edit/inline-input-edit.component';
import { TextEditorBackgroundPickerComponent } from '../text-editor-background-picker/text-editor-background-picker.component';
import { TextEditorColorPickerComponent } from '../text-editor-color-picker/text-editor-color-picker.component';
import './quill-background-set';
import './quill-color-set';
import './quill-context-formula';
import { InlineInputDynamicComponent, inlineInputInitialProperty } from './quill-context-formula';
import { textEditorOverlayPositions } from './text-editor-overlay-positions';
// interface FormulaBlockData {
//   value?: Object;
// }
function deltasEqual(delta1, delta2) {
    if (delta1 && delta2) {
        return JSON.stringify(delta1) == JSON.stringify(delta2);
    }
    else {
        return isEqual(delta1, delta2);
    }
}
var TextEditorComponent = /** @class */ (function () {
    function TextEditorComponent(themeService, overlay, injector, componentFactoryResolver, appRef, viewContainerRef, cd) {
        var _this = this;
        this.themeService = themeService;
        this.overlay = overlay;
        this.injector = injector;
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.viewContainerRef = viewContainerRef;
        this.cd = cd;
        this.editable = true;
        this.textParagraphs = false;
        this.textParagraphsInheritLines = false;
        this.toolbarHeadersEnabled = true;
        this.toolbarTextStyleEnabled = true;
        this.toolbarTextColorEnabled = false;
        this.toolbarInputLabel = false;
        this.toolbarListsEnabled = true;
        this.toolbarAlignEnabled = true;
        this.toolbarCleanEnabled = true;
        this.toolbarWrap = true;
        this.schema = 'light';
        this.paddingSize = 'none';
        this.theme = false;
        this.changed = new EventEmitter();
        this.escapeClick = new EventEmitter();
        this.editable$ = new BehaviorSubject(true);
        this.inputComponents = [];
        this.empty = false;
        this.onTextChange = (function () {
            return function (delta, oldDelta, source) {
                var value = _this.editor2.getContents();
                _this.empty = _this.isEmpty();
                _this.cd.markForCheck();
                if (deltasEqual(_this.value, value)) {
                    return;
                }
                _this.changed.emit(value);
            };
        })();
    }
    TextEditorComponent.prototype.ngOnInit = function () { };
    TextEditorComponent.prototype.ngOnDestroy = function () {
        // if (this.editor) {
        //   this.editor.destroy();
        // }
        if (this.editor2) {
            this.editor2.off('text-change', this.onTextChange);
        }
        if (this.editorToolbar) {
            this.editorToolbar.viewRef.destroy();
        }
        this.inputComponents.forEach(function (item) { return item.destroy(); });
    };
    TextEditorComponent.prototype.ngOnChanges = function (changes) {
        if (changes.editable) {
            if (this.editor2) {
                if (this.editable) {
                    this.editor2.enable();
                }
                else {
                    this.editor2.disable();
                }
            }
            this.editable$.next(this.editable);
        }
        if (changes.value && !changes.value.firstChange) {
            if (this.editor2) {
                if (this.value) {
                    var value = this.editor2.getContents();
                    if (!deltasEqual(this.value, value)) {
                        this.editor2.setContents(this.value, 'silent');
                    }
                }
            }
        }
        if (changes.toolbarElement && this.toolbarElement) {
            if (this.editorToolbar) {
                this.toolbarElement.nativeElement.appendChild(this.editorToolbar.element);
            }
        }
    };
    TextEditorComponent.prototype.ngAfterViewInit = function () {
        // this.initEditor();
        this.initEditor2();
    };
    TextEditorComponent.prototype.createSelectionOverlay = function (format, component, value) {
        var _this = this;
        var range = this.editor2.getSelection() || {
            index: 0,
            length: 0
        };
        var editorBounds = this.elEditor.nativeElement.getBoundingClientRect();
        var rangeBounds = this.editor2.getBounds(range.index, range.length);
        var positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo({
            x: editorBounds.left + rangeBounds.left + rangeBounds.width * 0.5,
            y: editorBounds.top + rangeBounds.top + rangeBounds.height * 0.5
        })
            .withPositions(textEditorOverlayPositions(rangeBounds.width, rangeBounds.height))
            .withPush(true)
            .withGrowAfterOpen(true);
        var overlayRef = this.overlay.create({
            panelClass: ['overlay'],
            positionStrategy: positionStrategy,
            hasBackdrop: true,
            backdropClass: 'popover2-backdrop'
        });
        var portal = new ComponentPortal(component, undefined, this.injector);
        var componentRef = overlayRef.attach(portal);
        componentRef.instance.value = value;
        componentRef.instance.initValue();
        var subscriptions = [];
        subscriptions.push(overlayRef
            .backdropClick()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            overlayRef.dispose();
        }));
        subscriptions.push(componentRef.instance.valueChange.pipe(untilDestroyed(this)).subscribe(function (valueChange) {
            _this.editor2.formatText(range, format, valueChange);
        }));
        overlayRef
            .detachments()
            .pipe(first(), untilDestroyed(this))
            .subscribe(function () {
            subscriptions.forEach(function (item) { return item.unsubscribe(); });
        });
    };
    TextEditorComponent.prototype.createInputComponent = function (container, value) {
        var formGroup = new FieldInputControl(__assign({ name: 'value' }, value), FieldInputRequiredValidator);
        var portalHost = new DomPortalHost(container, this.componentFactoryResolver, this.appRef, this.injector);
        var portal = new ComponentPortal(InlineInputEditComponent, this.viewContainerRef, this.injector);
        var ref = portalHost.attach(portal);
        ref.instance.itemForm = formGroup;
        ref.instance.context = this.context;
        ref.instance.contextElement = this.contextElement;
        ref.instance.contextElementPath = this.contextElementPath;
        ref.instance.contextElementPaths = this.contextElementPaths;
        ref.instance.editable$ = this.editable$;
        ref.instance.analyticsSource = this.analyticsSource;
        if (value[inlineInputInitialProperty]) {
            ref.instance.focusedInitial = true;
            delete value[inlineInputInitialProperty];
        }
        ref.changeDetectorRef.detectChanges();
        var result = new InlineInputDynamicComponent(ref, portalHost);
        this.inputComponents.push(result);
        return result;
    };
    TextEditorComponent.prototype.disposeInputComponent = function (component) {
        component.destroy();
        this.inputComponents = this.inputComponents.filter(function (item) { return item !== component; });
    };
    TextEditorComponent.prototype.createEditorToolbarElement = function () {
        var viewRef = this.viewContainerRef.createEmbeddedView(this.toolbarLeft);
        viewRef.detectChanges();
        var element = viewRef.rootNodes[0];
        removeElement(element);
        return {
            viewRef: viewRef,
            element: element
        };
    };
    TextEditorComponent.prototype.initEditor2 = function () {
        var _this = this;
        this.editorToolbar = this.createEditorToolbarElement();
        this.editor2 = new Quill(this.elEditor.nativeElement, {
            theme: 'snow',
            bounds: this.elEditor.nativeElement,
            placeholder: 'Enter content...',
            readOnly: !this.editable,
            formats: [
                'header',
                'bold',
                'italic',
                'underline',
                'strike',
                'link',
                'list',
                'align',
                'color-set',
                'background-set',
                'context-formula'
            ],
            modules: {
                contextFormula: {
                    createComponentFn: function (container, value) {
                        return _this.createInputComponent(container, value);
                    },
                    disposeComponentFn: function (ref) {
                        _this.disposeInputComponent(ref);
                    }
                },
                toolbar: {
                    container: this.editorToolbar.element,
                    formatsOptions: {
                        'color-set': {
                            theme$: this.themeService.theme$
                        },
                        'background-set': {
                            theme$: this.themeService.theme$
                        }
                    },
                    handlers: {
                        'color-set': function () {
                            var format = _this.editor2.getFormat()['color-set'] || {};
                            _this.createSelectionOverlay('color-set', TextEditorColorPickerComponent, format);
                        },
                        'background-set': function () {
                            var format = _this.editor2.getFormat()['background-set'] || {};
                            _this.createSelectionOverlay('background-set', TextEditorBackgroundPickerComponent, format);
                        }
                    }
                }
            }
        });
        this.editor2.keyboard.addBinding({ key: KeyboardEventKeyCode.Escape }, function () { return _this.escapeClick.emit(); });
        if (this.toolbarElement) {
            this.toolbarElement.nativeElement.appendChild(this.editorToolbar.element);
        }
        if (this.value) {
            this.editor2.setContents(this.value, 'silent');
            var history_1 = this.editor2.getModule('history');
            if (history_1) {
                history_1.clear();
            }
        }
        this.empty = this.isEmpty();
        this.cd.markForCheck();
        this.editor2.on('text-change', this.onTextChange);
    };
    TextEditorComponent.prototype.getLength = function () {
        if (!this.editor2) {
            return;
        }
        return this.editor2.getLength();
    };
    TextEditorComponent.prototype.isEmpty = function () {
        if (!this.editor2) {
            return;
        }
        var contents = this.editor2.getContents();
        return contents.ops.length == 1 && contents.ops[0].insert == '\n';
    };
    TextEditorComponent.prototype.selectAll = function () {
        if (!this.editor2) {
            return;
        }
        this.editor2.setSelection(0, this.getLength());
    };
    // initEditor() {
    //   const createComponentRef = (container: HTMLElement, value?: Object) => {
    //     const formGroup = new FieldInputControl({ name: 'value', ...value }, FieldInputRequiredValidator);
    //     return this.dynamicComponentService.createComponent<InlineInputEditComponent>(
    //       this.injector,
    //       {
    //         component: InlineInputEditComponent,
    //         analyticsSource: this.analyticsSource,
    //         inputs: {
    //           itemForm: formGroup,
    //           context: this.context
    //         }
    //       },
    //       container
    //     );
    //   };
    //
    //   class FormulaBlock {
    //     static get toolbox() {
    //       return {
    //         title: 'Computed Text',
    //         icon: '<span class="icon-function" style="font-size: 18px"></span>'
    //       };
    //     }
    //
    //     data: FormulaBlockData = {};
    //     componentRef: ComponentRef<InlineInputEditComponent>;
    //
    //     constructor({ api, config, data }) {
    //       this.data = {
    //         value: data.value || {
    //           value_type: 'formula',
    //           formula_value: `FORMAT('This is an {0}', 'example')`
    //         }
    //       };
    //     }
    //
    //     render() {
    //       const root = document.createElement('div');
    //       addClass(root, 'ce-paragraph cdx-block');
    //       this.componentRef = createComponentRef(root, this.data.value);
    //       return root;
    //     }
    //
    //     save(): FormulaBlockData {
    //       const value = this.componentRef ? this.componentRef.instance.itemForm.value : undefined;
    //       return {
    //         value: value
    //       };
    //     }
    //
    //     removed() {
    //       if (this.componentRef) {
    //         this.componentRef.destroy();
    //       }
    //     }
    //   }
    //
    //   this.editor = new EditorJS({
    //     holder: this.elEditor.nativeElement,
    //     placeholder: 'Enter text',
    //     tools: {
    //       header: {
    //         class: Header,
    //         config: {
    //           placeholder: 'Header',
    //           levels: [1, 2, 3],
    //           defaultLevel: 1
    //         },
    //         inlineToolbar: ['link']
    //       },
    //       formula: FormulaBlock
    //     },
    //     data: this.value,
    //     minHeight: 0,
    //     onChange: () => {
    //       this.change();
    //     }
    //   });
    // }
    // change() {
    //   this.editor
    //     .save()
    //     .then(outputData => {
    //       this.changed.next(outputData);
    //     })
    //     .catch(error => {
    //       console.log('Saving failed: ', error);
    //     });
    // }
    TextEditorComponent.prototype.onMouseDown = function (event) {
        ignoreDragDrop(event);
    };
    return TextEditorComponent;
}());
export { TextEditorComponent };
