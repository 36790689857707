import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as Color from 'color';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { TintStyle } from '@modules/actions';
import { getColorHexAStr, PickColorService } from '@modules/colors';
import { NumberFieldType } from '@modules/fields';
import { controlValue, deployUrl, isSet, KeyboardEventKeyCode, parseNumber } from '@shared';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerComponent implements OnInit, OnDestroy {
  @Input() hexControl: FormControl;
  @Input() alphaEnabled = false;
  @Input() alphaDefault = 100;
  @Input() accentColor: string;
  @Input() theme = false;

  hexControlInternalUpdate = false;
  hueControl = new FormControl(0);
  saturationControl = new FormControl(100);
  luminosityControl = new FormControl(50);
  alphaControl = new FormControl(this.alphaDefault);
  // hexControl = new FormControl('#2B50ED');
  hueBackground =
    'linear-gradient(to right, #FF0000 0%, #FF7D00 12.38%, #FFFF00 19.52%, #00FF00 35.71%, #00FFFF 51.43%, #0000FF 65.72%, #FF00FF 83.33%, #FF0000 100%)';
  hueThumbBorder: string;
  saturationBackground: string;
  luminosityBackground: string;
  alphaBackground: string;
  tintStyles = TintStyle;
  numberFieldTypes = NumberFieldType;

  constructor(private pickColorService: PickColorService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.initHex();
  }

  ngOnDestroy(): void {}

  initHex() {
    this.hexControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (isSet(value)) {
        let cleanValue = value.toUpperCase().replace(/[^0-9A-F]/g, '');

        if (cleanValue[0] != '#') {
          cleanValue = '#' + cleanValue;
        }

        cleanValue = cleanValue.substring(0, 9);

        if (cleanValue != value) {
          this.hexControl.patchValue(cleanValue);
          return;
        }
      }

      this.cd.markForCheck();
    });

    combineLatest(
      controlValue<number>(this.hueControl),
      controlValue<number>(this.saturationControl),
      controlValue<number>(this.luminosityControl)
    )
      .pipe(untilDestroyed(this))
      .subscribe(([hueRaw, saturationRaw, luminosityRaw]) => {
        const colorful = saturationRaw != 0 && luminosityRaw != 0 && luminosityRaw != 100;
        const hue = hueRaw || 0;
        const saturation = clamp(saturationRaw || 0, 20, 100);
        const luminosity = clamp(luminosityRaw || 0, 20, 80);
        const saturationL = saturationRaw > 0 ? saturation : 0;

        this.hueThumbBorder = colorful ? Color({ h: hue, s: 100, l: 30 }).hex() : '#768191';
        this.saturationBackground = `linear-gradient(to right, hsl(${hue}, 0%, ${luminosity}%) 0%, hsl(${hue}, 100%, ${luminosity}%) 100%)`;
        this.luminosityBackground = `linear-gradient(to right, hsl(${hue}, ${saturationL}%, 0%) 0%, hsl(${hue}, ${saturationL}%, 50%) 50%, hsl(${hue}, ${saturation}%, 100%) 100%)`;
        this.alphaBackground = [
          `linear-gradient(to right, hsl(${hue}, ${saturation}%, ${luminosity}%, 0) 0%, hsl(${hue}, ${saturation}%, ${luminosity}%, 1) 100%)`,
          `#fff center center / 10px url(${deployUrl('/assets/images/transparent.svg')})`
        ].join(', ');
        this.cd.markForCheck();
      });

    controlValue<string>(this.hexControl)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        let internalUpdate = false;

        if (this.hexControlInternalUpdate) {
          this.hexControlInternalUpdate = false;
          internalUpdate = true;
        }

        if (!internalUpdate) {
          if (isSet(value)) {
            try {
              const clr = Color(value);
              const hsl = clr.hsl();
              const [hue, saturation, luminosity] = hsl.color;
              const alpha = clr.alpha();

              this.hueControl.patchValue(hue);
              this.saturationControl.patchValue(saturation);
              this.luminosityControl.patchValue(luminosity);
              this.alphaControl.patchValue(alpha * 100);
            } catch (e) {}
          } else {
            this.hueControl.patchValue(0);
            this.saturationControl.patchValue(100);
            this.luminosityControl.patchValue(50);
            this.alphaControl.patchValue(this.alphaDefault);
          }
        }
      });
  }

  getCurrentColor(): Color {
    return Color({
      h: this.hueControl.value,
      s: this.saturationControl.value,
      l: this.luminosityControl.value,
      alpha: this.alphaControl.value / 100
    });
  }

  setHue(value: number) {
    const clr = this.getCurrentColor().hue(value);
    this.hexControlInternalUpdate = true;
    this.hexControl.patchValue(getColorHexAStr(clr));
  }

  setSaturation(value: number) {
    const clr = this.getCurrentColor().saturationl(value);
    this.hexControlInternalUpdate = true;
    this.hexControl.patchValue(getColorHexAStr(clr));
  }

  setLuminosity(value: number) {
    const clr = this.getCurrentColor().lightness(value);
    this.hexControlInternalUpdate = true;
    this.hexControl.patchValue(getColorHexAStr(clr));
  }

  setAlpha(value: number) {
    const clr = this.getCurrentColor().alpha(value / 100);
    this.hexControl.patchValue(getColorHexAStr(clr));
    this.hexControlInternalUpdate = true;
  }

  updateCurrentValue() {
    const clr = this.getCurrentColor();
    this.hexControl.patchValue(getColorHexAStr(clr));
    this.hexControlInternalUpdate = true;
  }

  pickColor() {
    this.pickColorService
      .pick()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (isSet(result)) {
            this.hexControl.patchValue(result.toUpperCase());
          }
        },
        () => {}
      );
  }

  onControlKey(e: KeyboardEvent, control: FormControl, input: HTMLInputElement) {
    if (e.keyCode == KeyboardEventKeyCode.ArrowUp) {
      e.stopPropagation();
      e.preventDefault();

      this.increment(e, control, input, true);
    } else if (e.keyCode == KeyboardEventKeyCode.ArrowDown) {
      e.stopPropagation();
      e.preventDefault();

      this.increment(e, control, input, false);
    }
  }

  increment(e: MouseEvent | KeyboardEvent, control: FormControl, input: HTMLInputElement, add: boolean) {
    const delta = this.getDelta(e);
    const value = control.value;
    const numberDefault = 0;
    const valueNumber = isSet(value) ? parseNumber(value, numberDefault) : numberDefault;
    const newValue = add ? valueNumber + delta : valueNumber - delta;

    control.patchValue(newValue);
    this.updateCurrentValue();

    setTimeout(() => input.select(), 0);
  }

  getDelta(e: MouseEvent | KeyboardEvent): number {
    if (e.shiftKey) {
      return 10;
    } else {
      return 1;
    }
  }
}
