import { CdkConnectedOverlay, ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subscription } from 'rxjs';

import { Border, BorderControl, BorderSettingsControl } from '@modules/customize';
import { createFormFieldFactory, NumberFieldType } from '@modules/fields';
import { ThemeService } from '@modules/theme';
import { firstSet } from '@shared';

import { stylesEditPopoverPositions } from '../styles-edit/styles-edit-popover-positions';

enum Side {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left'
}

@Component({
  selector: 'app-styles-edit-border',
  templateUrl: './styles-edit-border.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesEditBorderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: BorderSettingsControl;
  @Input() subtitle = 'Border';
  @Input() additional: string;
  @Input() contrast = false;
  @Input() disabled = false;
  @Input() added = false;
  @Input() removeEnabled = true;
  @Output() remove = new EventEmitter<void>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  createField = createFormFieldFactory();
  color: string;
  colors: string[];
  valueStr: string;
  dropdownOpened = false;
  side = Side.Top;
  sides = Side;
  popoverPositions = stylesEditPopoverPositions;
  popoverPositionsSubscription: Subscription;
  numberFieldTypes = NumberFieldType;

  constructor(public themeService: ThemeService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    const defaultBorder = new Border();

    combineLatest(this.control.serialize$(), this.themeService.isDarkTheme$)
      .pipe(untilDestroyed(this))
      .subscribe(([borderSettings, isDarkTheme]) => {
        if (borderSettings && borderSettings.isSidesSet()) {
          this.color = undefined;
          this.colors = [
            borderSettings.borderTop,
            borderSettings.borderRight,
            borderSettings.borderBottom,
            borderSettings.borderLeft
          ].map(item => {
            return isDarkTheme
              ? firstSet(item.colorDark, defaultBorder.colorDark)
              : firstSet(item.color, defaultBorder.color);
          });
          this.valueStr = 'Mixed value';
        } else if (borderSettings && borderSettings.border) {
          const thickness = firstSet(borderSettings.border.thickness, defaultBorder.thickness, 0);
          const style = firstSet(borderSettings.border.style, defaultBorder.style);

          this.color = isDarkTheme
            ? firstSet(borderSettings.border.colorDark, defaultBorder.colorDark)
            : firstSet(borderSettings.border.color, defaultBorder.color);
          this.colors = undefined;
          this.valueStr = `${thickness}px ${style}`;
        } else {
          this.color = undefined;
          this.colors = undefined;
          this.valueStr = undefined;
        }

        this.cd.markForCheck();
      });

    if (this.added) {
      this.setDropdownOpened(true);
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  setSide(value: Side) {
    this.side = value;
    this.cd.markForCheck();
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }

  getBorderControl(): BorderControl {
    if (this.control.controls.per_sides.value) {
      if (this.side == Side.Right) {
        return this.control.controls.border_right;
      } else if (this.side == Side.Bottom) {
        return this.control.controls.border_bottom;
      } else if (this.side == Side.Left) {
        return this.control.controls.border_left;
      } else {
        return this.control.controls.border_top;
      }
    } else {
      return this.control.controls.border;
    }
  }
}
