import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { GradientStop } from '@modules/customize';
import { ascComparator, controlValue, deployUrl, isSet, KeyboardEventKeyCode } from '@shared';

import { FillEditContext } from '../../data/fill-edit.context';
import { GradientStopControl } from '../../forms/gradient-stop.control';
import { GradientControl } from '../../forms/gradient.control';

@Component({
  selector: 'app-gradient-selector',
  templateUrl: './gradient-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GradientSelectorComponent implements OnInit, OnDestroy {
  @Input() control: GradientControl;
  @Input() source: any;

  @ViewChild('gradient_stop_track') gradientStopTrack: ElementRef;

  activeStopId: string;
  trackBackgroundSafe: SafeStyle;
  overlayClick = false;

  trackStopControlFn(i, item: GradientStopControl) {
    return item.getId() || `index_${i}`;
  }

  constructor(
    private fillEditContext: FillEditContext,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.fillEditContext.startCustomizingGradient({
      source: this.source,
      control: this.control
    });

    this.fillEditContext.customizingGradient$.pipe(untilDestroyed(this)).subscribe(value => {
      this.activeStopId = value ? value.activeStop : undefined;
      this.cd.markForCheck();
    });

    combineLatest(this.fillEditContext.customizingGradient$, controlValue(this.control.controls.stops))
      .pipe(untilDestroyed(this))
      .subscribe(([customizingGradient]) => {
        const activeStop =
          customizingGradient && isSet(customizingGradient.activeStop)
            ? this.control.controls.stops.controls.find(item => item.getId() == customizingGradient.activeStop)
            : undefined;
        const firstStop = this.control.controls.stops.controls[0];

        if (!activeStop && firstStop) {
          this.setCurrentStopControl(firstStop);
        } else if (!activeStop && customizingGradient && customizingGradient.activeStop) {
          this.setCurrentStopControl(undefined);
        }
      });

    controlValue(this.control.controls.stops)
      .pipe(
        switchMap(() => {
          if (!this.control.controls.stops.controls.length) {
            return of([]);
          }

          return combineLatest(
            this.control.controls.stops.controls
              .sort((lhs, rhs) => ascComparator(lhs.controls.position.value, rhs.controls.position.value))
              .map(control => {
                const color = control.controls.color.value;
                const position = `${control.controls.position.value * 100}%`;
                return of(`${color} ${position}`);
              })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(stopsCss => {
        const backgrounds = [];

        if (stopsCss.length) {
          backgrounds.push(`linear-gradient(to right, ${stopsCss.join(', ')})`);
        }

        backgrounds.push(`#fff center center / 10px url(${deployUrl('/assets/images/transparent.svg')})`);

        this.trackBackgroundSafe = this.sanitizer.bypassSecurityTrustStyle(backgrounds.join(', '));
        this.cd.markForCheck();
      });

    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter(e => e.keyCode == KeyboardEventKeyCode.Backspace),
        untilDestroyed(this)
      )
      .subscribe(e => {
        e.stopPropagation();

        const index = this.control.controls.stops.controls.findIndex(item => item.getId() === this.activeStopId);

        if (this.control.controls.stops.controls.length > 2 && !!this.control.controls.stops.controls[index]) {
          const newCurrentStop = index > 0 ? this.control.controls.stops.controls[index - 1] : undefined;

          this.control.controls.stops.removeAt(index);
          this.setCurrentStopControl(newCurrentStop);
        }
      });
  }

  ngOnDestroy(): void {
    this.fillEditContext.finishCustomizingGradient();
  }

  setCurrentStopControl(control?: GradientStopControl) {
    this.fillEditContext.updateCustomizingGradient({
      activeStop: control ? control.getId() : undefined
    });
  }

  createGradientStop(event: MouseEvent) {
    const bounds = this.gradientStopTrack.nativeElement.getBoundingClientRect();
    const position = clamp((event.clientX - bounds.left) / bounds.width, 0, 1);
    const stop = new GradientStop({ position: position, color: '#FFFFFF' });

    stop.generateId();

    const control = this.control.controls.stops.appendControl(stop);
    this.setCurrentStopControl(control);
  }
}
