import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { auditTime } from 'rxjs/operators';

import { ProjectSettingsStore } from '@modules/all-project-settings';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CHART_COLORS, getDatasetsEffectiveColors } from '@modules/charts';
import { ChartWidgetStyles } from '@modules/customize';
import { ChartWidget, GROUP1_OUTPUT, GROUP2_OUTPUT, GROUP3_OUTPUT, VALUE_OUTPUT } from '@modules/dashboard';
import { BooleanFieldStyle } from '@modules/field-components';
import { ModelDescription } from '@modules/models';
import { QueryBuilderContext } from '@modules/queries-components';
import { SidebarCollapseContext } from '@modules/sidebar';
import { ThemeService } from '@modules/theme';
import { controlValue, isSet } from '@shared';

import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarBaseWidgetEditComponent } from '../customize-bar-base-widget-edit/customize-bar-base-widget-edit.component';
import { ChartWidgetEditFormDatasetControl } from './chart-widget-edit-dataset.control';
import { CustomizeBarChartWidgetEditDatasetComponent } from './customize-bar-chart-widget-edit-dataset/customize-bar-chart-widget-edit-dataset.component';
import { CustomizeBarChartWidgetEditForm } from './customize-bar-chart-widget-edit.form';

@Component({
  selector: 'app-customize-bar-chart-widget-edit',
  templateUrl: './customize-bar-chart-widget-edit.component.html',
  providers: [CustomizeBarChartWidgetEditForm, QueryBuilderContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarChartWidgetEditComponent extends CustomizeBarBaseWidgetEditComponent<ChartWidget>
  implements OnInit, OnDestroy {
  elementStyles: ChartWidgetStyles;
  modelDescription: ModelDescription;
  collapseContext = new SidebarCollapseContext();
  booleanFieldStyle = BooleanFieldStyle;
  xDateLookup = false;
  datasetsColors: string[] = [];
  actionsValid$: Observable<boolean>;
  itemContextElementPaths = [[GROUP1_OUTPUT], [GROUP2_OUTPUT], [GROUP3_OUTPUT], [VALUE_OUTPUT]];
  defaultColors$ = new BehaviorSubject<string[]>(CHART_COLORS);

  constructor(
    private cd: ChangeDetectorRef,
    public form: CustomizeBarChartWidgetEditForm,
    private projectSettingsStore: ProjectSettingsStore,
    private themeService: ThemeService,
    customizeBarContext: CustomizeBarContext,
    analyticsService: UniversalAnalyticsService
  ) {
    super(form, customizeBarContext, analyticsService);
  }

  ngOnInit() {
    super.ngOnInit();

    combineLatest(this.form.firstResource$(), this.form.firstModelDescription$(), this.form.xDateLookup$())
      .pipe(auditTime(1000 / 60), untilDestroyed(this))
      .subscribe(([resource, modelDescription, xDateLookup]) => {
        this.resource = resource;
        this.modelDescription = modelDescription;
        this.xDateLookup = xDateLookup;
        this.cd.markForCheck();
      });

    combineLatest(this.projectSettingsStore.getAllSettings$(), this.themeService.isDarkTheme$)
      .pipe(untilDestroyed(this))
      .subscribe(([settings, isDarkTheme]) => {
        const defaultColors = CHART_COLORS.map((defaultColor, i) => {
          if (isDarkTheme) {
            return settings && isSet(settings.accentColorDark[i]) ? settings.accentColorDark[i] : defaultColor;
          } else {
            return settings && isSet(settings.autoColors[i]) ? settings.autoColors[i] : defaultColor;
          }
        });
        this.defaultColors$.next(defaultColors);
      });

    combineLatest(controlValue<{ color: string }[]>(this.form.controls.datasets), this.defaultColors$)
      .pipe(untilDestroyed(this))
      .subscribe(([datasets, defaultColors]) => {
        const datasetColors = datasets.map(item => item.color);
        this.datasetsColors = getDatasetsEffectiveColors(datasetColors, defaultColors);
        this.cd.markForCheck();
      });

    this.actionsValid$ = this.form.actionsValid$();

    const firstDataset = this.form.controls.datasets.controls[0];

    if (this.setupOnCreate && firstDataset) {
      this.editDataset(firstDataset, 0);
    }
  }

  editDataset(control: ChartWidgetEditFormDatasetControl, index: number) {
    const datasetColors = this.form.controls.datasets.controls.map(item => item.controls.color.value);
    const defaultColors = this.defaultColors$.value;

    this.customizeBarContext.appendSettingsComponent({
      component: CustomizeBarChartWidgetEditDatasetComponent,
      inputs: {
        widget: this.widget,
        chartType: this.form.controls.chart_type.value,
        form: control,
        groupsControl: this.form.controls.groups,
        datasetColumnEnabled: true,
        datasetColumnAllowed: index === 0,
        datasetColumnControl: this.form.controls.dataset_column,
        defaultTitle: `Dataset ${index + 1}`,
        context: this.context,
        defaultColor: getDatasetsEffectiveColors(datasetColors, defaultColors, index)[index],
        defaultColors: defaultColors,
        singleColorDataset: this.form.isSingleColorDataset(),
        firstInit: this.firstInit,
        setupOnCreate: this.setupOnCreate
      },
      outputs: {
        delete: [e => {}]
      }
    });
  }

  addDataset() {
    const item = this.form.createDataset();
    this.form.controls.datasets.append(item);
    this.editDataset(item, this.form.controls.datasets.controls.length - 1);
  }

  getDatasetTitle(form: ChartWidgetEditFormDatasetControl, index: number) {
    if (form.controls.name.value) {
      return form.controls.name.value;
    } else {
      return `Dataset ${index + 1}`;
    }
  }

  dragDrop(event: CdkDragDrop<FormControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.datasets.controls, event.previousIndex, event.currentIndex);
      this.form.controls.datasets.updateValueAndValidity();
    }
  }
}
