import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { TintStyle } from '@modules/actions';
import { CustomizeService } from '@modules/customize';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';

import { ProjectAppearanceContext } from '../project-settings/project-appearance.context';

@Component({
  selector: 'app-customize-bar-appearance-edit-buttons',
  templateUrl: './customize-bar-appearance-edit-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarAppearanceEditButtonsComponent implements OnInit, OnDestroy {
  @Input() context: ProjectAppearanceContext;

  styleControl = new FormControl(TintStyle.Primary);
  styleOptions = [
    {
      value: TintStyle.Primary,
      name: 'Primary',
      image: 'button-primary'
    },
    {
      value: TintStyle.Default,
      name: 'Default',
      image: 'button-default'
    },
    {
      value: TintStyle.Transparent,
      name: 'Transparent',
      image: 'button-transparent'
    }
  ];
  hasChanges$: Observable<boolean>;
  submitLoading = false;
  tintStyles = TintStyle;

  constructor(
    private customizeService: CustomizeService,
    public currentProjectStore: CurrentProjectStore,
    public themeService: ThemeService,
    private routing: RoutingService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.hasChanges$ = this.context.getHasChanges$();
  }

  ngOnDestroy(): void {}

  back() {
    const link = this.currentProjectStore.instance.settingsLayoutLink('appearance');
    this.routing.navigateApp(link);
  }

  cancel() {
    this.customizeService.stopTrackChanges();
    this.context.resetSavedState();
    this.customizeService.startTrackChanges();
  }

  submit() {
    if (this.submitLoading) {
      return;
    }

    this.submitLoading = true;
    this.cd.markForCheck();

    this.context
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.customizeService.stopTrackChanges();
          this.context.saveCurrentState();
          this.customizeService.startTrackChanges();
          this.submitLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }
}
