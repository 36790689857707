<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <ng-template let-image="image" let-name="name" let-data="data" #options_template>
    <!--    <span class="options__item-inner">-->
    <span *ngIf="image" class="options__item-image" [style.background-image]="'url(' + image + ')'"></span>
    <span class="options__item-label">
      <div>{{ name }}</div>
      <small>{{ data?.resource }}</small>
    </span>
    <!--    </span>-->
  </ng-template>
  <gxs-select
    [placeholder]="'choose' | localize | appCapitalize"
    [formControlName]="field.name"
    [id]="field.name | appUniqueId: idToken"
    [source]="source"
    [options]="{
      theme: 'jet',
      search: true,
      searchMinimumLength: 1,
      searchDebounce: 0,
      classes: field.params['classes'],
      labels: {} | localizeSelect
    }"
    [optionTemplate]="options_template"
  >
  </gxs-select>
</app-field-wrapper>
<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <ng-container *ngIf="valueStr">{{ valueStr }}</ng-container>
  <span *ngIf="!valueStr" class="loading-animation"><span class="stub-text">Record Name</span></span>
</app-field-wrapper>
