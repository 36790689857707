import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { forceObservable } from '@shared';

export interface UnsavedChangesHandler {
  hasUnsavedChanges(currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<UnsavedChangesHandler> {
  constructor(private dialogService: DialogService) {}

  canDeactivate(
    component: UnsavedChangesHandler,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!component.hasUnsavedChanges) {
      return true;
    }

    return forceObservable(component.hasUnsavedChanges(currentState, nextState)).pipe(
      switchMap(hasChanges => {
        if (hasChanges) {
          return this.dialogService.warning({
            title: 'Unsaved changes',
            description: 'Are you sure want to leave this page? Your unsaved changes will be lost',
            style: 'orange',
            buttons: [
              {
                name: 'cancel',
                label: 'Cancel',
                type: DialogButtonType.Default,
                hotkey: DialogButtonHotkey.Cancel
              },
              {
                name: 'ok',
                label: 'Discard changes',
                type: DialogButtonType.Danger,
                hotkey: DialogButtonHotkey.Submit
              }
            ]
          });
        }

        return of(true);
      })
    );
  }
}
