<app-field-wrapper
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [theme]="field.params['theme']"
>
  <input
    class="input input_stub"
    [class.input_theme]="field.params['theme']"
    [class.input_styles]="field.params['theme']"
    [class.input_fill]="true"
    [class.loading-animation]="true"
    [appFieldElementStyles]="elementStyles"
    [appFieldElementStylesEnabled]="field.params['theme']"
    type="text"
    value="Value"
    [disabled]="true"
  />
</app-field-wrapper>
