<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
    [cdkConnectedOverlayPositions]="dropdownPositions"
    [cdkConnectedOverlayOrigin]="dropdownTrigger"
    [cdkConnectedOverlayOpen]="dropdownOpened"
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayGrowAfterOpen]="true"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
    (backdropClick)="setDropdownOpened(false)"
    (detach)="setDropdownOpened(false)"
  >
    <app-field-type-dropdown
      class="overlay-content overlay-content_animated"
      [sections]="fieldTypeSections"
      [currentValue]="currentValue"
      (select)="setDropdownOpened(false); setCurrentValue($event)"
    ></app-field-type-dropdown>
  </ng-template>

  <div class="select select_theme_jet_orange" [ngClass]="field.params['classes']">
    <a
      href="javascript:void(0)"
      class="select__input"
      [class.select__input_opened]="dropdownOpened"
      cdkOverlayOrigin
      #dropdownTrigger="cdkOverlayOrigin"
      (click)="onInputClick()"
    >
      <span *ngIf="currentValueIcon" class="select__input-icon" [ngClass]="'icon-' + currentValueIcon"></span>

      <span class="select__input-label">
        <ng-container *ngIf="currentValueLabel | appIsSet">{{ currentValueLabel }}</ng-container>
        <ng-container *ngIf="!(currentValueLabel | appIsSet)">Choose Field type</ng-container>
      </span>
      <span class="select__input-arrow"></span>
    </a>
  </div>
</app-field-wrapper>

<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <ng-container *ngIf="currentValueLabel | appIsSet">{{ currentValueLabel }}</ng-container>
  <ng-container *ngIf="!(currentValueLabel | appIsSet)">---</ng-container>
</app-field-wrapper>
