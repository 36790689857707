<div class="color-selector" [style.width.px]="width">
  <div *ngIf="items?.length" class="color-selector__items">
    <div *ngFor="let item of items" class="color-selector__item">
      <a
        href="javascript:void(0)"
        class="color-selector-item"
        [class.color-selector-item_palette]="false"
        [style.background-color]="item.color"
        [class.color-selector-item_active]="current == item.value"
        (click)="select(item)"
        [appTip]="!item.value ? 'Reset color' : undefined"
        [appTipOptions]="{ side: 'top' }"
      >
        <span
          class="color-selector-item__border"
          [style.border-color]="!item.value && (emptyColor | appIsSet) ? emptyColor : item.color"
        ></span>

        <span
          *ngIf="!item.value"
          class="color-selector-item__icon color-selector-item__icon_contrast icon-close"
          [style.color]="item.color ? getContrastColor(item.color) : null"
        ></span>

        <span
          *ngIf="item.value && current == item.value"
          class="color-selector-item__icon icon-check_2"
          [style.color]="getContrastColor(item.value)"
        ></span>
      </a>
    </div>
  </div>

  <div *ngIf="customColors" class="color-selector__custom">
    <app-color-picker
      [hexControl]="customColorControl"
      [alphaEnabled]="alphaEnabled"
      [accentColor]="accentColor"
      [theme]="theme"
    ></app-color-picker>
  </div>
</div>
