/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../gradient-selector-stop-position/gradient-selector-stop-position.directive";
import * as i2 from "@angular/common";
import * as i3 from "../color-selector/color-selector.component.ngfactory";
import * as i4 from "../color-selector/color-selector.component";
import * as i5 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i6 from "../../../../shared/pipes/format-number/format-number.pipe";
import * as i7 from "../../../../common/tips/directives/tip/tip.directive";
import * as i8 from "./gradient-selector.component";
import * as i9 from "../../data/fill-edit.context";
import * as i10 from "@angular/platform-browser";
var styles_GradientSelectorComponent = [];
var RenderType_GradientSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GradientSelectorComponent, data: {} });
export { RenderType_GradientSelectorComponent as RenderType_GradientSelectorComponent };
function View_GradientSelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["appGradientSelectorStopPosition", ""], ["class", "slider__position slider__value-origin"]], [[2, "slider__position_active", null], [4, "left", "%"]], [[null, "click"], [null, "gradientStopMouseDown"], [null, "gradientStopSetPosition"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setCurrentStopControl(_v.context.$implicit);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } if (("gradientStopMouseDown" === en)) {
        var pd_1 = (_co.setCurrentStopControl(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("gradientStopSetPosition" === en)) {
        var pd_2 = (_v.context.$implicit.controls.position.patchValue($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 212992, [["gradient_stop_position", 4]], 0, i1.GradientSelectorStopPositionDirective, [i0.ElementRef], { gradientStopBounds: [0, "gradientStopBounds"] }, { gradientStopMouseDown: "gradientStopMouseDown", gradientStopSetPosition: "gradientStopSetPosition" }), i0.ɵppd(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "slider__value"]], [[2, "slider__value_active", null], [2, "slider__value_position_top", null], [2, "slider__value_position_bottom", null]], null, null, null, null)), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(5, null, [" ", " "])), i0.ɵppd(6, 2)], function (_ck, _v) { var currVal_2 = i0.ɵnov(_v.parent, 8); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.activeStopId)) && (_co.activeStopId == _v.context.$implicit.getId())); var currVal_1 = (_v.context.$implicit.controls.position.value * 100); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(i0.ɵnov(_v, 1).dragging$)); var currVal_4 = true; var currVal_5 = false; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 1), _v.context.$implicit.controls.position.value, "0%")); _ck(_v, 5, 0, currVal_6); }); }
function View_GradientSelectorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-color-selector", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; if (("selected" === en)) {
        var pd_0 = (_v.parent.context.$implicit.controls.color.patchValue($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ColorSelectorComponent_0, i3.RenderType_ColorSelectorComponent)), i0.ɵdid(1, 770048, null, 0, i4.ColorSelectorComponent, [i0.ChangeDetectorRef], { current: [0, "current"], colors: [1, "colors"], customColors: [2, "customColors"], alphaEnabled: [3, "alphaEnabled"] }, { selected: "selected" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.controls.color.value; var currVal_1 = i0.ɵEMPTY_ARRAY; var currVal_2 = true; var currVal_3 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_GradientSelectorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_GradientSelectorComponent_3)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(3, 1), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.activeStopId)) && (_v.context.$implicit.getId() == _co.activeStopId)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GradientSelectorComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.IsSetPipe, []), i0.ɵpid(0, i6.FormatNumberPipe, []), i0.ɵqud(402653184, 1, { gradientStopTrack: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 19, "div", [["class", "sidebar__element sidebar__element_margin_s"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 18, "div", [["class", "sidebar-cols sidebar-cols_align_center sidebar-cols_margin_s"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 7, "div", [["class", "sidebar-cols__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "slider"]], [[2, "slider_active", null], [2, "slider_thumb_inside", null]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 5, "div", [["class", "slider__track-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, [[1, 0], ["gradient_stop_track", 1]], null, 4, "div", [["class", "slider__track"]], [[4, "background", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createGradientStop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "div", [["class", "slider__track-fill-start"]], [[4, "background", null]], null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "slider__track-fill"]], [[4, "background", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GradientSelectorComponent_1)), i0.ɵdid(12, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 4, "div", [["class", "sidebar-cols__item sidebar-cols__item_fit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 3, "div", [["class", "button button_extra-small button_icon button_no-margin"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.control.invertStops() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 737280, null, 0, i7.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(16, { side: 0 }), (_l()(), i0.ɵeld(17, 0, null, null, 0, "span", [["class", "button__label icon-reflect"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 4, "div", [["class", "sidebar-cols__item sidebar-cols__item_fit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 3, "div", [["class", "button button_extra-small button_icon button_no-margin"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.control.rotateStops() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(20, 737280, null, 0, i7.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(21, { side: 0 }), (_l()(), i0.ɵeld(22, 0, null, null, 0, "span", [["class", "button__label icon-repeat"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GradientSelectorComponent_2)), i0.ɵdid(24, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.control.controls.stops.controls; var currVal_6 = _co.trackStopControlFn; _ck(_v, 12, 0, currVal_5, currVal_6); var currVal_7 = "Invert gradient"; var currVal_8 = _ck(_v, 16, 0, "top"); _ck(_v, 15, 0, currVal_7, currVal_8); var currVal_9 = "Rotate gradient"; var currVal_10 = _ck(_v, 21, 0, "top"); _ck(_v, 20, 0, currVal_9, currVal_10); var currVal_11 = _co.control.controls.stops.controls; _ck(_v, 24, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = true; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = _co.trackBackgroundSafe; _ck(_v, 8, 0, currVal_2); var currVal_3 = "transparent"; _ck(_v, 9, 0, currVal_3); var currVal_4 = "transparent"; _ck(_v, 10, 0, currVal_4); }); }
export function View_GradientSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-gradient-selector", [], null, null, null, View_GradientSelectorComponent_0, RenderType_GradientSelectorComponent)), i0.ɵdid(1, 245760, null, 0, i8.GradientSelectorComponent, [i9.FillEditContext, i10.DomSanitizer, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GradientSelectorComponentNgFactory = i0.ɵccf("app-gradient-selector", i8.GradientSelectorComponent, View_GradientSelectorComponent_Host_0, { control: "control", source: "source" }, {}, []);
export { GradientSelectorComponentNgFactory as GradientSelectorComponentNgFactory };
