<div
  class="table-action"
  [class.table-action_icon]="!verboseName"
  [class.table-action_disabled]="disabled || submitLoading"
  [appTintColor]="accentColor"
  [appTintColorStyle]="tintStyles.Primary"
  (click)="execute(button_element)"
  #button_element
>
  <app-loader-small *ngIf="submitLoading" class="table-action__icon"></app-loader-small>
  <span
    *ngIf="action.icon && !submitLoading"
    class="table-action__icon"
    [class.table-action__icon_image]="
      (action.icon | appIconType) == iconTypes.Emoji || (action.icon | appIconType) == iconTypes.Image
    "
    [appIcon]="action.icon"
  ></span>

  <span *ngIf="verboseName" class="table-action__label">{{ verboseName | appCapitalize }}</span>
</div>

<span
  *ngIf="!isVisible && (customizeService.enabled$ | async)"
  class="element__indicator element__indicator_outside icon-eye"
  [appTip]="'Action is hidden'"
></span>
