var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { TintStyle } from '@modules/actions';
import { ActionElementStyles, AlignHorizontal } from '@modules/customize';
import { ActionElementStylesControl } from '@modules/customize-bar';
import { controlValue } from '@shared';
var StylesPreviewButtonStyleComponent = /** @class */ (function () {
    function StylesPreviewButtonStyleComponent(cd) {
        this.cd = cd;
        this.label = 'Button';
        this.horizontal = false;
        this.alignHorizontals = AlignHorizontal;
        this.tintStyles = TintStyle;
    }
    StylesPreviewButtonStyleComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(controlValue(this.control), this.control.stylesDefaultUpdated$())
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.elementStyles = new ActionElementStyles({
                textStyle: _this.control.controls.text_style.serialize(),
                iconSettings: _this.control.controls.icon_settings.serialize(false),
                fillSettings: _this.control.controls.fill_settings.serialize(false),
                borderSettings: _this.control.controls.border_settings.serialize(false),
                borderRadius: _this.control.controls.border_radius.serialize(),
                shadow: _this.control.controls.shadow.serialize(false),
                padding: _this.control.controls.padding.serialize(),
                margin: _this.control.controls.margin.serialize()
            });
            _this.hoverElementStyles = new ActionElementStyles(__assign({ textStyle: _this.control.controls.text_style.serialize(), iconSettings: _this.control.controls.icon_settings.serialize(false), fillSettings: _this.control.controls.fill_settings.serialize(false), borderSettings: _this.control.controls.border_settings.serialize(false), borderRadius: _this.control.controls.border_radius.serialize(), shadow: _this.control.controls.shadow.serialize(false), padding: _this.control.controls.padding.serialize(), margin: _this.control.controls.margin.serialize() }, (_this.control.controls.hover_text_style.isSet() && {
                textStyle: _this.control.controls.hover_text_style.serialize()
            }), (_this.control.controls.hover_icon_settings.isSet() && {
                iconSettings: _this.control.controls.hover_icon_settings.serialize(false)
            }), (_this.control.controls.hover_fill_settings.isSet() && {
                fillSettings: _this.control.controls.hover_fill_settings.serialize(false)
            }), (_this.control.controls.hover_border_settings.isSet() && {
                borderSettings: _this.control.controls.hover_border_settings.serialize(false)
            }), (_this.control.controls.hover_shadow.isSet() && {
                shadow: _this.control.controls.hover_shadow.serialize(false)
            })));
            _this.activeElementStyles = new ActionElementStyles(__assign({ textStyle: _this.control.controls.text_style.serialize(), iconSettings: _this.control.controls.icon_settings.serialize(false), fillSettings: _this.control.controls.fill_settings.serialize(false), borderSettings: _this.control.controls.border_settings.serialize(false), borderRadius: _this.control.controls.border_radius.serialize(), shadow: _this.control.controls.shadow.serialize(false), padding: _this.control.controls.padding.serialize(), margin: _this.control.controls.margin.serialize() }, (_this.control.controls.active_text_style.isSet() && {
                textStyle: _this.control.controls.active_text_style.serialize()
            }), (_this.control.controls.active_icon_settings.isSet() && {
                iconSettings: _this.control.controls.active_icon_settings.serialize(false)
            }), (_this.control.controls.active_fill_settings.isSet() && {
                fillSettings: _this.control.controls.active_fill_settings.serialize(false)
            }), (_this.control.controls.active_border_settings.isSet() && {
                borderSettings: _this.control.controls.active_border_settings.serialize(false)
            }), (_this.control.controls.active_shadow.isSet() && {
                shadow: _this.control.controls.active_shadow.serialize(false)
            })));
            _this.cd.markForCheck();
        });
    };
    StylesPreviewButtonStyleComponent.prototype.ngOnDestroy = function () { };
    return StylesPreviewButtonStyleComponent;
}());
export { StylesPreviewButtonStyleComponent };
