import { Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { UniqueIdToken } from '@common/unique-id';
import {
  ElementActions,
  FieldElementStyles,
  TextStyle,
  TextStyleGlobalName,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { FieldLabelButton, FormField } from '@modules/fields';
import { controlValue, isEmptyArray, isSet } from '@shared';

export abstract class FieldComponent {
  @Input() form: FormGroup;
  @Input() field: FormField;
  @Input() readonly = false;
  @Input() requiredAsterisk = false;
  @Input() value: any;
  @Input() label = true;
  @Input() labelStyle: TextStyle;
  @Input() labelStyleGlobal: TextStyleGlobalName;
  @Input() labelAdditional: string;
  @Input() labelAdditionalStyle: TextStyle;
  @Input() labelAdditionalStyleGlobal: TextStyleGlobalName;
  @Input() elementStyles: FieldElementStyles;
  @Input() errors = true;
  @Input() autofocus = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() fieldContextElement: ViewContextElement;
  @Input() truncate = false;
  @Input() compact = false;
  @Input() manualMargin = false;
  @Input() labelButtons: FieldLabelButton[] = [];
  @Input() elementActions: ElementActions[] = [];
  @Input() tooltip: string;
  @Input() accentColor: string;

  idToken = new UniqueIdToken();

  get control(): FormControl {
    return this.form && this.field ? (this.form.controls[this.field.name] as FormControl) : undefined;
  }

  get currentValue(): any {
    const control = this.control;

    if (control) {
      return control.value;
    } else {
      return this.value;
    }
  }

  get currentValue$(): Observable<any> {
    const control = this.control;

    if (control) {
      return controlValue(control);
    } else {
      return of(this.value);
    }
  }

  setCurrentValue(value: any) {
    const control = this.control;

    if (!control || control.disabled) {
      return;
    }

    control.patchValue(value);
    control.markAsDirty();
  }

  isEmpty(value: any): boolean {
    return !isSet(value) || isEmptyArray(value);
  }

  formatEmpty(value: any): any {
    if (this.isEmpty(value)) {
      return '---';
    } else {
      return value;
    }
  }

  resetCurrentValue(value = '') {
    this.setCurrentValue(value);
  }

  focus() {}
}
