import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { Shadow, ShadowControl } from '@modules/customize';
import { createFormFieldFactory, NumberFieldType } from '@modules/fields';
import { ThemeService } from '@modules/theme';
import { firstSet } from '@shared';
import { stylesEditPopoverPositions } from '../styles-edit/styles-edit-popover-positions';
var StylesEditShadowComponent = /** @class */ (function () {
    function StylesEditShadowComponent(themeService, cd) {
        this.themeService = themeService;
        this.cd = cd;
        this.subtitle = 'Shadow';
        this.contrast = false;
        this.disabled = false;
        this.added = false;
        this.removeEnabled = true;
        this.remove = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.dropdownOpened = false;
        this.popoverPositions = stylesEditPopoverPositions;
        this.numberFieldTypes = NumberFieldType;
    }
    StylesEditShadowComponent.prototype.ngOnInit = function () {
        var _this = this;
        var defaultShadow = new Shadow();
        combineLatest(this.control.serialize$(), this.themeService.isDarkTheme$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var shadow = _a[0], isDarkTheme = _a[1];
            if (shadow) {
                var offsetX = firstSet(shadow.offsetX, defaultShadow.offsetX, 0);
                var offsetY = firstSet(shadow.offsetY, defaultShadow.offsetY, 0);
                var blurRadius = firstSet(shadow.blurRadius, defaultShadow.blurRadius, 0);
                var spreadRadius = firstSet(shadow.spreadRadius, defaultShadow.spreadRadius, 0);
                _this.color = isDarkTheme
                    ? firstSet(shadow.colorDark, defaultShadow.colorDark)
                    : firstSet(shadow.color, defaultShadow.color);
                _this.valueStr = offsetX + "px " + offsetY + "px " + blurRadius + "px " + spreadRadius + "px";
            }
            else {
                _this.color = undefined;
                _this.valueStr = undefined;
            }
            _this.cd.markForCheck();
        });
        if (this.added) {
            this.setDropdownOpened(true);
        }
    };
    StylesEditShadowComponent.prototype.ngOnDestroy = function () { };
    StylesEditShadowComponent.prototype.ngAfterViewInit = function () {
        this.setPositionObserver();
    };
    StylesEditShadowComponent.prototype.setDropdownOpened = function (value) {
        this.dropdownOpened = value;
        this.cd.markForCheck();
    };
    StylesEditShadowComponent.prototype.setPositionObserver = function () {
        var _this = this;
        if (this.popoverPositionsSubscription) {
            this.popoverPositionsSubscription.unsubscribe();
        }
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.popoverPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.popoverPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    return StylesEditShadowComponent;
}());
export { StylesEditShadowComponent };
