<app-page [paddingLeft]="true">
  <app-section [top]="true">
    <app-page-header>
      <app-page-title>Automations</app-page-title>
      <app-page-description>
        Automations allow you to automate repetitive tasks and user flows without leaving Jet.
      </app-page-description>

      <app-page-header-right>
        <a
          href="javascript:void(0)"
          class="button button_primary button_shadow"
          [class.button_disabled]="loading || createLoading"
          (click)="create()"
        >
          <app-loader-small *ngIf="createLoading" class="button__icon button__icon_left"></app-loader-small>
          <span *ngIf="!createLoading" class="button__icon button__icon_left icon-plus"></span>
          <span class="button__label">Add Automation</span>
        </a>
      </app-page-header-right>
    </app-page-header>

    <app-section-tabs>
      <app-section-tab
        [label]="'All Automations'"
        [active]="true"
        [link]="currentProjectStore.instance.automationsLink"
      ></app-section-tab>

      <app-section-tab
        [label]="'Run history'"
        [active]="false"
        [link]="currentProjectStore.instance.automationRunsLink"
      ></app-section-tab>
    </app-section-tabs>
  </app-section>
</app-page>

<ng-template let-size="size" #table_rows_stub_template>
  <tr *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8].slice(0, size || 4)" class="card-table__row">
    <td class="card-table__column"></td>
    <td class="card-table__column">
      <div class="card-table__column-inner">
        <span [class.loading-animation]="true"><span class="stub-text">Automation</span></span>
      </div>
    </td>
    <td class="card-table__column">
      <div class="card-table__column-inner">
        <span [class.loading-animation]="true"><span class="stub-text">Success</span></span>
      </div>
    </td>
    <td class="card-table__column">
      <div class="card-table__column-inner"></div>
    </td>
  </tr>
</ng-template>

<ng-template #table_stub_template>
  <table class="card-table">
    <thead>
      <tr class="card-table__head-row">
        <th class="card-table__head-column"></th>
        <th class="card-table__head-column">
          <span [class.loading-animation]="true"><span class="stub-text">Name</span></span>
        </th>
        <th class="card-table__head-column">
          <span [class.loading-animation]="true"><span class="stub-text">Run</span></span>
        </th>
        <th class="card-table__head-column"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngTemplateOutlet="table_rows_stub_template"></ng-container>
    </tbody>
  </table>
</ng-template>

<app-page [paddingHorizontal]="true">
  <app-page-block [paddingSize]="'none'">
    <ng-container *ngIf="!loading && automations?.length">
      <table class="card-table">
        <thead>
          <tr class="card-table__head-row">
            <th class="card-table__head-column"></th>
            <th class="card-table__head-column">Name</th>
            <th class="card-table__head-column">Last run</th>
            <th class="card-table__head-column"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of automations; trackBy: trackAutomationFn"
            class="card-table__row card-table__row_clickable"
            [class.card-table__row_disabled]="!item.active"
            [item]="item"
            app-automations-item
            (edit)="editItem(item)"
            (duplicate)="create(item)"
            (deleted)="fetch()"
            (updated)="updateItem($event)"
          ></tr>

          <!--      <ng-container *ngIf="loading$ | async">-->
          <!--        <ng-container *ngTemplateOutlet="table_rows_stub_template; context: { size: 3 }"></ng-container>-->
          <!--      </ng-container>-->
        </tbody>
      </table>

      <!--    <div *ngIf="(hasMore$ | async) && !(loading$ | async)" style="margin-top: 20px; text-align: center;">-->
      <!--      <a href="javascript:void(0)" class="button button_background button_small" (click)="getNext()">-->
      <!--        <span class="button__label">Load more</span>-->
      <!--      </a>-->
      <!--    </div>-->
    </ng-container>

    <div *ngIf="!loading && !automations?.length" class="status-block">
      <div class="status-block__main">
        <div class="status-block__content">
          <h1 class="status-block__title">No automations yet</h1>
          <div class="status-block__description">
            Start building automations to automate repetitive tasks and user flows without leaving Jet.
          </div>
          <div class="status-block__actions">
            <a
              href="javascript:void(0)"
              class="status-block__button button button_primary button_shadow"
              [class.button_disabled]="loading || createLoading"
              (click)="create()"
            >
              <app-loader-small *ngIf="createLoading" class="button__icon button__icon_left"></app-loader-small>
              <span *ngIf="!createLoading" class="button__icon button__icon_left icon-plus"></span>
              <span class="button__label">Add Automation</span>
            </a>
          </div>
        </div>
      </div>
      <div class="status-block__right">
        <div class="status-block__image">
          <div class="status-block__image-inner status-block__image-inner_type_not-found"></div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="loading && !automations?.length">
      <ng-container *ngTemplateOutlet="table_stub_template"></ng-container>
    </ng-container>
  </app-page-block>
</app-page>
