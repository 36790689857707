import { EditableFlexField, Input } from '@modules/fields';
import { isSet } from '@shared';

import { TextStyle } from '../../text-style';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class FilterElementInput {
  name: string;
  settings: EditableFlexField;
  labelStyle: TextStyle;
  labelAdditional: string;
  labelAdditionalStyle: TextStyle;
  tooltip: string;
  weight: number;

  deserialize(data: Object): FilterElementInput {
    this.name = data['name'];
    this.tooltip = data['tooltip'];
    this.weight = data['weight'];

    if (data['settings']) {
      this.settings = data['settings'];
      this.settings.valueInput = data['settings']['valueInput']
        ? new Input().deserialize(data['settings']['valueInput'])
        : undefined;
    } else {
      this.settings = undefined;
    }

    if (data['label_style']) {
      this.labelStyle = new TextStyle().deserialize(data['label_style']);
    } else {
      this.labelStyle = undefined;
    }

    if (isSet(data['label_additional'])) {
      this.labelAdditional = data['label_additional'];
    }

    if (data['label_additional_style']) {
      this.labelAdditionalStyle = new TextStyle().deserialize(data['label_additional_style']);
    } else {
      this.labelAdditionalStyle = undefined;
    }

    return this;
  }

  serialize(): Object {
    return {
      name: this.name,
      settings: this.settings
        ? {
            ...this.settings,
            ...(this.settings.valueInput && { valueInput: this.settings.valueInput.serialize() })
          }
        : undefined,
      label_style: this.labelStyle ? this.labelStyle.serialize() : undefined,
      label_additional: this.labelAdditional,
      label_additional_style: this.labelAdditionalStyle ? this.labelAdditionalStyle.serialize() : undefined,
      tooltip: this.tooltip,
      weight: this.weight
    };
  }
}

export enum FilterStyle {
  Wrap = 'wrap',
  Background = 'background'
}

export class FilterElementItem extends ElementItem {
  public type = ElementType.Filter;
  public elements: string[] = [];
  public elementInputs: FilterElementInput[] = [];
  public style: FilterStyle = FilterStyle.Background;

  deserialize(data: Object): FilterElementItem {
    super.deserialize(data);

    if (this.params['elements']) {
      this.elements = this.params['elements'];
    }

    if (this.params['element_inputs']) {
      this.elementInputs = this.params['element_inputs'].map(item => new FilterElementInput().deserialize(item));
    }

    if (this.params['style']) {
      this.style = this.params['style'];
    }

    return this;
  }

  serialize(): Object {
    this.params = {
      elements: this.elements,
      element_inputs: this.elementInputs.map(item => item.serialize()),
      style: this.style
    };
    return super.serialize();
  }

  get analyticsName(): string {
    return 'filter';
  }

  defaultName() {
    return 'Filter';
  }
}

registerElementForType(ElementType.Filter, FilterElementItem);
