<div class="action-button action-button_manual-margin">
  <button
    type="submit"
    class="button button_theme button_styles form__button"
    [class.button_primary]="!element.tint && style == tintStyles.Primary"
    [class.button_primary-transparent]="element.tint && style == tintStyles.Transparent"
    [class.button_icon]="!(verboseName | appIsSet)"
    [class.button_fill]="element.alignHorizontal == alignHorizontals.Justify"
    [appButtonTintColor]="element.tint || accentColor"
    [appButtonTintColorStyle]="element.style"
    [appActionElementStyles]="undefined"
    [appActionElementStylesTintColorStyle]="element.style"
    [disabled]="(isFormSubmitDisabled$ | async) || disabled"
  >
    <app-loader-small
      *ngIf="formElement?.submitLoading$ | async"
      class="button__icon"
      [class.button__icon_left]="verboseName | appIsSet"
    ></app-loader-small>
    <span
      *ngIf="!(formElement?.submitLoading$ | async) && element.icon"
      class="button__icon"
      [class.button__icon_left]="verboseName | appIsSet"
      [appIcon]="element.icon"
    ></span>
    <span *ngIf="verboseName | appIsSet" class="button__label">
      {{ verboseName || 'Submit' }}
    </span>
  </button>
</div>
