import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge } from 'rxjs';

import { OnBeforeDestroy } from '@common/lifecycle';
import { IgnoreScrollTop } from '@common/route-scroll';
import { CustomizeService, UnsavedChangesHandler, WithBuilderMode } from '@modules/customize';

import { ChangeComponent } from '../change/change.component';

@Component({
  selector: 'app-change-route',
  templateUrl: './change-route.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@WithBuilderMode
@IgnoreScrollTop({
  predicate: (newSnapshot, prevSnapshot) => {
    const model = newSnapshot.params['model'];
    const prevModel = prevSnapshot ? prevSnapshot.params['model'] : undefined;
    const id = newSnapshot.params['id'];
    const prevId = prevSnapshot ? prevSnapshot.params['id'] : undefined;

    return model == prevModel && id == prevId;
  }
})
export class ChangeRouteComponent implements OnInit, OnDestroy, OnBeforeDestroy, UnsavedChangesHandler {
  @ViewChild(ChangeComponent) changeComponent: ChangeComponent;

  modelId: string;
  id: string;
  params = {};

  constructor(
    public customizeService: CustomizeService,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    merge(this.activatedRoute.params, this.activatedRoute.queryParams)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const snapshot = this.activatedRoute.snapshot;

        this.modelId = snapshot.params['model'];
        this.id = snapshot.params['id'];
        this.params = snapshot.queryParams;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  onBeforeDestroy(): void {
    if (this.changeComponent) {
      this.changeComponent.onBeforeDestroy();
    }
  }

  hasUnsavedChanges(): boolean {
    return this.changeComponent.hasChanges();
  }

  getCollaborationParams(): Object {
    const snapshot = this.activatedRoute.snapshot;
    return {
      object_type: ['model', snapshot.params['model']].join('.'),
      object_id: snapshot.params['id']
    };
  }

  getUserActivitiesParams(): Object {
    const snapshot = this.activatedRoute.snapshot;
    return {
      object_type: ['model', snapshot.params['model']].join('.'),
      object_id: snapshot.params['id']
    };
  }
}
