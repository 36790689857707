import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { AppDragDrop, DragAxis } from '@common/drag-drop2';
import { CustomizeService, Fill, FillSettings, FillType } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import { MenuBlock, MenuBlockLayout, MenuBlockLayouts } from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';
import { capitalize, controlValue, getNumberOrdinal } from '@shared';

import { LayoutGroup, LayoutOption } from '../menu-block-layout-overlay/menu-block-layout-overlay.component';
import { MenuBlockControl } from '../project-settings/menu-block.control';
import { ProjectAppearanceContext } from '../project-settings/project-appearance.context';

interface MenuBlockItem {
  uid: string;
  title: string;
  control: MenuBlockControl;
}

@Component({
  selector: 'app-customize-bar-menu-edit',
  templateUrl: './customize-bar-menu-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarMenuEditComponent implements OnInit, OnDestroy {
  @Input() context: ProjectAppearanceContext;
  @Input() blockControlPreviewHover$ = new BehaviorSubject<MenuBlockControl>(undefined);
  @Output() blockControlHover = new EventEmitter<MenuBlockControl>();

  createField = createFormFieldFactory();
  layoutGroups: LayoutGroup[] = [
    {
      label: 'Vertical',
      options: [
        {
          layout: MenuBlockLayout.LeftThin,
          image: 'layout-primary-menu-left',
          title: 'Thin width',
          subtitle: 'Left position',
          color: MenuBlockLayouts.getDefaultColor(MenuBlockLayout.LeftThin)
        },
        {
          layout: MenuBlockLayout.LeftWide,
          image: 'layout-menu-left',
          title: 'Wide width',
          subtitle: 'Left position'
        }
      ]
    },
    {
      label: 'Horizontal',
      options: [
        {
          layout: MenuBlockLayout.TopThin,
          image: 'layout-primary-menu-top',
          title: 'Primary menu',
          subtitle: 'Top position',
          color: MenuBlockLayouts.getDefaultColor(MenuBlockLayout.LeftThin)
        },
        {
          layout: MenuBlockLayout.TopThin,
          image: 'layout-menu-top',
          title: 'Secondary menu',
          subtitle: 'Top position',
          color: ''
        },
        {
          layout: MenuBlockLayout.TopContentThin,
          image: 'layout-menu-top-content',
          title: 'Inner menu',
          subtitle: 'Content top position'
        }
      ]
    }
  ];
  blockItems: MenuBlockItem[] = [];
  blockItemParamSubscription: Subscription;
  blockControlHover$ = new BehaviorSubject<MenuBlockControl>(undefined);
  hasChanges$: Observable<boolean>;
  submitLoading = false;
  dragAxis = DragAxis;

  trackMenuBlockItemFn(i, item: MenuBlockItem) {
    return item.control.instance.uid;
  }

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private customizeService: CustomizeService,
    public themeService: ThemeService,
    private routing: RoutingService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.hasChanges$ = this.context.getHasChanges$();

    controlValue(this.context.controls.menu.controls.blocks)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateBlockItems());

    this.blockControlHover$.pipe(untilDestroyed(this)).subscribe(value => this.blockControlHover.emit(value));
  }

  ngOnDestroy(): void {}

  updateBlockItems() {
    if (this.blockItemParamSubscription) {
      this.blockItemParamSubscription.unsubscribe();
      this.blockItemParamSubscription = undefined;
    }

    let leftI = 0;
    let topI = 0;

    this.blockItems = this.context.controls.menu.controls.blocks.controls.map(control => {
      let title: string;

      if (!control.controls.layout.value) {
        title = 'New menu';
      } else if (MenuBlockLayouts.isLeft(control.controls.layout.value)) {
        title = `Left ${capitalize(getNumberOrdinal(leftI + 1))} menu`;
        leftI += 1;
      } else if (MenuBlockLayouts.isTop(control.controls.layout.value)) {
        title = `Top ${capitalize(getNumberOrdinal(topI + 1))} menu`;
        topI += 1;
      }

      return {
        uid: control.instance ? control.instance.uid : undefined,
        title: title,
        control: control
      };
    });
    this.cd.markForCheck();
  }

  addMenuBlock(option: LayoutOption) {
    const block = new MenuBlock();

    block.generateUid();
    block.layout = option.layout;
    block.fillSettings = new FillSettings({
      fill: new Fill({ type: FillType.Color, color: option.color }),
      fillDark: new Fill({ type: FillType.Color, color: option.color })
    });

    const control = this.context.controls.menu.controls.blocks.appendControl(block);

    control.applyDefaultState();
  }

  dragDrop(event: AppDragDrop<MenuBlockControl[]>) {
    moveItemInArray(this.context.controls.menu.controls.blocks.controls, event.previousIndex, event.currentIndex);
    this.context.controls.menu.controls.blocks.updateValueAndValidity();
  }

  back() {
    const link = this.currentProjectStore.instance.settingsLayoutLink('appearance');
    this.routing.navigateApp(link);
  }

  cancel() {
    this.customizeService.stopTrackChanges();
    this.context.resetSavedState();
    this.customizeService.startTrackChanges();
  }

  submit() {
    if (this.submitLoading) {
      return;
    }

    this.submitLoading = true;
    this.cd.markForCheck();

    this.context
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.customizeService.stopTrackChanges();
          this.context.saveCurrentState();
          this.customizeService.startTrackChanges();
          this.submitLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }
}
