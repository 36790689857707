import { getColorHexStr } from '@modules/colors';
import { isSet } from '@shared';

import { Frame } from './frame';
import { Gradient } from './gradient';

export enum FillType {
  Color = 'color',
  Gradient = 'gradient'
}

export class Fill {
  type: FillType = FillType.Color;
  color: string;
  gradient: Gradient;

  constructor(options: Partial<Fill> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data['type']) {
      this.type = data['type'];
    }

    if (data['color']) {
      this.color = data['color'];
    }

    if (data['gradient']) {
      this.gradient = new Gradient().deserialize(data['gradient']);
    }

    return this;
  }

  serialize(): Object {
    return {
      type: this.type,
      color: this.color,
      gradient: this.gradient ? this.gradient.serialize() : undefined
    };
  }

  isSet(): boolean {
    if (this.type == FillType.Color) {
      return isSet(this.color);
    } else if (this.type == FillType.Gradient) {
      return this.gradient && this.gradient.isSet();
    } else {
      return false;
    }
  }

  css(
    options: { frame?: Frame } = {}
  ): { background?: string; accentColor?: string; width?: string; height?: string; transform?: string } {
    options = {
      frame: new Frame({ width: 1, height: 1 }),
      ...options
    };

    if (this.type == FillType.Color && isSet(this.color)) {
      const color = getColorHexStr(this.color);
      return {
        background: color,
        accentColor: color
      };
    } else if (this.type == FillType.Gradient && this.gradient) {
      return this.gradient.css({ frame: options.frame });
    }

    return {};
  }
}
