<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner popover2__inner_sidebar">
      <div class="popover2__viewport popover2__viewport_scrollable" [style.max-height.vh]="80">
        <div [style.width.px]="300">
          <div class="sidebar__element sidebar__element_margin_s">
            <app-sidebar-field [label]="'color'">
              <ng-container actions-right>
                <div class="button-group" [style.margin]="'-5px 0'">
                  <div
                    class="button-group__item"
                    [class.button-group__item_active]="themeService.isDefaultTheme"
                    (click)="themeService.theme = 'default'"
                  >
                    <span class="button-group__item-icon icon-sun"></span>
                  </div>

                  <div
                    class="button-group__item"
                    [class.button-group__item_active]="themeService.isDarkTheme"
                    (click)="themeService.theme = 'dark'"
                  >
                    <span class="button-group__item-icon icon-moon"></span>
                  </div>
                </div>
              </ng-container>

              <app-auto-field
                *ngIf="themeService.isDefaultTheme"
                [field]="
                  createField({
                    name: 'color',
                    field: 'ColorField',
                    params: {
                      classes: ['select_fill'],
                      allow_empty: true,
                      empty_color: undefined,
                      custom_colors: true,
                      alpha_enabled: true
                    }
                  })
                "
                [form]="control"
                [label]="false"
              ></app-auto-field>

              <app-auto-field
                *ngIf="themeService.isDarkTheme"
                [field]="
                  createField({
                    name: 'color_dark',
                    field: 'ColorField',
                    params: {
                      classes: ['select_fill'],
                      allow_empty: true,
                      empty_color: undefined,
                      custom_colors: true,
                      alpha_enabled: true
                    }
                  })
                "
                [form]="control"
                [label]="false"
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element sidebar__element_margin_s">
            <div class="sidebar-cols sidebar-cols_margin_s">
              <div class="sidebar-cols__item">
                <app-sidebar-field [label]="'Offset X'">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-group__item input-group__item_first input-group__fill input input_small"
                      [formControl]="control.controls.offset_x"
                      [appInputFormat]="{ type: numberFieldTypes.Integer, numberKeyboardControl: true }"
                    />

                    <div
                      class="input-group__item input-group__item_last input input_small input_disabled"
                      [style.padding-left.px]="10"
                      [style.padding-right.px]="10"
                    >
                      px
                    </div>
                  </div>
                </app-sidebar-field>
              </div>

              <div class="sidebar-cols__item">
                <app-sidebar-field [label]="'Offset Y'">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-group__item input-group__item_first input-group__fill input input_small"
                      [formControl]="control.controls.offset_y"
                      [appInputFormat]="{ type: numberFieldTypes.Integer, numberKeyboardControl: true }"
                    />

                    <div
                      class="input-group__item input-group__item_last input input_small input_disabled"
                      [style.padding-left.px]="10"
                      [style.padding-right.px]="10"
                    >
                      px
                    </div>
                  </div>
                </app-sidebar-field>
              </div>
            </div>
          </div>

          <div class="sidebar__element sidebar__element_margin_s">
            <div class="sidebar-cols sidebar-cols_margin_s">
              <div class="sidebar-cols__item">
                <app-sidebar-field [label]="'Blur radius'">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-group__item input-group__item_first input-group__fill input input_small"
                      [formControl]="control.controls.blur_radius"
                      [appInputFormat]="{ type: numberFieldTypes.Integer, min: 0, numberKeyboardControl: true }"
                    />

                    <div
                      class="input-group__item input-group__item_last input input_small input_disabled"
                      [style.padding-left.px]="10"
                      [style.padding-right.px]="10"
                    >
                      px
                    </div>
                  </div>
                </app-sidebar-field>
              </div>

              <div class="sidebar-cols__item">
                <app-sidebar-field [label]="'Spread radius'">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-group__item input-group__item_first input-group__fill input input_small"
                      [formControl]="control.controls.spread_radius"
                      [appInputFormat]="{ type: numberFieldTypes.Integer, numberKeyboardControl: true }"
                    />

                    <div
                      class="input-group__item input-group__item_last input input_small input_disabled"
                      [style.padding-left.px]="10"
                      [style.padding-right.px]="10"
                    >
                      px
                    </div>
                  </div>
                </app-sidebar-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div
  class="sidebar-list-item sidebar-list-item_compact sidebar-list-item_clickable"
  [class.sidebar-list-item_contrast]="contrast"
  [class.sidebar-list-item_disabled]="disabled || dropdownOpened"
  (click)="setDropdownOpened(true)"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
>
  <div class="sidebar-list-item__left">
    <div
      class="sidebar-list-item__color sidebar-list-item__color_size_l sidebar-list-item__color_square"
      [style.background]="color"
    ></div>
  </div>

  <div class="sidebar-list-item__main">
    <ng-container *ngIf="valueStr | appIsSet">
      <div class="sidebar-list-item__title">{{ valueStr }}</div>
      <div class="sidebar-list-item__description">
        {{ subtitle }}
        <ng-container *ngIf="additional | appIsSet"> {{ additional }}</ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="!(valueStr | appIsSet)">
      <div class="sidebar-list-item__title sidebar-list-item__title_secondary">
        {{ subtitle }}
        <ng-container *ngIf="additional | appIsSet"> {{ additional }}</ng-container>
      </div>
    </ng-container>
  </div>

  <div *ngIf="removeEnabled" class="sidebar-list-item__right">
    <div
      class="sidebar-list-item__action sidebar-list-item__action_hover-only icon-bin"
      [appTip]="'Remove'"
      [appTipOptions]="{ side: 'top' }"
      (click)="$event.stopPropagation(); remove.emit()"
    ></div>
  </div>
</div>
