<a
  href="javascript:void(0)"
  class="button button_small"
  [class.button_primary]="!action.tint && style == tintStyles.Primary"
  [class.button_primary-transparent]="!action.tint && style == tintStyles.Transparent"
  [class.button_disabled]="disabled || submitLoading"
  [class.button_icon]="!(verboseName | appIsSet)"
  [class.button_theme]="theme"
  [class.button_styles]="theme"
  [appButtonTintColor]="action.tint || accentColor"
  [appButtonTintColorStyle]="style"
  [appActionElementStyles]="undefined"
  [appActionElementStylesTintColorStyle]="action.style"
  (click)="execute($event, button_element)"
  #button_element
>
  <app-loader-small
    *ngIf="submitLoading"
    class="button__icon"
    [class.button__icon_left]="verboseName | appIsSet"
  ></app-loader-small>
  <span
    *ngIf="action.icon && !submitLoading"
    class="icon button__icon"
    [class.button__icon_left]="verboseName | appIsSet"
    [appIcon]="action.icon"
  ></span>

  <span class="button__label">{{ verboseName || 'action' }}</span>
</a>

<span
  *ngIf="!isVisible && (customizeService.enabled$ | async)"
  class="element__indicator element__indicator_outside icon-eye"
  [appTip]="'Action is hidden'"
></span>
