import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { TextStyle } from '@modules/customize';
import { getFontFamilyVariable, weightOptions } from '@modules/styles';
import { globalFontOptions, ThemeService } from '@modules/theme';
import { firstSet, isSet } from '@shared';

import { stylesEditPopoverPositions } from '../styles-edit/styles-edit-popover-positions';
import { TextStyleControl } from '../text-style-edit/text-style.control';

@Component({
  selector: 'app-styles-edit-text-style',
  templateUrl: './styles-edit-text-style.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesEditTextStyleComponent implements OnInit, OnDestroy {
  @Input() control: TextStyleControl;
  @Input() subtitle = 'Text style';
  @Input() additional: string;
  @Input() contrast = false;
  @Input() disabled = false;
  @Input() added = false;
  @Input() removeEnabled = true;
  @Output() remove = new EventEmitter<void>();

  color: string;
  valueStr: string;
  popoverPositions = stylesEditPopoverPositions;

  constructor(private themeService: ThemeService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    const defaultStyle = new TextStyle();

    combineLatest(this.control.serialize$(), this.themeService.isDarkTheme$)
      .pipe(untilDestroyed(this))
      .subscribe(([textStyle, isDarkTheme]) => {
        const fontFamily = firstSet(
          textStyle ? textStyle.fontFamily : undefined,
          defaultStyle ? defaultStyle.fontFamily : undefined
        );
        const fontWeight = firstSet(
          textStyle ? textStyle.fontWeight : undefined,
          defaultStyle ? defaultStyle.fontWeight : undefined
        );
        const fontWeightName = weightOptions.filter(item => item.value == fontWeight).map(item => item.name)[0];
        const fontSize = firstSet(
          textStyle ? textStyle.fontSize : undefined,
          defaultStyle ? defaultStyle.fontSize : undefined
        );

        this.color = isDarkTheme
          ? firstSet(textStyle ? textStyle.colorDark : undefined, defaultStyle ? defaultStyle.colorDark : undefined)
          : firstSet(textStyle ? textStyle.color : undefined, defaultStyle ? defaultStyle.color : undefined);

        const valueStrParts: string[] = [];

        if (fontSize) {
          valueStrParts.push(`${fontSize}px`);
        }

        if (fontFamily) {
          const fontFamilyVariable = getFontFamilyVariable(fontFamily);
          const fontFamilyGlobal = isSet(fontFamilyVariable)
            ? globalFontOptions.find(item => item.value == `--${fontFamilyVariable}`)
            : undefined;
          const fontName = fontFamilyGlobal ? fontFamilyGlobal.name : fontFamily;

          valueStrParts.push(valueStrParts.length ? `- ${fontName}` : fontName);
        }

        if (fontWeightName) {
          valueStrParts.push(valueStrParts.length ? `(${fontWeightName})` : fontWeightName);
        }

        if (!textStyle) {
          this.valueStr = undefined;
        } else if (valueStrParts.length) {
          this.valueStr = valueStrParts.join(' ');
        } else {
          this.valueStr = undefined;
        }

        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}
}
