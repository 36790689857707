/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/components/loader-small/loader-small.component.ngfactory";
import * as i2 from "../../../../../shared/components/loader-small/loader-small.component";
import * as i3 from "../../../../icons/directives/icon/icon.directive";
import * as i4 from "../../../../../core/services/app-config/app-config.service";
import * as i5 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i6 from "../../../../icons/pipes/icon-type/icon-type.pipe";
import * as i7 from "../../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i8 from "../../../../colors-shared/directives/tint-color/tint-color.directive";
import * as i9 from "@angular/common";
import * as i10 from "./table-actions-item.component";
import * as i11 from "../../../../customize/services/customize/customize.service";
import * as i12 from "../../../../action-queries/services/action/action.service";
import * as i13 from "../../../../action-queries/services/action-controller/action-controller.service";
import * as i14 from "../../../../../common/notifications/services/notification/notification.service";
var styles_TableActionsItemComponent = [];
var RenderType_TableActionsItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TableActionsItemComponent, data: {} });
export { RenderType_TableActionsItemComponent as RenderType_TableActionsItemComponent };
function View_TableActionsItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader-small", [["class", "table-action__icon"]], null, null, null, i1.View_LoaderSmallComponent_0, i1.RenderType_LoaderSmallComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderSmallComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_TableActionsItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "table-action__icon"]], [[2, "table-action__icon_image", null], [4, "background-image", null]], null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i3.IconDirective, [i4.AppConfigService, i0.ElementRef], { icon: [0, "icon"] }, null), i0.ɵppd(2, 1), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.action.icon; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((i0.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.action.icon)) == _co.iconTypes.Emoji) || (i0.ɵunv(_v, 0, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.action.icon)) == _co.iconTypes.Image)); var currVal_1 = i0.ɵnov(_v, 1).backgroundImage; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TableActionsItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "table-action__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 1), _co.verboseName)); _ck(_v, 1, 0, currVal_0); }); }
function View_TableActionsItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "element__indicator element__indicator_outside icon-eye"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i5.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null)], function (_ck, _v) { var currVal_0 = "Action is hidden"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TableActionsItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i6.IconTypePipe, [i4.AppConfigService]), i0.ɵpid(0, i7.CapitalizePipe, []), (_l()(), i0.ɵeld(2, 0, [["button_element", 1]], null, 7, "div", [["class", "table-action"]], [[2, "table-action_icon", null], [2, "table-action_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.execute(i0.ɵnov(_v, 2)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 540672, null, 0, i8.TintColorDirective, [i0.ElementRef], { appTintColor: [0, "appTintColor"], appTintColorStyle: [1, "appTintColorStyle"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableActionsItemComponent_1)), i0.ɵdid(5, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableActionsItemComponent_2)), i0.ɵdid(7, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableActionsItemComponent_3)), i0.ɵdid(9, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TableActionsItemComponent_4)), i0.ɵdid(11, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i9.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.accentColor; var currVal_3 = _co.tintStyles.Primary; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co.submitLoading; _ck(_v, 5, 0, currVal_4); var currVal_5 = (_co.action.icon && !_co.submitLoading); _ck(_v, 7, 0, currVal_5); var currVal_6 = _co.verboseName; _ck(_v, 9, 0, currVal_6); var currVal_7 = (!_co.isVisible && i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform(_co.customizeService.enabled$))); _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.verboseName; var currVal_1 = (_co.disabled || _co.submitLoading); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_TableActionsItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-table-actions-item", [], [[2, "element__indicator-wrapper", null], [2, "hidden", null]], null, null, View_TableActionsItemComponent_0, RenderType_TableActionsItemComponent)), i0.ɵdid(1, 770048, null, 0, i10.TableActionsItemComponent, [i11.CustomizeService, i12.ActionService, i13.ActionControllerService, i14.NotificationService, i0.Injector, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 1).hidden; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var TableActionsItemComponentNgFactory = i0.ɵccf("app-table-actions-item", i10.TableActionsItemComponent, View_TableActionsItemComponent_Host_0, { action: "action", selectedItems: "selectedItems", context: "context", contextElement: "contextElement", accentColor: "accentColor" }, {}, []);
export { TableActionsItemComponentNgFactory as TableActionsItemComponentNgFactory };
