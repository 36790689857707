import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { forceObservable } from '@shared';
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/dialogs/services/dialog/dialog.service";
var UnsavedChangesGuard = /** @class */ (function () {
    function UnsavedChangesGuard(dialogService) {
        this.dialogService = dialogService;
    }
    UnsavedChangesGuard.prototype.canDeactivate = function (component, currentRoute, currentState, nextState) {
        var _this = this;
        if (!component.hasUnsavedChanges) {
            return true;
        }
        return forceObservable(component.hasUnsavedChanges(currentState, nextState)).pipe(switchMap(function (hasChanges) {
            if (hasChanges) {
                return _this.dialogService.warning({
                    title: 'Unsaved changes',
                    description: 'Are you sure want to leave this page? Your unsaved changes will be lost',
                    style: 'orange',
                    buttons: [
                        {
                            name: 'cancel',
                            label: 'Cancel',
                            type: DialogButtonType.Default,
                            hotkey: DialogButtonHotkey.Cancel
                        },
                        {
                            name: 'ok',
                            label: 'Discard changes',
                            type: DialogButtonType.Danger,
                            hotkey: DialogButtonHotkey.Submit
                        }
                    ]
                });
            }
            return of(true);
        }));
    };
    UnsavedChangesGuard.ngInjectableDef = i0.defineInjectable({ factory: function UnsavedChangesGuard_Factory() { return new UnsavedChangesGuard(i0.inject(i1.DialogService)); }, token: UnsavedChangesGuard, providedIn: "root" });
    return UnsavedChangesGuard;
}());
export { UnsavedChangesGuard };
