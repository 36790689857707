var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { asyncScheduler, BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged, map, throttleTime } from 'rxjs/operators';
import { CustomizeService, TextStyle, ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$, createFormFieldFactory, FieldType, FormField, getFieldDescriptionByType, getValidatorByType, LOADING_VALUE, ParameterField } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentProjectStore } from '@modules/projects';
import { controlValue } from '@shared';
import { CustomPagePopupComponent } from '../../custom-page-popup/custom-page-popup.component';
export var VALUE_OUTPUT = 'value';
var STANDALONE_FORM_CONTROL = 'field';
function getElementStateInfo(state) {
    return {
        name: state.name,
        field: state.formField ? state.formField.field : undefined
    };
}
function getElementStateOutputs(state) {
    return {
        field: state.formField ? state.formField.field : undefined,
        params: state.formField ? state.formField.params : undefined
    };
}
function getElementStateValue(state) {
    return {
        field: state.formField ? state.formField.field : undefined,
        valueInput: state.valueInput ? state.valueInput.serialize() : undefined
    };
}
function getElementStateDisabled(state) {
    return {
        disableInput: state.disableInput ? state.disableInput.serialize() : undefined
    };
}
function getElementStateValidators(state) {
    return state.formField
        ? {
            required: state.formField.required,
            validatorType: state.formField.validatorType,
            validatorParams: state.formField.validatorParams
        }
        : {};
}
var FilterElementItemComponent = /** @class */ (function () {
    function FilterElementItemComponent(customizeService, modelDescriptionStore, currentProjectStore, viewContextElement, cd, popup) {
        var _a;
        this.customizeService = customizeService;
        this.modelDescriptionStore = modelDescriptionStore;
        this.currentProjectStore = currentProjectStore;
        this.viewContextElement = viewContextElement;
        this.cd = cd;
        this.popup = popup;
        this.field$ = new BehaviorSubject(undefined);
        this.state = {};
        this.createField = createFormFieldFactory();
        this.control = new FormControl();
        this.form = new FormGroup((_a = {}, _a[STANDALONE_FORM_CONTROL] = this.control, _a));
        this.baseValidator = [];
        this.loadingValue = false;
        this.fieldTypes = FieldType;
        this.booleanValueEquals = function (lhs, rhs) { return lhs === rhs; };
    }
    FilterElementItemComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.initContext();
        this.fieldOnChange(this.field);
        this.trackChanges();
    };
    FilterElementItemComponent.prototype.ngOnDestroy = function () { };
    FilterElementItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.field) {
            this.fieldOnChange(this.field);
        }
    };
    FilterElementItemComponent.prototype.fieldOnChange = function (value) {
        this.field$.next(value);
    };
    FilterElementItemComponent.prototype.trackChanges = function () {
        var _this = this;
        this.field$
            .pipe(map(function (field) { return _this.getElementState(field); }), untilDestroyed(this))
            .subscribe(function (state) {
            _this.onStateUpdated(state);
            _this.state = state;
        });
    };
    FilterElementItemComponent.prototype.getElementState = function (field) {
        var formField = new FormField().deserialize({
            name: STANDALONE_FORM_CONTROL,
            label: this.field.verboseName,
            field: this.field.field,
            required: false,
            reset_enabled: true,
            editable: true,
            placeholder: this.field.placeholder,
            validator_type: this.field.validatorType,
            validator_params: this.field.validatorParams,
            params: __assign({}, this.field.params, { classes: ['input_fill', 'select_fill'], theme: true }),
            description: this.field.description
        });
        return {
            field: field,
            valueInput: field && field.valueInput,
            name: field.verboseName,
            formField: formField
        };
    };
    FilterElementItemComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getElementStateInfo(state), getElementStateInfo(this.state))) {
            this.updateContextInfo(state);
        }
        if (!isEqual(getElementStateOutputs(state), getElementStateOutputs(this.state))) {
            this.updateContextOutputs(state);
            this.updateContextActions(state);
        }
        if (!isEqual(getElementStateValue(state), getElementStateValue(this.state))) {
            this.updateDefaultValue(state);
            this.updateValueOutput();
        }
        if (!isEqual(getElementStateDisabled(state), getElementStateDisabled(this.state))) {
            this.updateDisabled(state);
        }
        if (!isEqual(getElementStateValidators(state), getElementStateValidators(this.state))) {
            this.updateValidators(state);
        }
    };
    FilterElementItemComponent.prototype.updateDefaultValue = function (state) {
        var _this = this;
        if (this.defaultValueSubscription) {
            this.defaultValueSubscription.unsubscribe();
            this.defaultValueSubscription = undefined;
        }
        var value$;
        var fieldTypeDefault = null;
        if (state.valueInput) {
            value$ = applyParamInput$(state.valueInput, {
                context: this.context,
                defaultValue: fieldTypeDefault,
                handleLoading: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }));
        }
        else {
            value$ = of(fieldTypeDefault);
        }
        this.defaultValueSubscription = value$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.setControlDefault(value);
        });
    };
    FilterElementItemComponent.prototype.setControlDefault = function (value) {
        if (value === LOADING_VALUE) {
            this.loadingValue = true;
            this.cd.markForCheck();
        }
        else {
            this.loadingValue = false;
            this.cd.markForCheck();
            this.control.patchValue(value);
            this.control.markAsPristine();
        }
    };
    FilterElementItemComponent.prototype.updateDisabled = function (state) {
        var _this = this;
        if (this.disabledSubscription) {
            this.disabledSubscription.unsubscribe();
            this.disabledSubscription = undefined;
        }
        var value$;
        if (state.disableInput) {
            value$ = applyParamInput$(state.disableInput, {
                context: this.context,
                defaultValue: false,
                handleLoading: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }));
        }
        else {
            value$ = of(false);
        }
        this.disabledSubscription = value$.pipe(untilDestroyed(this)).subscribe(function (disabled) {
            _this.setControlDisabled(disabled);
        });
    };
    FilterElementItemComponent.prototype.setControlDisabled = function (disabled) {
        if (disabled === LOADING_VALUE) {
            disabled = true;
        }
        if (disabled && !this.control.disabled) {
            this.control.disable();
        }
        else if (!disabled && this.control.disabled) {
            this.control.enable();
        }
        this.cd.markForCheck();
    };
    FilterElementItemComponent.prototype.updateValidators = function (state) {
        if (this.updateValidatorSubscription) {
            this.updateValidatorSubscription.unsubscribe();
            this.updateValidatorSubscription = undefined;
        }
        var validators = this.baseValidator.slice();
        if (state.formField) {
            if (state.formField.required) {
                validators.push(Validators.required);
            }
            var validator = getValidatorByType(state.formField.validatorType, state.formField.validatorParams, {
                context: this.context,
                contextElement: this.viewContextElement
            });
            if (validator.func) {
                validators.push(validator.func);
            }
            if (validator.contextDependent && this.context) {
                var control_1 = this.control;
                this.updateValidatorSubscription = this.context.outputValues$
                    .pipe(throttleTime(10, asyncScheduler, { leading: true, trailing: true }))
                    .pipe(untilDestroyed(this))
                    .subscribe(function () { return control_1.updateValueAndValidity(); });
            }
        }
        this.control.setValidators(validators);
        this.control.updateValueAndValidity();
    };
    FilterElementItemComponent.prototype.updateValueOutput = function () {
        var _this = this;
        if (this.updateOutputSubscription) {
            this.updateOutputSubscription.unsubscribe();
            this.updateOutputSubscription = undefined;
        }
        var control = this.control;
        this.updateOutputSubscription = controlValue(control)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            var error = !!control.errors;
            _this.viewContextElement.setOutputValue(VALUE_OUTPUT, value, { error: error });
        });
    };
    FilterElementItemComponent.prototype.initContext = function () {
        var fieldDescription = getFieldDescriptionByType(this.field.field);
        this.viewContextElement.initElement({
            uniqueName: this.name,
            name: this.field.verboseName,
            icon: fieldDescription.icon,
            allowSkip: true
        }, this.parent);
    };
    FilterElementItemComponent.prototype.updateContextInfo = function (state) {
        var fieldDescription = getFieldDescriptionByType(state.formField.field);
        this.viewContextElement.initInfo({
            name: state.name,
            icon: fieldDescription.icon,
            getFieldValue: function (field, outputs) {
                return outputs[VALUE_OUTPUT];
            }
        }, true);
    };
    FilterElementItemComponent.prototype.updateContextOutputs = function (state) {
        var fieldDescription = getFieldDescriptionByType(state.formField.field);
        this.viewContextElement.setOutputs([
            {
                uniqueName: VALUE_OUTPUT,
                name: 'Value',
                icon: fieldDescription.icon,
                fieldType: state.formField.field,
                fieldParams: state.formField.params,
                external: true
            }
        ]);
    };
    FilterElementItemComponent.prototype.updateContextActions = function (state) {
        var _this = this;
        var valueParameter = new ParameterField();
        valueParameter.name = 'value';
        valueParameter.field = state.formField.field;
        valueParameter.params = __assign({}, state.formField.params);
        this.viewContextElement.setActions([
            {
                uniqueName: 'set_value',
                name: 'Set Value',
                icon: 'edit',
                parameters: [valueParameter],
                handler: function (params) {
                    _this.control.setValue(params['value']);
                }
            },
            {
                uniqueName: 'clear_value',
                name: 'Clear Value',
                icon: 'delete',
                parameters: [],
                handler: function () {
                    var fieldDescription = getFieldDescriptionByType(state.formField.field);
                    _this.control.setValue(fieldDescription.defaultValue);
                }
            }
        ]);
    };
    return FilterElementItemComponent;
}());
export { FilterElementItemComponent };
