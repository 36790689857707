var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge } from 'rxjs';
import { IgnoreScrollTop } from '@common/route-scroll';
import { CustomizeService, WithBuilderMode } from '@modules/customize';
import { ChangeComponent } from '../change/change.component';
var ɵ0 = function (newSnapshot, prevSnapshot) {
    var model = newSnapshot.params['model'];
    var prevModel = prevSnapshot ? prevSnapshot.params['model'] : undefined;
    var id = newSnapshot.params['id'];
    var prevId = prevSnapshot ? prevSnapshot.params['id'] : undefined;
    return model == prevModel && id == prevId;
};
var ChangeRouteComponent = /** @class */ (function () {
    function ChangeRouteComponent(customizeService, activatedRoute, cd) {
        this.customizeService = customizeService;
        this.activatedRoute = activatedRoute;
        this.cd = cd;
        this.params = {};
    }
    ChangeRouteComponent.prototype.ngOnInit = function () {
        var _this = this;
        merge(this.activatedRoute.params, this.activatedRoute.queryParams)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var snapshot = _this.activatedRoute.snapshot;
            _this.modelId = snapshot.params['model'];
            _this.id = snapshot.params['id'];
            _this.params = snapshot.queryParams;
            _this.cd.markForCheck();
        });
    };
    ChangeRouteComponent.prototype.ngOnDestroy = function () { };
    ChangeRouteComponent.prototype.onBeforeDestroy = function () {
        if (this.changeComponent) {
            this.changeComponent.onBeforeDestroy();
        }
    };
    ChangeRouteComponent.prototype.hasUnsavedChanges = function () {
        return this.changeComponent.hasChanges();
    };
    ChangeRouteComponent.prototype.getCollaborationParams = function () {
        var snapshot = this.activatedRoute.snapshot;
        return {
            object_type: ['model', snapshot.params['model']].join('.'),
            object_id: snapshot.params['id']
        };
    };
    ChangeRouteComponent.prototype.getUserActivitiesParams = function () {
        var snapshot = this.activatedRoute.snapshot;
        return {
            object_type: ['model', snapshot.params['model']].join('.'),
            object_id: snapshot.params['id']
        };
    };
    ChangeRouteComponent = __decorate([
        WithBuilderMode,
        IgnoreScrollTop({
            predicate: ɵ0
        }),
        __metadata("design:paramtypes", [CustomizeService,
            ActivatedRoute,
            ChangeDetectorRef])
    ], ChangeRouteComponent);
    return ChangeRouteComponent;
}());
export { ChangeRouteComponent };
export { ɵ0 };
