<div class="sidebar__element sidebar__element_margin_s">
  <div class="sidebar-cols sidebar-cols_align_center sidebar-cols_margin_s">
    <div class="sidebar-cols__item">
      <div class="slider" [class.slider_active]="false" [class.slider_thumb_inside]="true">
        <div class="slider__track-wrapper">
          <div
            class="slider__track"
            [style.background]="trackBackgroundSafe"
            (click)="createGradientStop($event)"
            #gradient_stop_track
          >
            <div class="slider__track-fill-start" [style.background]="'transparent'"></div>
            <div class="slider__track-fill" [style.background]="'transparent'">
              <div
                *ngFor="let stopControl of control.controls.stops.controls; trackBy: trackStopControlFn"
                appGradientSelectorStopPosition
                class="slider__position slider__value-origin"
                [class.slider__position_active]="(activeStopId | appIsSet) && activeStopId == stopControl.getId()"
                [style.left.%]="stopControl.controls.position.value * 100"
                [gradientStopBounds]="gradient_stop_track"
                (click)="setCurrentStopControl(stopControl); $event.stopPropagation()"
                (gradientStopMouseDown)="setCurrentStopControl(stopControl)"
                (gradientStopSetPosition)="stopControl.controls.position.patchValue($event)"
                #gradient_stop_position="appGradientSelectorStopPosition"
              >
                <div
                  class="slider__value"
                  [class.slider__value_active]="gradient_stop_position.dragging$ | async"
                  [class.slider__value_position_top]="true"
                  [class.slider__value_position_bottom]="false"
                >
                  {{ stopControl.controls.position.value | appFormatNumber: '0%' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar-cols__item sidebar-cols__item_fit">
      <div
        class="button button_extra-small button_icon button_no-margin"
        [appTip]="'Invert gradient'"
        [appTipOptions]="{ side: 'top' }"
        (click)="control.invertStops()"
      >
        <span class="button__label icon-reflect"></span>
      </div>
    </div>

    <div class="sidebar-cols__item sidebar-cols__item_fit">
      <div
        class="button button_extra-small button_icon button_no-margin"
        [appTip]="'Rotate gradient'"
        [appTipOptions]="{ side: 'top' }"
        (click)="control.rotateStops()"
      >
        <span class="button__label icon-repeat"></span>
      </div>
    </div>
  </div>
</div>

<ng-container *ngFor="let stopControl of control.controls.stops.controls">
  <app-color-selector
    *ngIf="(activeStopId | appIsSet) && stopControl.getId() == activeStopId"
    [current]="stopControl.controls.color.value"
    [colors]="[]"
    [customColors]="true"
    [alphaEnabled]="true"
    (selected)="stopControl.controls.color.patchValue($event)"
  ></app-color-selector>
</ng-container>
