import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { auditTime, map, switchMap, throttleTime } from 'rxjs/operators';

import { CustomizeGradient, FillEditContext } from '@modules/colors-components';
import {
  ActionElementStyles,
  Corners,
  ElementWrapperStyles,
  FieldElementStyles,
  Frame,
  Margin
} from '@modules/customize';
import {
  MenuBlock,
  MenuBlockLayout,
  MenuBlockLayouts,
  MenuGeneratorService,
  MenuItem,
  MenuItemType
} from '@modules/menu';
import { MenuContext, MenuPrimary, MenuSecondary } from '@modules/menu-components';
import { ThemeService } from '@modules/theme';
import { ThemeContext } from '@modules/theme-components';
import { CurrentUserStore } from '@modules/users';
import { controlValue, isSet, TypedChanges } from '@shared';

import { MenuBlockControl } from '../project-settings/menu-block.control';
import { MenuUpdateForm } from '../project-settings/menu-update.form';
import { ProjectAppearanceForm } from '../project-settings/project-appearance.form';

interface MenuBlockItem {
  block: MenuBlock;
  control: MenuBlockControl;
  firstMenuItem: MenuItem;
  primary: boolean;
  isLight: boolean;
  color: string;
  backgroundColor: string;
  background: SafeStyle;
  backgroundWidth?: string;
  backgroundHeight?: string;
  backgroundTransform?: SafeStyle;
  borderTop?: SafeStyle;
  borderRight?: SafeStyle;
  borderBottom?: SafeStyle;
  borderLeft?: SafeStyle;
  borderRadius?: Corners;
  style: SafeStyle;
}

export enum AdminPreviewType {
  Default = 'default',
  Buttons = 'buttons',
  Fields = 'fields'
}

@Component({
  selector: 'app-admin-template, [app-admin-template]',
  templateUrl: './admin-template.component.html',
  providers: [ThemeContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminTemplateComponent implements OnInit, OnDestroy, OnChanges {
  @Input() menuForm: MenuUpdateForm;
  @Input() appearanceForm: ProjectAppearanceForm;
  @Input() blockControlHover: MenuBlockControl;
  @Input() blockControlActive: MenuBlockControl;
  @Input() contentDisabled = false;
  @Input() container: HTMLElement;
  @Input() preview = AdminPreviewType.Default;
  @Output() blockControlPreviewHover = new EventEmitter<MenuBlockControl>();
  @Output() blockControlPreviewClick = new EventEmitter<{ control: MenuBlockControl; event: MouseEvent }>();

  leftMenuBlocks: MenuBlockItem[] = [];
  topMenuBlocks: MenuBlockItem[] = [];
  topContentMenuBlocks: MenuBlockItem[] = [];
  customizeGradient: CustomizeGradient;
  externalFonts: string[] = [];
  actionElementStylesPrimary: ActionElementStyles;
  actionElementStylesDefault: ActionElementStyles;
  fieldElementStyles: FieldElementStyles;
  elementWrapperStyles: ElementWrapperStyles;
  blockControlHover$ = new BehaviorSubject<MenuBlockControl>(undefined);
  blockControlActive$ = new BehaviorSubject<MenuBlockControl>(undefined);
  blockControlPreviewHover$ = new BehaviorSubject<MenuBlockControl>(undefined);
  blockControlFocus: MenuBlockControl;
  previewTypes = AdminPreviewType;

  trackMenuBlockItemFn(i, item: MenuBlockItem) {
    return item.control.instance.uid;
  }

  trackMenuItemFn(i, item: MenuItem) {
    return item.id;
  }

  constructor(
    private context: MenuContext,
    @Optional() private fillEditContext: FillEditContext,
    public currentUserStore: CurrentUserStore,
    private menuGeneratorService: MenuGeneratorService,
    public themeService: ThemeService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.appearanceForm.valueChanges
      .pipe(throttleTime(200), untilDestroyed(this))
      .subscribe(() => this.cd.markForCheck());

    controlValue(this.menuForm.controls.blocks, {
      debounce: () => {
        return this.fillEditContext && this.fillEditContext.customizingGradient$.value ? 0 : 200;
      }
    })
      .pipe(
        switchMap(() => {
          return combineLatest(
            this.menuForm.controls.blocks.controls.map(control => {
              return control
                .getEnabled$({ context: this.context })
                .pipe(map(enabled => ({ control: control, enabled: enabled })));
            })
          ).pipe(map(items => items.filter(item => item.enabled).map(item => item.control)));
        }),
        switchMap(menuBlockControls => {
          const backgroundColor$ = combineLatest(
            this.themeService.isDarkTheme$,
            controlValue<string>(this.appearanceForm.controls.background_color),
            controlValue<string>(this.appearanceForm.controls.background_color_dark)
          ).pipe(
            map(([isDarkTheme, backgroundColor, backgroundColorDark]) => {
              return isDarkTheme ? backgroundColorDark : backgroundColor;
            })
          );

          return combineLatest(of(menuBlockControls), this.themeService.isDarkTheme$, backgroundColor$);
        }),
        untilDestroyed(this)
      )
      .subscribe(([menuBlockControls, isDarkTheme, backgroundColor]) => {
        const menuBlocks = menuBlockControls.map<MenuBlockItem>(control => {
          const block = control.serialize();
          const fill = isDarkTheme
            ? control.controls.fill_settings.controls.fill_dark
                .getInstance()
                .css({ frame: new Frame({ width: 320, height: 240 }) })
            : control.controls.fill_settings.controls.fill
                .getInstance()
                .css({ frame: new Frame({ width: 320, height: 240 }) });
          let borderTop: SafeStyle;
          let borderRight: SafeStyle;
          let borderBottom: SafeStyle;
          let borderLeft: SafeStyle;

          if (block.borderSettings && block.borderSettings.isSidesSet()) {
            borderTop =
              block.borderSettings && block.borderSettings.borderTop
                ? this.sanitizer.bypassSecurityTrustStyle(block.borderSettings.borderTop.cssBorder(isDarkTheme))
                : undefined;
            borderRight =
              block.borderSettings && block.borderSettings.borderRight
                ? this.sanitizer.bypassSecurityTrustStyle(block.borderSettings.borderRight.cssBorder(isDarkTheme))
                : undefined;
            borderBottom =
              block.borderSettings && block.borderSettings.borderBottom
                ? this.sanitizer.bypassSecurityTrustStyle(block.borderSettings.borderBottom.cssBorder(isDarkTheme))
                : undefined;
            borderLeft =
              block.borderSettings && block.borderSettings.borderLeft
                ? this.sanitizer.bypassSecurityTrustStyle(block.borderSettings.borderLeft.cssBorder(isDarkTheme))
                : undefined;
          } else {
            borderTop = borderRight = borderBottom = borderLeft =
              block.borderSettings && block.borderSettings.border
                ? this.sanitizer.bypassSecurityTrustStyle(block.borderSettings.border.cssBorder(isDarkTheme))
                : undefined;
          }

          const width = control.controls.width.value;
          const height = control.controls.height.value;
          const firstMenuItem = block.getAllItems().filter(item => [MenuItemType.Simple].includes(item.type))[0];
          const isLight = MenuPrimary.isLight(fill.accentColor || backgroundColor);
          const horizontal = MenuBlockLayouts.isHorizontal(control.controls.layout.value);
          const primary = MenuBlockLayouts.isPrimary(control.controls.layout.value);
          const style = this.getMenuStyle({
            horizontal: horizontal,
            primary: primary,
            accentColor: fill.accentColor,
            backgroundColor: backgroundColor,
            background: fill.background,
            width: width,
            height: height,
            ...(control.controls.border_radius.isSet() && {
              borderRadius: control.controls.border_radius.value
            }),
            ...(control.controls.padding.isSet() && {
              padding: control.controls.padding.value
            })
          });

          block.startItems = this.menuGeneratorService.cleanMenuItemsAppMode(block.startItems);
          block.centerItems = this.menuGeneratorService.cleanMenuItemsAppMode(block.centerItems);
          block.endItems = this.menuGeneratorService.cleanMenuItemsAppMode(block.endItems);

          return {
            block: block,
            control: control,
            firstMenuItem: firstMenuItem,
            primary: primary,
            isLight: isLight,
            color: fill.accentColor,
            backgroundColor: backgroundColor,
            background: this.sanitizer.bypassSecurityTrustStyle(fill.background),
            backgroundWidth: fill.width,
            backgroundHeight: fill.height,
            backgroundTransform: this.sanitizer.bypassSecurityTrustStyle(fill.transform),
            borderTop: borderTop,
            borderRight: borderRight,
            borderBottom: borderBottom,
            borderLeft: borderLeft,
            borderRadius: control.controls.border_radius.serialize(),
            style: style
          };
        });

        this.leftMenuBlocks = menuBlocks.filter(item => MenuBlockLayouts.isLeft(item.block.layout));
        this.topMenuBlocks = menuBlocks.filter(item => item.block.layout == MenuBlockLayout.TopThin);
        this.topContentMenuBlocks = menuBlocks.filter(item => item.block.layout == MenuBlockLayout.TopContentThin);
        this.cd.markForCheck();
      });

    if (this.fillEditContext) {
      this.fillEditContext.customizingGradient$.pipe(untilDestroyed(this)).subscribe(value => {
        this.customizeGradient = value;
        this.cd.markForCheck();
      });
    }

    combineLatest(
      controlValue<string>(this.appearanceForm.controls.font_regular),
      controlValue<string>(this.appearanceForm.controls.font_heading)
    )
      .pipe(untilDestroyed(this))
      .subscribe(fonts => {
        this.externalFonts = fonts.filter(item => isSet(item));
        this.cd.markForCheck();
      });

    combineLatest(
      this.appearanceForm.controls.action_element_styles_primary.serialize$(),
      this.appearanceForm.controls.action_element_styles_primary.stylesDefaultUpdated$()
    )
      .pipe(untilDestroyed(this))
      .subscribe(([elementStyles]) => {
        this.actionElementStylesPrimary = elementStyles;
        this.cd.markForCheck();
      });

    combineLatest(
      this.appearanceForm.controls.action_element_styles_default.serialize$(),
      this.appearanceForm.controls.action_element_styles_default.stylesDefaultUpdated$()
    )
      .pipe(untilDestroyed(this))
      .subscribe(([elementStyles]) => {
        this.actionElementStylesDefault = elementStyles;
        this.cd.markForCheck();
      });

    combineLatest(
      this.appearanceForm.controls.field_element_styles.serialize$(),
      this.appearanceForm.controls.field_element_styles.stylesDefaultUpdated$()
    )
      .pipe(untilDestroyed(this))
      .subscribe(([elementStyles]) => {
        this.fieldElementStyles = elementStyles;
        this.cd.markForCheck();
      });

    this.appearanceForm.controls.element_wrapper_styles
      .serialize$()
      .pipe(untilDestroyed(this))
      .subscribe(elementStyles => {
        this.elementWrapperStyles = elementStyles;
        this.cd.markForCheck();
      });

    const customizingGradient$ = this.fillEditContext ? this.fillEditContext.customizingGradient$ : of(undefined);

    combineLatest(
      this.blockControlHover$,
      this.blockControlActive$,
      this.blockControlPreviewHover$,
      customizingGradient$
    )
      .pipe(untilDestroyed(this))
      .subscribe(([blockControlHover, blockControlActive, blockControlPreviewHover, customizingGradient]) => {
        if (customizingGradient && customizingGradient.source instanceof MenuBlockControl) {
          this.blockControlFocus = undefined;
        } else if (blockControlPreviewHover) {
          this.blockControlFocus = blockControlPreviewHover;
        } else if (blockControlHover && blockControlHover.isVisible()) {
          this.blockControlFocus = blockControlHover;
        } else if (blockControlActive && blockControlActive.isVisible()) {
          this.blockControlFocus = blockControlActive;
        } else {
          this.blockControlFocus = undefined;
        }

        this.cd.markForCheck();
      });

    this.blockControlPreviewHover$
      .pipe(untilDestroyed(this))
      .subscribe(value => this.blockControlPreviewHover.emit(value));
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<AdminTemplateComponent>): void {
    if (changes.blockControlHover) {
      this.blockControlHover$.next(this.blockControlHover);
    }

    if (changes.blockControlActive) {
      this.blockControlActive$.next(this.blockControlActive);
    }
  }

  getMenuStyle(
    options: {
      horizontal?: boolean;
      primary?: boolean;
      accentColor?: string;
      backgroundColor?: string;
      background?: string;
      width?: number;
      height?: number;
      borderRadius?: number;
      padding?: Margin;
    } = {}
  ): SafeStyle {
    const vars = options.primary
      ? MenuPrimary.getVars(options.accentColor, options.backgroundColor)
      : MenuSecondary.getVars(options.accentColor, options.backgroundColor);

    const styles = toPairs(vars).map(([k, v]) => [`--${k}`, `${v}`]);

    if (!options.horizontal && (options.width || options.padding)) {
      const defaultMenuWidth = options.primary ? MenuPrimary.defaultWidth() : MenuSecondary.defaultWidth();
      const width =
        (options.width || defaultMenuWidth) +
        ((options.padding && options.padding.left) || 0) +
        ((options.padding && options.padding.right) || 0);
      styles.push(['width', `${width}px`]);
    }

    if (options.horizontal && (options.height || options.padding)) {
      const defaultMenuHeight = options.primary ? MenuPrimary.defaultHeight() : 0;
      const height =
        (options.height || defaultMenuHeight) +
        ((options.padding && options.padding.top) || 0) +
        ((options.padding && options.padding.bottom) || 0);
      styles.push(['height', `${height}px`]);
    }

    if (options.borderRadius) {
      styles.push(['border-top-left-radius', `${options.borderRadius}px`]);
    }

    if (options.borderRadius) {
      styles.push(['border-top-right-radius', `${options.borderRadius}px`]);
    }

    if (options.borderRadius) {
      styles.push(['border-bottom-right-radius', `${options.borderRadius}px`]);
    }

    if (options.borderRadius) {
      styles.push(['border-bottom-left-radius', `${options.borderRadius}px`]);
    }

    if (options.padding && options.padding.top) {
      styles.push(['padding-top', `${options.padding.top}px`]);
    }

    if (options.padding && options.padding.right) {
      styles.push(['padding-right', `${options.padding.right}px`]);
    }

    if (options.padding && options.padding.bottom) {
      styles.push(['padding-bottom', `${options.padding.bottom}px`]);
    }

    if (options.padding && options.padding.left) {
      styles.push(['padding-left', `${options.padding.left}px`]);
    }

    return this.sanitizer.bypassSecurityTrustStyle(styles.map(([k, v]) => `${k}: ${v}`).join(';'));
  }

  onBlockControlPreviewClick(block: MenuBlockItem, e: MouseEvent) {
    if (this.customizeGradient) {
      return;
    }

    this.blockControlPreviewClick.next({ control: block.control, event: e });
  }

  asMenuBlockItems(value: any): MenuBlockItem[] {
    return value as MenuBlockItem[];
  }
}
