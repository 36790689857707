<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [labelButtons]="buttons"
  [tooltip]="tooltip"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <ng-template
    let-actions="actions"
    let-stickyTop="stickyTop"
    let-stickyBottom="stickyBottom"
    let-select="select"
    #actions_template
  >
    <app-select-field-action-item
      *ngFor="let item of asActions(actions)"
      [item]="item"
      [context]="context"
      [contextElement]="contextElement"
      [stickyTop]="stickyTop"
      [stickyBottom]="stickyBottom"
      (execute)="asSelectComponent(select).close()"
    ></app-select-field-action-item>
  </ng-template>

  <app-select
    *ngIf="control"
    [placeholder]="field.placeholder || ('choose' | localize | appCapitalize)"
    [control]="control"
    [error]="(form | appFormFieldErrors: field.name)?.length > 0"
    [theme]="field.params['theme']"
    [styles]="field.params['theme']"
    [fill]="selectFill"
    [small]="selectSmall"
    [segment]="selectSegment"
    [appFieldElementStyles]="elementStyles"
    [appFieldElementStylesEnabled]="field.params['theme']"
    [source]="source?.initialized ? source : undefined"
    [resetEnabled]="!field['required'] && field['resetEnabled']"
    [searchEnabled]="true"
    [searchMinimumLength]="1"
    [searchDebounce]="200"
    [id]="field.name | appUniqueId: idToken"
    [classes]="field.params['classes']"
    #select
  >
    <ng-container dropdown-top>
      <ng-container
        *ngTemplateOutlet="actions_template; context: { actions: topActions, select: select }"
      ></ng-container>
    </ng-container>

    <ng-container dropdown-bottom>
      <ng-container
        *ngTemplateOutlet="actions_template; context: { actions: bottomActions, stickyBottom: true, select: select }"
      ></ng-container>
    </ng-container>
  </app-select>
</app-field-wrapper>

<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [labelButtons]="buttons"
  [tooltip]="tooltip"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <app-auto-field
    *ngIf="relatedFormField"
    [field]="relatedFormField"
    [value]="relatedValue"
    [readonly]="true"
    [label]="false"
  >
  </app-auto-field>
  <ng-container *ngIf="!relatedFormField">
    <span *ngIf="valueStrLoading" class="loading-animation"><span class="stub-text">Record Name</span></span>
    <ng-container *ngIf="!valueStrLoading">
      <ng-container *ngIf="valueStr != undefined">{{ valueStr | appSafeAsync }}</ng-container>
      <ng-container *ngIf="valueStr == undefined">{{ formatEmpty(undefined) }}</ng-container>
    </ng-container>
  </ng-container>
</app-field-wrapper>
