<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.name.value || (nameEditable ? 'gallery' : 'Gallery')"
    [titleEnabled]="nameEnabled"
    [titleEditable]="nameEditable"
    [titleCleanValue]="titleCleanValue"
    [backEnabled]="backEnabled"
    [backLabel]="'All components'"
    [subtitle]="'List'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (titleChanged)="onTitleChanged($event)"
    (deleteClick)="delete()"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'" [error]="(dataValid$ | async) !== false ? undefined : 'Not configured'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-model-description-data-source-button
              [control]="form.controls.data_source"
              [sortingFieldControl]="form.controls.sorting_field"
              [sortingAscControl]="form.controls.sorting_asc"
              [context]="context"
              [contextElement]="contextElement"
              [error]="(dataValid$ | async) !== false ? undefined : 'Not configured'"
              [classes]="'component-data-button'"
              (editClick)="editDataSource()"
              (addInputClick)="editDataSource({ addInput: true })"
              (editSortingClick)="editDataSource({ editSorting: true })"
            >
            </app-model-description-data-source-button>
          </div>

          <div *ngIf="queryConfigured$ | async" class="sidebar__element">
            <app-custom-view-item-edit
              [control]="form.controls.card_view"
              [mappingsControl]="form.controls.card_view_mappings"
              [sourceParameters]="columnParameters$ | async"
              [stateSelectedEnabled]="true"
              [subtitle]="'Card component'"
              [deleteEnabled]="true"
              [componentLabel]="'card'"
              [initialTemplatesFilter]="{ type: customViewTemplateTypes.ListItem }"
              [loading]="form.submitLoading$ | async"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPath]="itemContextElementPath"
              [analyticsSource]="element.analyticsGenericName"
            ></app-custom-view-item-edit>
          </div>
        </app-sidebar-section>

        <app-sidebar-section *ngIf="queryConfigured$ | async" [title]="'Card fields'">
          <ng-container header-left>
            <div
              class="sidebar-section__title-icon sidebar-section__title-icon_clickable sidebar-section__title-action icon-search"
              [class.sidebar-section__title-icon_active]="columnsSearch"
              (mousedown)="toggleSearch()"
              [appTip]="'Search'"
              [appTipOptions]="{ side: 'top' }"
            ></div>
          </ng-container>

          <ng-container header-right>
            <div class="sidebar-section__header-links">
              <a class="sidebar__link" href="javascript:void(0)" (click)="resetColumns()">
                Reset
              </a>

              <ng-container *ngIf="fieldsComponent.hasMultipleItems() && !form.controls.card_view.value">
                <span class="sidebar-section__header-separator"></span>
                <a class="sidebar__link" href="javascript:void(0)" (click)="fieldsComponent.toggleAll()">
                  <span *ngIf="fieldsComponent.isToggledAll()" class="button__label">Deselect All</span>
                  <span *ngIf="!fieldsComponent.isToggledAll()" class="button__label">Select All</span>
                </a>
              </ng-container>
            </div>
          </ng-container>

          <div class="sidebar__list">
            <app-display-fields-edit
              [form]="form.controls.data_source.controls.columns"
              [fieldActionsControl]="form.controls.columns_actions"
              [resource]="resource"
              [modelDescription]="modelDescription"
              [componentName]="'card'"
              [element]="element"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPath]="itemContextElementPath"
              [searchEnabled]="columnsSearch"
              [searchFocus]="columnsSearch"
              [visibleEditable]="!form.controls.card_view.value"
              [customViewEnabled]="!form.controls.card_view.value"
              [firstInit]="firstInit"
              [analyticsSource]="element.analyticsGenericName"
              (searchCleared)="setColumnsSearch(false)"
              #fieldsComponent
            >
            </app-display-fields-edit>
          </div>
        </app-sidebar-section>

        <app-related-model-components
          *ngIf="modelDescription"
          [modelDescription]="modelDescription"
          [context]="context"
          [contextElement]="contextElement"
          [contextElementModelOutput]="selectedItemContextElementPath"
          [collapseContext]="collapseContext"
        ></app-related-model-components>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-customize-bar-input-edit
              [label]="'gallery title'"
              [required]="true"
              [itemForm]="form.controls.title"
              [textStyleControl]="form.controls.element_styles.controls.title_style"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPath]="contextElementPath"
              [contextElementPaths]="contextElementPaths"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_title'"
            ></app-customize-bar-input-edit>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'cards per row'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'cards_per_row',
                    field: 'SelectField',
                    params: {
                      options: form.cardsPerRowOptions,
                      classes: [
                        'select_fill',
                        form.value['cards_per_row'] == cardsPerRow.Custom ? 'select_segment-top' : ''
                      ]
                    }
                  })
                "
              ></app-auto-field>

              <app-auto-field
                *ngIf="form.value['cards_per_row'] == cardsPerRow.Custom"
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'cards_per_row_custom',
                    field: 'IntegerField',
                    placeholder: 'Default is Auto',
                    params: { classes: ['input_fill', 'input_segment-bottom'] }
                  })
                "
              ></app-auto-field>

              <ng-container *ngIf="form.value['cards_per_row'] == cardsPerRow.Custom" description>
                Can be less if it doesn't fit the screen
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'cards per page'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'per_page',
                    field: 'IntegerField',
                    placeholder: 'Default is 10',
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <ng-container *ngIf="dataConfigured$ | async">
            <div class="sidebar__element">
              <app-sidebar-field [label]="'group by field'">
                <app-select
                  [control]="form.controls.group_field"
                  [options]="columnOptions$ | async"
                  [fill]="true"
                  [resetEnabled]="true"
                  (valueChange)="onGroupFieldChange($event)"
                ></app-select>

                <ng-container actions>
                  <ng-container *ngIf="form.controls.group_field.value | appIsSet">
                    <app-list-groups-edit-overlay
                      [form]="form"
                      [context]="context"
                      [contextElement]="contextElement"
                      [addingMissing]="groupsAddMissingLoading"
                      [origin]="editing_fields_edit_origin"
                      [openedInitial]="groupsEditOverlayOpenedInitial"
                      [analyticsSource]="element.analyticsGenericName"
                      #list_groups_edit_overlay="appListGroupsEditOverlay"
                    ></app-list-groups-edit-overlay>

                    <span
                      class="sidebar__field-action sidebar__field-action_button icon-gear_2"
                      [class.sidebar__field-action_active]="list_groups_edit_overlay.isOpened()"
                      [appTip]="'Customize Grouping'"
                      [appTipOptions]="{ side: 'top' }"
                      cdkOverlayOrigin
                      #editing_fields_edit_origin="cdkOverlayOrigin"
                    ></span>

                    <app-text-style-edit-overlay
                      [control]="form.controls.element_styles.controls.group_title_style"
                      [origin]="origin"
                      #text_style_edit
                    ></app-text-style-edit-overlay>

                    <span
                      class="sidebar__field-action sidebar__field-action_button icon-text_2"
                      [class.sidebar__field-action_active]="!(groupTitleTextStyleIsDefault | async)"
                      [class.sidebar__field-action_disabled]="text_style_edit.isOpened()"
                      [appTip]="'Customize Title style'"
                      [appTipOptions]="{ side: 'top' }"
                      cdkOverlayOrigin
                      #origin="cdkOverlayOrigin"
                    ></span>
                  </ng-container>
                </ng-container>
              </app-sidebar-field>
            </div>
          </ng-container>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'multiple selection'" [layoutLeft]="true">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'multiple_selection',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'select first card by default'" [layoutLeft]="true">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'first_item_default_selection',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'enable search'" [layoutLeft]="true">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'search_enabled',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div *ngIf="form.controls.search_enabled.value" class="sidebar__element">
            <app-sidebar-field [label]="'update results while typing'" [layoutLeft]="true">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'search_live',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'display filters'" [layoutLeft]="true">
              <app-toggle
                *ngIf="!form.controls.display_filters.value"
                [selected]="false"
                [orange]="true"
                (click)="form.controls.display_filters.patchValue(true); form.controls.filter_fields.enableDefault()"
              ></app-toggle>

              <app-toggle
                *ngIf="form.controls.display_filters.value"
                [selected]="true"
                [orange]="true"
                (click)="form.controls.display_filters.patchValue(false)"
              ></app-toggle>

              <ng-container actions>
                <ng-container *ngIf="form.controls.display_filters.value">
                  <app-option-enabled-edit-overlay
                    [control]="form.controls.filter_fields"
                    [dataSourceControl]="form.controls.data_source"
                    [emptyMessage]="'Nothing to filter'"
                    [origin]="filter_fields_edit_origin"
                    #filter_fields_edit_overlay="appOptionEnabledEditOverlay"
                  ></app-option-enabled-edit-overlay>

                  <span
                    class="sidebar__field-action sidebar__field-action_button icon-gear_2"
                    [class.sidebar__field-action_active]="filter_fields_edit_overlay.isOpened()"
                    [appTip]="'Customize Enabled filters'"
                    [appTipOptions]="{ side: 'top' }"
                    cdkOverlayOrigin
                    #filter_fields_edit_origin="cdkOverlayOrigin"
                  ></span>
                </ng-container>
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'display footer'" [layoutLeft]="true">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'display_footer',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div *ngIf="visibleEditable" class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="settings.analyticsGenericName + '_visible'"
            >
              <app-input-edit
                [itemForm]="form.controls.visible_input"
                [context]="context"
                [contextElement]="contextElement"
                [contextElementPath]="visibleContextElementPath"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="settings.analyticsGenericName + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: elements.field.value >= 10
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'tooltip'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'tooltip',
                    field: 'CharField',
                    placeholder: 'Show information for users',
                    reset_enabled: true,
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'Gap between cards'">
              <app-gap-control
                [horizontalControl]="form.controls.gap_horizontal"
                [verticalControl]="form.controls.gap_vertical"
              ></app-gap-control>
            </app-sidebar-field>
          </div>

          <div *ngIf="elementStylesEditable" class="sidebar__element">
            <app-styles-grid-list-element [control]="form.controls.element_styles"></app-styles-grid-list-element>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Actions'" [error]="(actionsValid$ | async) !== false ? undefined : 'Not configured'">
        <app-sidebar-section>
          <div class="sidebar__list">
            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'When card clicked'"></app-sidebar-list-separator>
            </div>
            <div class="sidebar-list">
              <div class="sidebar-list__item">
                <app-action-single-edit
                  [options]="{ originEnabled: true }"
                  [title]="'Card click'"
                  [control]="form.controls.card_click_action"
                  [context]="context"
                  [contextElement]="contextElement"
                  [contextElementPath]="itemContextElementPath"
                  [defaultSubtitle]="'Not specified'"
                  [remove]="form.controls.card_click_action.value"
                  [icon]="'cursor'"
                  [object]="settings.analyticsGenericName"
                  [firstInit]="firstInit"
                >
                </app-action-single-edit>
              </div>
            </div>

            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'Card actions'"></app-sidebar-list-separator>
            </div>

            <ng-container *ngIf="!form.getCardView()">
              <app-actions-edit
                [options]="{
                  actionItemClass: viewSettingsActionClass,
                  nameEditable: true,
                  iconEditable: true,
                  styleEditable: true,
                  colorsEditable: true,
                  approveEnabled: true,
                  confirmationEnabled: true,
                  completionEditable: true,
                  disabledEditable: true,
                  visibleEditable: true,
                  originEnabled: true
                }"
                [control]="form.controls.model_actions"
                [context]="context"
                [contextElement]="contextElement"
                [contextElementPath]="itemContextElementPath"
                [emptyAction]="'Card action'"
                [emptyActionValue]="'Not specified'"
                [emptyIcon]="'duplicate_2'"
                [actionLabel]="'Card click'"
                [object]="settings.analyticsGenericName"
                [firstInit]="firstInit"
              >
              </app-actions-edit>
            </ng-container>

            <ng-container *ngIf="form.getCardView() as view">
              <div class="sidebar-list">
                <div *ngFor="let output of view.actions" class="sidebar-list__item">
                  <app-action-single-edit
                    *ngIf="form.controls.custom_actions.controls[output.name] as control"
                    [title]="output.verboseName"
                    [control]="control"
                    [context]="context"
                    [contextElement]="contextElement"
                    [contextElementPath]="itemContextElementPath"
                    [defaultSubtitle]="'Not specified'"
                    [remove]="control.value"
                    [icon]="output.icon || 'power'"
                    [object]="element.analyticsName"
                    [firstInit]="firstInit"
                  >
                  </app-action-single-edit>
                </div>

                <div *ngIf="!view.actions.length" class="sidebar__message">
                  There are no actions in component
                </div>
              </div>
            </ng-container>

            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'Header actions'"></app-sidebar-list-separator>
            </div>
            <app-actions-edit
              [options]="{
                actionItemClass: viewSettingsActionClass,
                nameEditable: true,
                iconEditable: true,
                styleEditable: true,
                colorsEditable: true,
                approveEnabled: true,
                confirmationEnabled: true,
                completionEditable: true,
                disabledEditable: true,
                visibleEditable: true,
                originEnabled: true
              }"
              [control]="form.controls.actions"
              [context]="context"
              [contextElement]="contextElement"
              [pin]="true"
              [emptyAction]="'Header action'"
              [emptyActionValue]="'Not specified'"
              [emptyIcon]="'fileds'"
              [actionLabel]="'Header action click'"
              [object]="settings.analyticsGenericName"
              [firstInit]="firstInit"
            >
            </app-actions-edit>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>
