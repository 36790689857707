import { Injectable, Injector, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { MenuSettings, MenuSettingsStore } from '@modules/menu';

import { MenuUpdateForm } from './menu-update.form';
import { ProjectAppearanceForm } from './project-appearance.form';

export interface AppearanceState {
  projectSettings: AllProjectSettings;
  menuSettings: MenuSettings;
}

export interface AppearanceStateChanges {
  projectSettings: boolean;
  menuSettings: boolean;
}

@Injectable()
export class ProjectAppearanceContext extends FormGroup implements OnDestroy {
  controls: {
    appearance: ProjectAppearanceForm;
    menu: MenuUpdateForm;
  };

  private initSubscriptions: Subscription[] = [];
  private savedState$ = new BehaviorSubject<AppearanceState>(undefined);
  private hasChanges$ = new BehaviorSubject<AppearanceStateChanges>({ projectSettings: false, menuSettings: false });

  constructor(
    private injector: Injector,
    private projectSettingsStore: ProjectSettingsStore,
    private menuSettingsStore: MenuSettingsStore
  ) {
    super({
      appearance: ProjectAppearanceForm.inject(injector),
      menu: MenuUpdateForm.inject(injector)
    });

    this.init();
  }

  init() {
    this.initSubscriptions.forEach(item => item.unsubscribe());
    this.initSubscriptions = [];

    const subscriptions: Subscription[] = [];

    subscriptions.push(
      combineLatest(this.projectSettingsStore.getAllSettingsFirst$(), this.menuSettingsStore.getFirst())
        .pipe(untilDestroyed(this))
        .subscribe(([projectSettings, menuSettings]) => {
          this.controls.appearance.init(projectSettings);
          this.controls.menu.init(menuSettings);

          this.savedState$.next(this.getState());
          this.hasChanges$.next({ projectSettings: false, menuSettings: false });

          subscriptions.push(
            this.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
              const savedState = this.savedState$.value;

              if (savedState) {
                const currentState = this.getState();
                const hasChanges = this.getStateChanges(currentState, savedState);

                this.hasChanges$.next(hasChanges);
              } else {
                this.hasChanges$.next({ projectSettings: false, menuSettings: false });
              }
            })
          );
        })
    );

    this.initSubscriptions = subscriptions;
  }

  ngOnDestroy(): void {}

  getState(): AppearanceState {
    return { projectSettings: this.controls.appearance.getInstance(), menuSettings: this.controls.menu.getInstance() };
  }

  getHasChanges(): boolean {
    const changes = this.hasChanges$.value;
    return changes.projectSettings || changes.menuSettings;
  }

  getHasChanges$(): Observable<boolean> {
    return this.hasChanges$.pipe(map(changes => changes.projectSettings || changes.menuSettings));
  }

  isStateProjectSettingsEqual(lhs: AllProjectSettings, rhs: AllProjectSettings): boolean {
    return isEqual(this.controls.appearance.serializeInstance(lhs), this.controls.appearance.serializeInstance(rhs));
  }

  isStateMenuEqual(lhs: MenuSettings, rhs: MenuSettings): boolean {
    return isEqual(
      lhs.blocks.map(item => item.serialize()),
      rhs.blocks.map(item => item.serialize())
    );
  }

  getStateChanges(lhs: AppearanceState, rhs: AppearanceState): AppearanceStateChanges {
    return {
      projectSettings: !this.isStateProjectSettingsEqual(lhs.projectSettings, rhs.projectSettings),
      menuSettings: !this.isStateMenuEqual(lhs.menuSettings, rhs.menuSettings)
    };
  }

  isStateEqual(lhs: AppearanceState, rhs: AppearanceState): boolean {
    const changes = this.getStateChanges(lhs, rhs);
    return !changes.projectSettings && !changes.menuSettings;
  }

  setState(state: AppearanceState, save = false) {
    this.controls.appearance.init(cloneDeep(state.projectSettings));
    this.controls.menu.init(cloneDeep(state.menuSettings));

    if (save) {
      this.savedState$.next(state);
      this.hasChanges$.next({ projectSettings: false, menuSettings: false });
    }
  }

  resetSavedState() {
    const savedState = this.savedState$.value;
    if (!savedState) {
      return;
    }

    this.setState(savedState, true);
  }

  saveCurrentState() {
    const state = this.getState();
    this.setState(state, true);
  }

  submit(): Observable<AppearanceState> {
    const savedState = this.savedState$.value;
    const hasChanges$ = this.hasChanges$.value;
    const obs$: Observable<any>[] = [];

    if (hasChanges$.projectSettings) {
      obs$.push(this.controls.appearance.submit());
    }

    if (hasChanges$.menuSettings) {
      obs$.push(this.controls.menu.submit());
    }

    if (!obs$.length) {
      return of(savedState);
    }

    return combineLatest(obs$).pipe(map(() => savedState));
  }
}
