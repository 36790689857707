import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as Color from 'color';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LocalStorage } from '@core';
import { PickColorService } from '@modules/colors';
import { Option } from '@modules/field-components';
import { ColorModel } from '@modules/views';
import { controlValue, deployUrl, isSet, MouseButton } from '@shared';

import { ColorControl, ColorControlValue } from '../../controls/color.control';

export const COLOR_MODEL_KEY = 'color_model';

@Component({
  selector: 'app-color-selector-advanced',
  templateUrl: './color-selector-advanced.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorSelectorAdvancedComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorSelectorAdvancedComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @Input() formControl: ColorControl;

  @ViewChild('hue_space_element') hueSpaceElement: ElementRef;

  colorModelControl = new FormControl(ColorModel.RGB);
  colorModelOptions: Option[] = [
    {
      value: ColorModel.RGB,
      name: 'RGB'
    },
    {
      value: ColorModel.HSL,
      name: 'HSL'
    },
    {
      value: ColorModel.HSB,
      name: 'HSB',
      nameAdditional: 'HSV'
    }
  ];
  hueSpaceX: number;
  hueSpaceY: number;
  hueGlobalControl = new FormControl(0);
  hueControl = new FormControl(0);
  redControl = new FormControl(0);
  greenControl = new FormControl(0);
  blueControl = new FormControl(0);
  saturationHSLControl = new FormControl(0);
  luminosityControl = new FormControl(0);
  saturationHSBControl = new FormControl(0);
  brightnessControl = new FormControl(0);
  alphaControl = new FormControl(100);
  hexControl = new FormControl('000000');
  hueSpaceBackground: string;
  hueSpaceThumbBackground: string;
  hueBackground =
    'linear-gradient(to right, #FF0000 0%, #FF7D00 12.38%, #FFFF00 19.52%, #00FF00 35.71%, #00FFFF 51.43%, #0000FF 65.72%, #FF00FF 83.33%, #FF0000 100%)';
  hueThumbBorder: string;
  alphaBackground: string;
  colorModels = ColorModel;

  onChange = (value: ColorControlValue) => undefined;
  onTouched = () => undefined;

  constructor(
    private pickColorService: PickColorService,
    private localStorage: LocalStorage,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const defaultColorModel = this.localStorage.get(COLOR_MODEL_KEY, ColorModel.RGB);

    this.colorModelControl.setValue(defaultColorModel);
    this.colorModelControl.markAsPristine();

    controlValue(this.hueGlobalControl).subscribe(hue => {
      this.hueSpaceBackground = Color({ h: hue, s: 100, l: 50 }).hex();
      this.hueThumbBorder = Color({ h: hue, s: 100, l: 30 }).hex();
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.initHueSpace();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: ColorControlValue): void {
    const red = Math.round(value.red * 255);
    const green = Math.round(value.green * 255);
    const blue = Math.round(value.blue * 255);
    const alpha = Math.round(value.alpha * 100);
    const clr = Color({ r: red, g: green, b: blue });

    this.setColor(clr);
    this.alphaControl.patchValue(alpha);
  }

  setColor(clr: Color, force = false) {
    const hex = clr.hex().slice(1).toUpperCase();

    if (hex == this.hexControl.value && !force) {
      return;
    }

    const rgb = clr.rgb();
    const hsl = clr.hsl();
    const hsv = clr.hsv();
    const [red, green, blue] = rgb.color;
    const [hue, saturationHSL, luminosity] = hsl.color;
    const [_, saturationHSB, brightness] = hsv.color;

    this.redControl.patchValue(Math.round(red));
    this.greenControl.patchValue(Math.round(green));
    this.blueControl.patchValue(Math.round(blue));
    this.hueGlobalControl.patchValue(hue);
    this.hueControl.patchValue(Math.round(hue));
    this.saturationHSLControl.patchValue(Math.round(saturationHSL));
    this.luminosityControl.patchValue(Math.round(luminosity));
    this.saturationHSBControl.patchValue(Math.round(saturationHSB));
    this.brightnessControl.patchValue(Math.round(brightness));
    this.hexControl.patchValue(hex);

    this.hueSpaceX = undefined;
    this.hueSpaceY = undefined;
    this.hueSpaceThumbBackground = '#' + hex;
    this.alphaBackground = [
      `linear-gradient(to right, rgba(${red}, ${green}, ${blue}, 0) 0%, rgba(${red}, ${green}, ${blue}, 1) 100%)`,
      `#fff center center / 10px url(${deployUrl('/assets/images/transparent.svg')})`
    ].join(', ');
    this.cd.markForCheck();
  }

  submitColor() {
    const colorModel = this.colorModelControl.value;
    const alpha = this.alphaControl.value;

    const value: ColorControlValue = { red: 0, green: 0, blue: 0, alpha: 1 };

    if (colorModel == ColorModel.RGB) {
      const red = this.redControl.value;
      const green = this.greenControl.value;
      const blue = this.blueControl.value;

      value.red = red / 255;
      value.green = green / 255;
      value.blue = blue / 255;
    } else if (colorModel == ColorModel.HSL) {
      const hue = this.hueControl.value;
      const saturationHSL = this.saturationHSLControl.value;
      const luminosity = this.luminosityControl.value;
      const clr = Color({ h: hue, s: saturationHSL, l: luminosity });
      const rgb = clr.rgb();
      const [clrRed, clrGreen, clrBlue] = rgb.color;

      value.red = clrRed / 255;
      value.green = clrGreen / 255;
      value.blue = clrBlue / 255;
    } else if (colorModel == ColorModel.HSB) {
      const hue = this.hueControl.value;
      const saturationHSB = this.saturationHSBControl.value;
      const brightness = this.brightnessControl.value;
      const clr = Color({ h: hue, s: saturationHSB, v: brightness });
      const rgb = clr.rgb();
      const [clrRed, clrGreen, clrBlue] = rgb.color;

      value.red = clrRed / 255;
      value.green = clrGreen / 255;
      value.blue = clrBlue / 255;
    }

    value.alpha = alpha / 100;

    this.onChange(value);
  }

  setHue(hue: number) {
    const clr = Color({ h: hue, s: this.saturationHSLControl.value, l: this.luminosityControl.value });
    this.setColor(clr);
    this.submitColor();
  }

  setRGB(options: { red?: number; green?: number; blue?: number } = {}) {
    const clr = Color({
      r: isSet(options.red) ? options.red : this.redControl.value,
      g: isSet(options.green) ? options.green : this.greenControl.value,
      b: isSet(options.blue) ? options.blue : this.blueControl.value
    });
    this.setColor(clr);
    this.submitColor();
  }

  setHSL(options: { hue?: number; saturationHSL?: number; luminosity?: number } = {}) {
    const clr = Color({
      h: isSet(options.hue) ? options.hue : this.hueControl.value,
      s: isSet(options.saturationHSL) ? options.saturationHSL : this.saturationHSLControl.value,
      l: isSet(options.luminosity) ? options.luminosity : this.luminosityControl.value
    });
    this.setColor(clr);
    this.submitColor();
  }

  setHSB(options: { hue?: number; saturationHSB?: number; brightness?: number } = {}) {
    const clr = Color({
      h: isSet(options.hue) ? options.hue : this.hueControl.value,
      s: isSet(options.saturationHSB) ? options.saturationHSB : this.saturationHSBControl.value,
      v: isSet(options.brightness) ? options.brightness : this.brightnessControl.value
    });
    this.setColor(clr);
    this.submitColor();
  }

  setHex(value: string) {
    const cleanValue = this.cleanHex(value);

    if (isSet(cleanValue) && cleanValue.length > 0) {
      try {
        const clr = Color('#' + cleanValue);
        this.setColor(clr, true);
        this.submitColor();
      } catch (e) {
        this.setColor(Color('#000'));
        this.submitColor();
      }
    }
  }

  cleanHex(value: string): string {
    if (!isSet(value)) {
      return;
    }

    return value
      .toUpperCase()
      .replace(/[^0-9A-F]/g, '')
      .substring(0, 6);
  }

  initHueSpace() {
    fromEvent<MouseEvent>(this.hueSpaceElement.nativeElement, 'mousedown')
      .pipe(
        filter(e => e.button == MouseButton.Main),
        untilDestroyed(this)
      )
      .subscribe(downEvent => {
        downEvent.preventDefault();

        const subscriptions = [];
        const bounds = this.hueSpaceElement.nativeElement.getBoundingClientRect();
        const downEventPoint = this.getMouseEventElementPoint(bounds, downEvent);

        this.setHSB({
          hue: this.hueGlobalControl.value,
          saturationHSB: Math.round(downEventPoint.x * 100),
          brightness: Math.round((1 - downEventPoint.y) * 100)
        });

        this.hueSpaceX = downEventPoint.x;
        this.hueSpaceY = downEventPoint.y;
        this.cd.markForCheck();

        subscriptions.push(
          fromEvent<MouseEvent>(document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(moveEvent => {
              moveEvent.preventDefault();
              moveEvent.stopPropagation();

              const moveEventPoint = this.getMouseEventElementPoint(bounds, moveEvent);

              this.setHSB({
                hue: this.hueGlobalControl.value,
                saturationHSB: Math.round(moveEventPoint.x * 100),
                brightness: Math.round((1 - moveEventPoint.y) * 100)
              });

              this.hueSpaceX = moveEventPoint.x;
              this.hueSpaceY = moveEventPoint.y;
              this.cd.markForCheck();
            })
        );

        subscriptions.push(
          fromEvent<MouseEvent>(document, 'mouseup')
            .pipe(
              filter(e => e.button == MouseButton.Main),
              untilDestroyed(this)
            )
            .subscribe(upEvent => {
              upEvent.preventDefault();
              subscriptions.forEach(item => item.unsubscribe());
            })
        );
      });
  }

  getMouseEventElementPoint(bounds: DOMRect, event: MouseEvent): { x: number; y: number } {
    return {
      x: clamp((event.clientX - bounds.left) / bounds.width, 0, 1),
      y: clamp((event.clientY - bounds.top) / bounds.height, 0, 1)
    };
  }

  saveColorModel() {
    this.localStorage.set(COLOR_MODEL_KEY, this.colorModelControl.value);
  }

  pickColor() {
    this.pickColorService
      .pick()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (isSet(result)) {
            this.alphaControl.patchValue(100);
            this.setHex(result.slice(1));
          }
        },
        () => {}
      );
  }
}
