import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { FillSettingsControl } from '@modules/colors-components';
import {
  BorderSettingsControl,
  CornersControl,
  IconSettingsControl,
  MarginControl,
  ShadowControl
} from '@modules/customize';

import { Style, StyleItem, StylesEditComponent, StyleType } from '../styles-edit/styles-edit.component';
import { TextStyleControl } from '../text-style-edit/text-style.control';
import { ActionElementStylesControl } from './action-element-styles.control';

@Component({
  selector: 'app-styles-action-element',
  templateUrl: './styles-action-element-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesActionElementEditComponent implements OnInit, OnDestroy {
  @Input() control: ActionElementStylesControl;

  @ViewChild(StylesEditComponent) stylesEditComponent: StylesEditComponent;

  styles: StyleItem[] = [];

  ngOnInit(): void {
    this.updateStyles();
  }

  ngOnDestroy(): void {}

  updateStyles() {
    this.styles = [
      {
        style: {
          label: 'Text',
          icon: 'text',
          type: StyleType.TextStyle,
          control: this.control.controls.text_style,
          isSet$: control => control.isSet$(),
          onRemove: style => style.control.resetDefaults()
        } as Style<TextStyleControl>
      },
      {
        style: {
          label: 'Icon',
          icon: 'text',
          type: StyleType.IconSettings,
          control: this.control.controls.icon_settings,
          isSet$: control => control.isSet$(),
          onRemove: style => style.control.reset()
        } as Style<IconSettingsControl>
      },
      {
        style: {
          label: 'Background',
          icon: 'palette',
          type: StyleType.Fill,
          control: this.control.controls.fill_settings,
          isSet$: control => control.isSet$(),
          onAdd: style => style.control.resetDefaults(),
          onRemove: style => style.control.reset()
        } as Style<FillSettingsControl>
      },
      {
        style: {
          label: 'Border',
          icon: 'sides',
          type: StyleType.BorderSettings,
          control: this.control.controls.border_settings,
          isSet$: control => control.isSet$(),
          onAdd: style => style.control.resetDefaults(),
          onRemove: style => style.control.reset()
        } as Style<BorderSettingsControl>
      },
      {
        style: {
          label: 'Corner radius',
          icon: 'corners',
          type: StyleType.Corners,
          control: this.control.controls.border_radius,
          isSet$: control => control.isSet$(),
          onAdd: style => style.control.resetDefaults(),
          onRemove: style => style.control.reset()
        } as Style<CornersControl>
      },
      {
        style: {
          label: 'Shadow',
          icon: 'duplicate_2',
          type: StyleType.Shadow,
          control: this.control.controls.shadow,
          isSet$: control => control.isSet$(),
          onAdd: style => style.control.resetDefaults(),
          onRemove: style => style.control.reset()
        } as Style<ShadowControl>
      },
      {
        style: {
          label: 'Inner spacing',
          icon: 'align_horizontal_fill',
          type: StyleType.Margin,
          control: this.control.controls.padding,
          isSet$: control => control.isSet$(),
          onRemove: style => style.control.reset()
        } as Style<MarginControl>
      },
      {
        style: {
          label: 'Outer spacing',
          icon: 'align_horizontal_center',
          type: StyleType.Margin,
          control: this.control.controls.margin,
          isSet$: control => control.isSet$(),
          onRemove: style => style.control.reset()
        } as Style<MarginControl>
      },
      {
        label: 'On hover',
        icon: 'cursor',
        children: [
          {
            label: 'Text',
            additional: '(on hover)',
            icon: 'text',
            type: StyleType.TextStyle,
            control: this.control.controls.hover_text_style,
            isSet$: control => control.isSet$(),
            onRemove: style => style.control.resetDefaults()
          } as Style<TextStyleControl>,
          {
            label: 'Icon',
            icon: 'text',
            type: StyleType.IconSettings,
            control: this.control.controls.hover_icon_settings,
            isSet$: control => control.isSet$(),
            onRemove: style => style.control.reset()
          } as Style<IconSettingsControl>,
          {
            label: 'Background',
            additional: '(on hover)',
            icon: 'palette',
            type: StyleType.Fill,
            control: this.control.controls.hover_fill_settings,
            isSet$: control => control.isSet$(),
            onAdd: style => style.control.resetDefaults(),
            onRemove: style => style.control.reset()
          } as Style<FillSettingsControl>,
          {
            label: 'Border',
            additional: '(on hover)',
            icon: 'sides',
            type: StyleType.BorderSettings,
            control: this.control.controls.hover_border_settings,
            isSet$: control => control.isSet$(),
            onAdd: style => style.control.resetDefaults(),
            onRemove: style => style.control.reset()
          } as Style<BorderSettingsControl>,
          {
            label: 'Shadow',
            additional: '(on hover)',
            icon: 'duplicate_2',
            type: StyleType.Shadow,
            control: this.control.controls.hover_shadow,
            isSet$: control => control.isSet$(),
            onAdd: style => style.control.resetDefaults(),
            onRemove: style => style.control.reset()
          } as Style<ShadowControl>
        ]
      },
      {
        label: 'On pressed',
        icon: 'select_all',
        children: [
          {
            label: 'Text',
            additional: '(on pressed)',
            icon: 'text',
            type: StyleType.TextStyle,
            control: this.control.controls.active_text_style,
            isSet$: control => control.isSet$(),
            onRemove: style => style.control.resetDefaults()
          } as Style<TextStyleControl>,
          {
            label: 'Icon',
            icon: 'text',
            type: StyleType.IconSettings,
            control: this.control.controls.active_icon_settings,
            isSet$: control => control.isSet$(),
            onRemove: style => style.control.reset()
          } as Style<IconSettingsControl>,
          {
            label: 'Background',
            additional: '(on pressed)',
            icon: 'palette',
            type: StyleType.Fill,
            control: this.control.controls.active_fill_settings,
            isSet$: control => control.isSet$(),
            onAdd: style => style.control.resetDefaults(),
            onRemove: style => style.control.reset()
          } as Style<FillSettingsControl>,
          {
            label: 'Border',
            additional: '(on pressed)',
            icon: 'sides',
            type: StyleType.BorderSettings,
            control: this.control.controls.active_border_settings,
            isSet$: control => control.isSet$(),
            onAdd: style => style.control.resetDefaults(),
            onRemove: style => style.control.reset()
          } as Style<BorderSettingsControl>,
          {
            label: 'Shadow',
            additional: '(on pressed)',
            icon: 'duplicate_2',
            type: StyleType.Shadow,
            control: this.control.controls.active_shadow,
            isSet$: control => control.isSet$(),
            onAdd: style => style.control.resetDefaults(),
            onRemove: style => style.control.reset()
          } as Style<ShadowControl>
        ]
      }
    ];
  }
}
