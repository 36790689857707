import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { AlignHorizontal, FieldElementStyles } from '@modules/customize';
import { FieldElementStylesControl } from '@modules/customize-bar';
import { controlValue } from '@shared';

@Component({
  selector: 'app-styles-preview-field',
  templateUrl: './styles-preview-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StylesPreviewFieldComponent implements OnInit, OnDestroy {
  @Input() control: FieldElementStylesControl;
  @Input() accentColor: string;

  fieldTypes = [
    { label: 'Field with value', value: 'Test value' },
    { label: 'Empty field', value: '' }
  ];
  elementStyles: FieldElementStyles;
  hoverElementStyles: FieldElementStyles;
  focusElementStyles: FieldElementStyles;
  errorElementStyles: FieldElementStyles;
  alignHorizontals = AlignHorizontal;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    combineLatest(controlValue(this.control), this.control.stylesDefaultUpdated$())
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.elementStyles = new FieldElementStyles({
          textStyle: this.control.controls.text_style.serialize(),
          placeholderStyle: this.control.controls.placeholder_style.serialize(),
          labelStyle: this.control.controls.label_style.serialize(),
          labelAdditionalStyle: this.control.controls.label_additional_style.serialize(),
          fillSettings: this.control.controls.fill_settings.serialize(false),
          borderSettings: this.control.controls.border_settings.serialize(false),
          borderRadius: this.control.controls.border_radius.serialize(),
          shadow: this.control.controls.shadow.serialize(false),
          padding: this.control.controls.padding.serialize(),
          margin: this.control.controls.margin.serialize()
        });
        this.hoverElementStyles = new FieldElementStyles({
          textStyle: this.control.controls.text_style.serialize(),
          placeholderStyle: this.control.controls.placeholder_style.serialize(),
          labelStyle: this.control.controls.label_style.serialize(),
          labelAdditionalStyle: this.control.controls.label_additional_style.serialize(),
          fillSettings: this.control.controls.fill_settings.serialize(false),
          borderSettings: this.control.controls.border_settings.serialize(false),
          borderRadius: this.control.controls.border_radius.serialize(),
          shadow: this.control.controls.shadow.serialize(false),
          padding: this.control.controls.padding.serialize(),
          margin: this.control.controls.margin.serialize(),

          ...(this.control.controls.hover_text_style.isSet() && {
            textStyle: this.control.controls.hover_text_style.serialize()
          }),
          ...(this.control.controls.hover_placeholder_style.isSet() && {
            placeholderStyle: this.control.controls.hover_placeholder_style.serialize()
          }),
          ...(this.control.controls.hover_fill_settings.isSet() && {
            fillSettings: this.control.controls.hover_fill_settings.serialize(false)
          }),
          ...(this.control.controls.hover_border_settings.isSet() && {
            borderSettings: this.control.controls.hover_border_settings.serialize(false)
          }),
          ...(this.control.controls.hover_shadow.isSet() && {
            shadow: this.control.controls.hover_shadow.serialize(false)
          })
        });
        this.focusElementStyles = new FieldElementStyles({
          textStyle: this.control.controls.text_style.serialize(),
          placeholderStyle: this.control.controls.placeholder_style.serialize(),
          labelStyle: this.control.controls.label_style.serialize(),
          labelAdditionalStyle: this.control.controls.label_additional_style.serialize(),
          fillSettings: this.control.controls.fill_settings.serialize(false),
          borderSettings: this.control.controls.border_settings.serialize(false),
          borderRadius: this.control.controls.border_radius.serialize(),
          shadow: this.control.controls.shadow.serialize(false),
          padding: this.control.controls.padding.serialize(),
          margin: this.control.controls.margin.serialize(),

          ...(this.control.controls.focus_text_style.isSet() && {
            textStyle: this.control.controls.focus_text_style.serialize()
          }),
          ...(this.control.controls.focus_placeholder_style.isSet() && {
            placeholderStyle: this.control.controls.focus_placeholder_style.serialize()
          }),
          ...(this.control.controls.focus_fill_settings.isSet() && {
            fillSettings: this.control.controls.focus_fill_settings.serialize(false)
          }),
          ...(this.control.controls.focus_border_settings.isSet() && {
            borderSettings: this.control.controls.focus_border_settings.serialize(false)
          }),
          ...(this.control.controls.focus_shadow.isSet() && {
            shadow: this.control.controls.focus_shadow.serialize(false)
          })
        });
        this.errorElementStyles = new FieldElementStyles({
          textStyle: this.control.controls.text_style.serialize(),
          placeholderStyle: this.control.controls.placeholder_style.serialize(),
          labelStyle: this.control.controls.label_style.serialize(),
          labelAdditionalStyle: this.control.controls.label_additional_style.serialize(),
          fillSettings: this.control.controls.fill_settings.serialize(false),
          borderSettings: this.control.controls.border_settings.serialize(false),
          borderRadius: this.control.controls.border_radius.serialize(),
          shadow: this.control.controls.shadow.serialize(false),
          padding: this.control.controls.padding.serialize(),
          margin: this.control.controls.margin.serialize(),

          ...(this.control.controls.error_text_style.isSet() && {
            textStyle: this.control.controls.error_text_style.serialize()
          }),
          ...(this.control.controls.error_placeholder_style.isSet() && {
            placeholderStyle: this.control.controls.error_placeholder_style.serialize()
          }),
          ...(this.control.controls.error_fill_settings.isSet() && {
            fillSettings: this.control.controls.error_fill_settings.serialize(false)
          }),
          ...(this.control.controls.error_border_settings.isSet() && {
            borderSettings: this.control.controls.error_border_settings.serialize(false)
          }),
          ...(this.control.controls.error_shadow.isSet() && {
            shadow: this.control.controls.error_shadow.serialize(false)
          })
        });
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}
}
