<app-filters-list-item
  *ngFor="let item of filters"
  class="filters__item"
  [item]="item"
  [filters]="filters"
  [dataSource]="dataSource"
  [bright]="bright"
  [context]="context"
  [contextElement]="contextElement"
  [theme]="theme"
  (filtersUpdated)="filtersUpdated.emit($event)"
></app-filters-list-item>

<app-filter-edit-overlay
  [dataSource]="dataSource"
  [filters]="filters"
  [origin]="add_filter_origin"
  [theme]="theme"
  (filtersUpdated)="filtersUpdated.emit($event)"
  #filter_add_overlay="appFilterEditOverlay"
></app-filter-edit-overlay>

<div
  class="filters-button filters__item"
  [class.filters-button_active]="filter_add_overlay.isOpened()"
  [appTintColor]="'accentColor' | appThemeColorHexOption: true | async"
  [appTintColorStyle]="tintStyles.Transparent"
  cdkOverlayOrigin
  #add_filter_origin="cdkOverlayOrigin"
>
  <div class="filters-button__icon icon-plus"></div>
  <div class="filters-button__label">{{ 'Add filter' | localize }}</div>
</div>
