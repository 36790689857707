<div appElement [appElementDetectVisible]="true" (appElementFirstVisible)="onFirstVisible()">
  <ng-container *ngIf="!readonly">
    <app-field-wrapper
      [form]="form"
      [field]="field"
      [readonly]="readonly"
      [requiredAsterisk]="requiredAsterisk"
      [label]="label"
      [labelStyle]="labelStyle"
      [labelStyleGlobal]="labelStyleGlobal"
      [labelAdditional]="labelAdditional"
      [labelAdditionalStyle]="labelAdditionalStyle"
      [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
      [errors]="errors"
      [truncate]="truncate"
      [manualMargin]="manualMargin"
      [labelButtons]="labelButtons"
      [tooltip]="tooltip"
      [idToken]="idToken"
      [theme]="field.params['theme']"
    >
      <div *ngIf="source" class="radio-button-field radio-button-field_manual-margin">
        <ng-container *ngIf="!(source.loading$ | async) && (source.options$ | async)?.length">
          <a
            *ngFor="let item of source.options$ | async; let first = first; let last = last"
            href="javascript:void(0)"
            class="button button_small"
            [class.button_segment-left]="first && !last"
            [class.button_segment-right]="!first && last"
            [class.button_segment-middle]="!first && !last"
            [class.button_primary]="valueOption?.value === item.value"
            [class.button_theme]="field.params['theme']"
            [class.button_styles]="field.params['theme']"
            [appButtonTintColor]="accentColor"
            [appButtonTintColorStyle]="valueOption?.value === item.value ? tintStyles.Primary : tintStyles.Default"
            [appActionElementStyles]="undefined"
            [appActionElementStylesTintColorStyle]="
              valueOption?.value === item.value ? tintStyles.Primary : tintStyles.Default
            "
            (click)="setCurrentValue(item.value)"
          >
            <span class="button__label">{{ item.name }}</span>
          </a>
        </ng-container>

        <ng-container *ngIf="(source.loading$ | async) || !(source.options$ | async)?.length">
          <div class="button button_small button_disabled" [class.button_theme]="field.params['theme']">
            <span class="button__label">
              <span [class.loading-animation]="source.loading$ | async"><span class="stub-text">Option</span></span>
            </span>
          </div>
        </ng-container>
      </div>

      <div *ngIf="!source && options$" class="radio-button-field radio-button-field_manual-margin">
        <ng-container *ngIf="(options$ | async)?.length">
          <a
            *ngFor="let item of options$ | async; let first = first; let last = last"
            href="javascript:void(0)"
            class="button button_small"
            [class.button_segment-left]="first && !last"
            [class.button_segment-right]="!first && last"
            [class.button_segment-middle]="!first && !last"
            [class.button_primary]="valueOption?.value === item.value && !accentColor"
            [class.button_icon]="item.icon && !item.name"
            [class.button_disabled]="control?.disabled"
            [class.button_theme]="field.params['theme']"
            [class.button_styles]="field.params['theme']"
            [appButtonTintColor]="accentColor"
            [appButtonTintColorStyle]="valueOption?.value === item.value ? tintStyles.Primary : tintStyles.Default"
            [appActionElementStyles]="undefined"
            [appActionElementStylesTintColorStyle]="
              valueOption?.value === item.value ? tintStyles.Primary : tintStyles.Default
            "
            (click)="setCurrentValue(item.value)"
          >
            <span
              *ngIf="item.icon"
              class="button__icon"
              [appIcon]="item.icon"
              [class.button__icon_left]="item.name"
            ></span>
            <span *ngIf="item.name" class="button__label">{{ item.name }}</span>
          </a>
        </ng-container>

        <ng-container *ngIf="!(options$ | async)?.length">
          <div class="button button_small button_disabled" [class.button_theme]="field.params['theme']">
            <span class="button__label">
              <span class="stub-text">Option</span>
            </span>
          </div>
        </ng-container>
      </div>
    </app-field-wrapper>
  </ng-container>

  <app-field-wrapper
    *ngIf="readonly"
    [form]="form"
    [field]="field"
    [readonly]="readonly"
    [requiredAsterisk]="requiredAsterisk"
    [label]="label"
    [labelStyle]="labelStyle"
    [labelStyleGlobal]="labelStyleGlobal"
    [labelAdditional]="labelAdditional"
    [labelAdditionalStyle]="labelAdditionalStyle"
    [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
    [errors]="errors"
    [truncate]="truncate"
    [manualMargin]="manualMargin"
    [labelButtons]="labelButtons"
    [tooltip]="tooltip"
    [idToken]="idToken"
    [theme]="field.params['theme']"
  >
    <ng-container *ngIf="valueOption">
      <span
        class="status"
        [class.status_theme]="field.params['theme']"
        [ngClass]="
          !(valueOption.color | appIsColorHex)
            ? ['color_' + valueOption.color + '_1', 'background-color_' + valueOption.color + '_2']
            : ['']
        "
        [style.color]="(valueOption.color | appIsColorHex) ? valueOption.color : null"
        [style.background-color]="(valueOption.color | appIsColorHex) ? backgroundCustomColor(valueOption.color) : null"
      >
        {{ valueOption.name || valueOption.value }}
      </span>
    </ng-container>
    <ng-container *ngIf="!valueOption">
      {{ formatEmpty(value) }}
    </ng-container>
  </app-field-wrapper>
</div>
