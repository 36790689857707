<app-sidebar-field [label]="'Styles'">
  <ng-container actions-right>
    <div
      *ngIf="currentProjectStore.instance.features.isStylesEnabled()"
      class="sidebar__field-action sidebar__field-action_button icon-plus"
      [class.sidebar__field-action_active]="trigger.menuOpen"
      [class.sidebar__field-action_bright-active]="false"
      [matMenuTriggerFor]="dropdown"
      #trigger="matMenuTrigger"
    ></div>

    <div
      *ngIf="!currentProjectStore.instance.features.isStylesEnabled()"
      class="sidebar__field-action sidebar__field-action_button icon-plus"
      [class.sidebar__field-action_bright-active]="false"
      (click)="showFeatureOverview()"
    ></div>

    <mat-menu #dropdown="matMenu">
      <ng-template matMenuContent>
        <ng-container *ngFor="let item of styles">
          <ng-container *ngIf="item.style as style">
            <button
              *ngIf="!style.isEnabled || style.isEnabled(style)"
              mat-menu-item
              class="mat-menu-item-odd"
              [disabled]="!isAddStyleEnabled(style)"
              [disableRipple]="true"
              (click)="addStyle(style)"
            >
              <span class="choose-items-item__dropdown-icon" [ngClass]="'icon-' + style.icon"></span>
              {{ style.label }}
            </button>
          </ng-container>

          <ng-container *ngIf="item.children as children">
            <mat-menu #children_dropdown="matMenu">
              <ng-template matMenuContent>
                <ng-container *ngFor="let child of children">
                  <button
                    *ngIf="!child.isEnabled || child.isEnabled(child)"
                    mat-menu-item
                    class="mat-menu-item-odd"
                    [disabled]="!isAddStyleEnabled(child)"
                    [disableRipple]="true"
                    (click)="addStyle(child)"
                  >
                    <span class="choose-items-item__dropdown-icon" [ngClass]="'icon-' + child.icon"></span>
                    {{ child.label }}
                  </button>
                </ng-container>
              </ng-template>
            </mat-menu>

            <button
              mat-menu-item
              class="mat-menu-item-odd"
              [disableRipple]="true"
              [matMenuTriggerFor]="children_dropdown"
            >
              <span *ngIf="item.icon" class="choose-items-item__dropdown-icon" [ngClass]="'icon-' + item.icon"></span>
              {{ item.label }}
            </button>
          </ng-container>
        </ng-container>
      </ng-template>
    </mat-menu>
  </ng-container>

  <ng-container *ngFor="let group of enabledStylesGroups; trackBy: trackStyleGroupFn">
    <div *ngIf="group.label" class="sidebar__element sidebar__element_margin_xs sidebar__element_padding_none">
      <mat-menu #group_dropdown="matMenu">
        <ng-template matMenuContent>
          <ng-container *ngFor="let style of group.allStyles">
            <button
              *ngIf="!style.isEnabled || style.isEnabled(style)"
              mat-menu-item
              class="mat-menu-item-odd"
              [disabled]="!isAddStyleEnabled(style)"
              [disableRipple]="true"
              (click)="addStyle(style)"
            >
              <span class="choose-items-item__dropdown-icon" [ngClass]="'icon-' + style.icon"></span>
              {{ style.label }}
            </button>
          </ng-container>
        </ng-template>
      </mat-menu>

      <div class="sidebar-field sidebar-field-layout-default">
        <div
          class="sidebar-field__label sidebar-field-layout-default__label sidebar-field-layout-default__label_no-margin"
        >
          <div class="sidebar-field__label-main">
            <span
              *ngIf="group.icon | appIsSet"
              class="sidebar-field__label-title-icon"
              [ngClass]="'icon-' + group.icon"
            ></span>

            <span class="sidebar-field__label-title">{{ group.label }}</span>
          </div>

          <div class="sidebar-field__label-right">
            <div
              class="sidebar__field-action sidebar__field-action_button icon-plus"
              [class.sidebar__field-action_active]="group_trigger.menuOpen"
              [class.sidebar__field-action_bright-active]="false"
              [matMenuTriggerFor]="group_dropdown"
              #group_trigger="matMenuTrigger"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar-container2">
      <div *ngFor="let item of group.styles; trackBy: trackStyleFn" class="sidebar-container2__item">
        <app-styles-edit-text-style
          *ngIf="item.type == styleTypes.TextStyle"
          [control]="asAny(item.control)"
          [subtitle]="item.label"
          [additional]="item.additional"
          [added]="item.control === addedStyle?.control"
          (remove)="removeStyle(item)"
        ></app-styles-edit-text-style>

        <app-styles-edit-icon
          *ngIf="item.type == styleTypes.IconSettings"
          [control]="asAny(item.control)"
          [subtitle]="item.label"
          [additional]="item.additional"
          [added]="item.control === addedStyle?.control"
          (remove)="removeStyle(item)"
        ></app-styles-edit-icon>

        <app-styles-edit-fill
          *ngIf="item.type == styleTypes.Fill"
          [control]="asAny(item.control)"
          [subtitle]="item.label"
          [additional]="item.additional"
          [added]="item.control === addedStyle?.control"
          (remove)="removeStyle(item)"
        ></app-styles-edit-fill>

        <app-styles-edit-border
          *ngIf="item.type == styleTypes.BorderSettings"
          [control]="asAny(item.control)"
          [subtitle]="item.label"
          [additional]="item.additional"
          [added]="item.control === addedStyle?.control"
          (remove)="removeStyle(item)"
        ></app-styles-edit-border>

        <app-styles-edit-border-radius
          *ngIf="item.type == styleTypes.Corners"
          [control]="asAny(item.control)"
          [subtitle]="item.label"
          [additional]="item.additional"
          [added]="item.control === addedStyle?.control"
          (remove)="removeStyle(item)"
        ></app-styles-edit-border-radius>

        <app-styles-edit-shadow
          *ngIf="item.type == styleTypes.Shadow"
          [control]="asAny(item.control)"
          [subtitle]="item.label"
          [additional]="item.additional"
          [added]="item.control === addedStyle?.control"
          (remove)="removeStyle(item)"
        ></app-styles-edit-shadow>

        <app-styles-edit-margin
          *ngIf="item.type == styleTypes.Margin"
          [control]="asAny(item.control)"
          [subtitle]="item.label"
          [additional]="item.additional"
          [icon]="item.icon"
          [added]="item.control === addedStyle?.control"
          (remove)="removeStyle(item)"
        ></app-styles-edit-margin>
      </div>

      <div *ngIf="!group.styles.length" class="sidebar-container2 sidebar-container2_empty">
        <div class="sidebar-container2__message">
          No styles added
        </div>
      </div>
    </div>
  </ng-container>
</app-sidebar-field>
