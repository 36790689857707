<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <div *ngIf="control" class="input-icon-wrapper">
    <input
      class="input"
      [class.input_error]="(form | appFormFieldErrors: field.name)?.length"
      [class.input_wide]="field.params['wide']"
      [class.input_icon-right]="field.resetEnabled"
      [class.input_theme]="field.params['theme']"
      [class.input_styles]="field.params['theme']"
      [appFieldElementStyles]="elementStyles"
      [appFieldElementStylesEnabled]="field.params['theme']"
      [ngClass]="field.params['classes']"
      type="text"
      [appInputFormat]="{
        type:
          (valueFormat?.numberFraction | appIsSet)
            ? valueFormat?.numberFraction
              ? numberFieldTypes.Decimal
              : numberFieldTypes.Integer
            : field.params['type'],
        min: field.params['min_value'],
        max: field.params['max_value'],
        fraction: (valueFormat?.numberFraction | appIsSet) ? valueFormat?.numberFraction : field.params['fraction'],
        fractionSeparator:
          (valueFormat?.numberFractionSeparator | appIsSet)
            ? valueFormat?.numberFractionSeparator
            : defaultNumberFractionSeparator,
        thousandsSeparator:
          (valueFormat?.numberThousandsSeparator | appIsSet: true)
            ? valueFormat?.numberThousandsSeparator
            : defaultNumberThousandsSeparator,
        prefix: valueFormat?.prefix
      }"
      [appAutofocus]="autofocus"
      [formControl]="control"
      [id]="field.name | appUniqueId: idToken"
      [placeholder]="field.placeholder || ''"
    />

    <span
      *ngIf="field.resetEnabled && (control.value | appIsSet)"
      class="input-icon input-icon_interactive input-icon_right icon-close"
      [class.input-icon_small]="inputSmall"
      [class.input-icon_theme]="field.params['theme']"
      (click)="resetCurrentValue()"
    ></span>
  </div>
</app-field-wrapper>

<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  {{ formatEmpty((displayValue$ | appIsSet) ? (displayValue$ | async) : undefined) }}
</app-field-wrapper>
