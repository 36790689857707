<button
  *ngIf="visible || (customizeEnabled$ | async)"
  type="button"
  class="button button_theme button_styles"
  [class.button_primary]="!item.tint && item.style == tintStyles.Primary"
  [class.button_primary-transparent]="!item.tint && item.style == tintStyles.Transparent"
  [class.button_icon]="!(verboseName | appIsSet)"
  [class.button_fill]="fill"
  [class.button_disabled]="disabled"
  [class.button_segment-left]="left"
  [class.button_segment-right]="right"
  [class.button_segment-top]="top"
  [class.button_segment-bottom]="bottom"
  [class.button_segment-middle]="middle && !vertical"
  [class.button_segment-middle-vertical]="middle && vertical"
  [appButtonTintColor]="item.tint || accentColor"
  [appButtonTintColorStyle]="item.style"
  [appActionElementStyles]="undefined"
  [appActionElementStylesTintColorStyle]="item.style"
  [disabled]="disabled || submitLoading"
  (click)="executeAction(item, button_element)"
  #button_element
>
  <app-loader-small
    *ngIf="submitLoading"
    class="button__icon"
    [class.button__icon_left]="verboseName | appIsSet"
  ></app-loader-small>
  <span
    *ngIf="item.icon && !submitLoading"
    class="button__icon"
    [class.button__icon_left]="verboseName | appIsSet"
    [appIcon]="item.icon"
  ></span>

  <span *ngIf="verboseName | appIsSet" class="button__label">
    {{ verboseName | appCapitalize }}
  </span>

  <span
    *ngIf="!visible && (customizeEnabled$ | async)"
    class="element__indicator element__indicator_tiny icon-eye"
    [appTip]="'Component is hidden'"
  ></span>
</button>
