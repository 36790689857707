/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../filters-list-item/filters-list-item.component.ngfactory";
import * as i2 from "../../../models-list/stores/model-select-source";
import * as i3 from "../../../model-queries/services/model/model.service";
import * as i4 from "../../../model-queries/stores/model-description.store";
import * as i5 from "../../../projects/stores/current-project.store";
import * as i6 from "../../../projects/stores/current-environment.store";
import * as i7 from "../../../model-queries/services/model-utils/model-utils.service";
import * as i8 from "../filters-list-item/filters-list-item.component";
import * as i9 from "../../../theme-components/pipes/theme-color-hex-option/theme-color-hex-option.pipe";
import * as i10 from "../../../theme-components/data/theme-context";
import * as i11 from "../../../theme/services/theme/theme.service";
import * as i12 from "../../../../common/localize/pipes/localize/localize.pipe";
import * as i13 from "@angular/common";
import * as i14 from "../filter-edit-overlay/filter-edit-overlay.component.ngfactory";
import * as i15 from "../filter-edit-overlay/filter-edit-overlay.component";
import * as i16 from "@angular/cdk/overlay";
import * as i17 from "../../../colors-shared/directives/tint-color/tint-color.directive";
import * as i18 from "./filters-list.component";
var styles_FiltersListComponent = [];
var RenderType_FiltersListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FiltersListComponent, data: {} });
export { RenderType_FiltersListComponent as RenderType_FiltersListComponent };
function View_FiltersListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-filters-list-item", [["class", "filters__item"]], [[2, "filters__item", null], [2, "filters__item_active", null]], [[null, "filtersUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filtersUpdated" === en)) {
        var pd_0 = (_co.filtersUpdated.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FiltersListItemComponent_0, i1.RenderType_FiltersListItemComponent)), i0.ɵprd(512, null, i2.ModelSelectSource, i2.ModelSelectSource, [i3.ModelService, i4.ModelDescriptionStore, i5.CurrentProjectStore, i6.CurrentEnvironmentStore, i0.Injector, i7.ModelUtilsService]), i0.ɵdid(2, 770048, null, 0, i8.FiltersListItemComponent, [i6.CurrentEnvironmentStore, i4.ModelDescriptionStore, i2.ModelSelectSource, i0.ChangeDetectorRef], { item: [0, "item"], filters: [1, "filters"], dataSource: [2, "dataSource"], bright: [3, "bright"], context: [4, "context"], contextElement: [5, "contextElement"], theme: [6, "theme"] }, { filtersUpdated: "filtersUpdated" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit; var currVal_3 = _co.filters; var currVal_4 = _co.dataSource; var currVal_5 = _co.bright; var currVal_6 = _co.context; var currVal_7 = _co.contextElement; var currVal_8 = _co.theme; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).cls; var currVal_1 = i0.ɵnov(_v, 2).activeCls; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_FiltersListComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i9.ThemeColorHexOptionPipe, [[2, i10.ThemeContext], [2, i11.ThemeService]]), i0.ɵpid(0, i12.LocalizePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FiltersListComponent_1)), i0.ɵdid(3, 278528, null, 0, i13.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-filter-edit-overlay", [], null, [[null, "filtersUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filtersUpdated" === en)) {
        var pd_0 = (_co.filtersUpdated.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_FilterEditOverlayComponent_0, i14.RenderType_FilterEditOverlayComponent)), i0.ɵdid(5, 4440064, [["filter_add_overlay", 4]], 0, i15.FilterEditOverlayComponent, [i0.ChangeDetectorRef], { dataSource: [0, "dataSource"], filters: [1, "filters"], origin: [2, "origin"], theme: [3, "theme"] }, { filtersUpdated: "filtersUpdated" }), (_l()(), i0.ɵeld(6, 0, null, null, 8, "div", [["cdkOverlayOrigin", ""], ["class", "filters-button filters__item"]], [[2, "filters-button_active", null]], null, null, null, null)), i0.ɵdid(7, 16384, [["add_filter_origin", 4]], 0, i16.CdkOverlayOrigin, [i0.ElementRef], null, null), i0.ɵdid(8, 540672, null, 0, i17.TintColorDirective, [i0.ElementRef], { appTintColor: [0, "appTintColor"], appTintColorStyle: [1, "appTintColorStyle"] }, null), i0.ɵppd(9, 2), i0.ɵpid(131072, i13.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(11, 0, null, null, 0, "div", [["class", "filters-button__icon icon-plus"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "filters-button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ""])), i0.ɵppd(14, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filters; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.dataSource; var currVal_2 = _co.filters; var currVal_3 = i0.ɵnov(_v, 7); var currVal_4 = _co.theme; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 10).transform(i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v, 0), "accentColor", true)))); var currVal_7 = _co.tintStyles.Transparent; _ck(_v, 8, 0, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_5 = i0.ɵnov(_v, 5).isOpened(); _ck(_v, 6, 0, currVal_5); var currVal_8 = i0.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i0.ɵnov(_v, 1), "Add filter")); _ck(_v, 13, 0, currVal_8); }); }
export function View_FiltersListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-filters-list", [], null, null, null, View_FiltersListComponent_0, RenderType_FiltersListComponent)), i0.ɵdid(1, 114688, null, 0, i18.FiltersListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FiltersListComponentNgFactory = i0.ɵccf("app-filters-list", i18.FiltersListComponent, View_FiltersListComponent_Host_0, { filters: "filters", dataSource: "dataSource", bright: "bright", context: "context", contextElement: "contextElement", theme: "theme" }, { filtersUpdated: "filtersUpdated" }, []);
export { FiltersListComponentNgFactory as FiltersListComponentNgFactory };
