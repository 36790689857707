<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <div *ngIf="control">
    <app-slider
      [baseControl]="control"
      [min]="minValue"
      [minLabel]="(minValueLabel | appIsSet) ? minValueLabel : (minValue | appDomainFormatNumber: '0' | async)"
      [max]="maxValue"
      [maxLabel]="(maxValueLabel | appIsSet) ? maxValueLabel : (maxValue | appDomainFormatNumber: '0' | async)"
      [normal]="normalValue"
      [normalLabel]="normalValueLabel"
      [step]="stepSize"
      [stickToSteps]="field.params['stick_to_steps']"
      [debounce]="0"
      [fillBackground]="field.params['tint'] || accentColor"
      [thumbBorderColor]="field.params['tint'] || accentColor"
      [orange]="field.params['orange']"
      [thumbInside]="field.params['thumb_inside']"
      [theme]="field.params['theme']"
    ></app-slider>
  </div>
</app-field-wrapper>

<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <app-slider
    [baseControl]="control"
    [baseValue]="currentValue"
    [disabled]="true"
    [min]="minValue"
    [minLabel]="(minValueLabel | appIsSet) ? minValueLabel : (minValue | appDomainFormatNumber: '0' | async)"
    [max]="maxValue"
    [maxLabel]="(maxValueLabel | appIsSet) ? maxValueLabel : (maxValue | appDomainFormatNumber: '0' | async)"
    [normal]="normalValue"
    [normalLabel]="normalValueLabel"
    [step]="stepSize"
    [stickToSteps]="field.params['stick_to_steps']"
    [debounce]="0"
    [fillBackground]="field.params['tint']"
    [thumbBorderColor]="field.params['tint']"
    [orange]="field.params['orange']"
    [thumbInside]="field.params['thumb_inside']"
  ></app-slider>
</app-field-wrapper>
