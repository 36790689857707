<ng-container *ngIf="!loading">
  <div *ngIf="!fieldValue">
    <div class="popover2__viewport popover2__viewport_scrollable">
      <div class="popover2__content" style="min-width: 300px;">
        <app-select-data-source-field
          [dataSource]="dataSource"
          [searchPlaceholder]="'Filter by...' | localize"
          [relationsSelectEnabled]="false"
          [onlyVisible]="onlyVisible"
          [optionsFilter]="optionsFilter"
          (selected)="selectField($event)"
        ></app-select-data-source-field>
      </div>
    </div>
  </div>

  <form *ngIf="fieldValue" [formGroup]="form" (ngSubmit)="submit()">
    <div class="filter-edit-value" [class.filter-edit-value_theme]="theme">
      <div class="filter-edit-value__header">
        <div class="filter-edit-value__header-main">
          <div class="filter-edit-value__header-field">
            <span
              *ngIf="fieldValueDescription"
              class="filter-edit-value__header-field-icon"
              [ngClass]="'icon-' + fieldValueDescription.icon"
            ></span>

            <span class="filter-edit-value__header-field-label">
              {{ fieldValue.field.name }}
            </span>
          </div>

          <ng-container *ngIf="lookups.length">
            <mat-menu #lookup_dropdown="matMenu">
              <ng-template matMenuContent>
                <ng-container *ngFor="let lookup of lookups">
                  <ng-container *ngFor="let exclude of excludeLookupsSupported ? [false, true] : [false]">
                    <button
                      mat-menu-item
                      class="mat-menu-item-odd"
                      [class.mat-menu-item-active]="
                        form.controls.lookup.value?.type?.lookup === lookup.type.lookup &&
                        form.controls.exclude.value == exclude
                      "
                      [disableRipple]="true"
                      (click)="form.controls.lookup.patchValue(lookup); form.controls.exclude.patchValue(exclude)"
                    >
                      <span
                        *ngIf="lookup.type.icon"
                        class="mat-menu-item-icon"
                        [ngClass]="['icon-' + lookup.type.icon]"
                      ></span>
                      <span *ngIf="!lookup.type.icon" class="mat-menu-item-icon">{{ lookup.type.symbol }}</span>

                      {{ lookup.type.str(undefined, undefined, exclude) }}
                    </button>
                  </ng-container>
                </ng-container>
              </ng-template>
            </mat-menu>

            <div
              class="inline-selector filter-edit-value__header-lookup"
              [class.inline-selector_active]="lookup_trigger.menuOpen"
              [matMenuTriggerFor]="lookup_dropdown"
              #lookup_trigger="matMenuTrigger"
            >
              <div class="inline-selector__label">
                <ng-container *ngIf="form.controls.lookup.value && !form.controls.lookup.value.type?.str">
                  {{ 'equals' | localize }}
                </ng-container>

                <ng-container *ngIf="form.controls.lookup.value && form.controls.lookup.value.type?.str">
                  {{ form.controls.lookup.value.type.str(undefined, undefined, form.controls.exclude.value) }}
                </ng-container>

                <ng-container *ngIf="!form.controls.lookup.value">
                  {{ 'choose' | localize | appCapitalize }}
                </ng-container>
              </div>

              <span
                class="inline-selector__icon"
                [class.icon-arrow_up_2]="lookup_trigger.menuOpen"
                [class.icon-arrow_down_2]="!lookup_trigger.menuOpen"
              ></span>
            </div>
          </ng-container>

          <div *ngIf="!lookups.length" class="filter-edit-value__header-lookup">{{ 'equals' | localize }}</div>
        </div>

        <div *ngIf="filter" class="filter-edit-value__header-right">
          <div
            class="filter-edit-value__header-action filter-edit-value__header-action_danger icon-bin"
            [appTip]="'Delete this filter' | localize"
            [appTipOptions]="{ side: 'top' }"
            (click)="removeFilter()"
          ></div>
        </div>
      </div>

      <div class="filter-edit-value__value">
        <ng-container *ngIf="valueField">
          <ng-container *ngIf="form.controls.lookup.value?.array">
            <div
              *ngFor="let control of form.controls.value_array.controls; let i = index"
              class="filter-edit-value__value-item"
            >
              <app-filter-edit-value
                [control]="control"
                [valueField]="valueField"
                [autoFocus]="control === addedValueControl"
              ></app-filter-edit-value>

              <div
                *ngIf="form.controls.value_array.controls.length > 1"
                class="filter-edit-value__value-item-close icon-close"
                (click)="removeValueControl(i)"
              ></div>
            </div>

            <div class="filter-edit-value__actions">
              <div class="filter-edit-value__link" (click)="addValueControl()">
                <span class="filter-edit-value__link-icon icon-plus"></span>
                <span class="filter-edit-value__link-label">Add</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!form.controls.lookup.value?.array">
            <app-filter-edit-value
              [control]="form.controls.value"
              [valueField]="valueField"
              [autoFocus]="true"
            ></app-filter-edit-value>
          </ng-container>
        </ng-container>

        <input
          *ngIf="!valueField"
          class="input input_small input_fill input_disabled"
          type="text"
          [placeholder]="form.controls.lookup.value?.type?.verboseName || ''"
          disabled
        />
      </div>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="loading">
  <div *ngIf="!filter && !field">
    <div class="popover2__viewport popover2__viewport_scrollable">
      <div class="popover2__content" style="min-width: 300px;">
        <div class="token-popup__item">
          <div class="token-popup-search">
            <div class="token-popup-search__input">
              <span [class.loading-animation]="true">
                <span class="stub-text">
                  Filter
                </span>
              </span>
            </div>
          </div>
        </div>

        <div class="token-popup__item" style="margin-top: 10px;">
          <div class="token-popup-section">
            <div class="token-popup-section__content">
              <ng-container *ngFor="let item of ['name', 'category', 'active', 'date_add']">
                <div class="token-popup-list-item">
                  <div class="token-popup-list-item__inner">
                    <div class="token-popup-list-item__line">
                      <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                        <span [class.loading-animation]="true">
                          <span class="stub-text">{{ item }}</span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="filter || field" class="filter-edit-value">
    <div class="filter-edit-value__header">
      <div class="filter-edit-value__header-main">
        <div class="filter-edit-value__header-field">
          <span [class.loading-animation]="true">
            <span class="stub-text">field</span>
          </span>
        </div>

        <div class="filter-edit-value__header-lookup">
          <span [class.loading-animation]="true">
            <span class="stub-text">equals</span>
          </span>
        </div>
      </div>
    </div>

    <div class="filter-edit-value__value">
      <div class="input input_small input_fill input_disabled input_stub">
        <span [class.loading-animation]="true">
          <span class="stub-text">Value</span>
        </span>
      </div>
    </div>
  </div>
</ng-container>
