<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
  class="rich-text-field"
  [class.rich-text-field_theme]="field.params['theme']"
>
  <textarea
    *ngIf="!isMarkdown()"
    class="input input_textarea input_fill input_fill-vertical input_stub input_disabled"
    [class.input_error]="(form | appFormFieldErrors: field.name)?.length"
    [class.input_wide]="field.params['wide']"
    [class.input_theme]="field.params['theme']"
    [class.loading-animation]="true"
    [appAutofocus]="autofocus"
    [formControlName]="field.name"
    [id]="field.name | appUniqueId: idToken"
    [placeholder]="field.placeholder || ''"
    #input
  >
  </textarea>
  <div *ngIf="isMarkdown()" #editorjs></div>
</app-field-wrapper>
<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <span [innerHTML]="formatEmpty(sanitizeValue)"></span>
</app-field-wrapper>
