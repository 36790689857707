<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <div
    class="code-editor"
    [class.code-editor_autogrow]="field.params['autogrow']"
    [class.code-editor_flexible]="field.params['flexible']"
  >
    <div class="code-editor__main">
      <div
        class="code-editor__main-code"
        [class.code-editor__main-code_field]="field.params['field']"
        [class.code-editor__main-code_field_contrast]="field.params['field_contrast']"
        [class.code-editor__main-code_side]="false"
        [ngClass]="field.params['classes']"
      >
        <div
          *ngIf="field.placeholder && !(form.value[field.name] | appIsSet)"
          class="code-editor__placeholder"
          [innerHTML]="field.placeholder | appFormatText"
        ></div>

        <div
          ace-editor
          [mode]="field.params['language'] || 'javascript'"
          [theme]="theme"
          [text]="form.value[field.name]"
          [options]="{
            maxLines: 1000,
            printMargin: false,
            enableBasicAutocompletion: true,
            enableSnippets: true,
            enableLiveAutocompletion: true,
            tooltipFollowsMouse: false,
            indentedSoftWrap: false,
            tabSize: 2,
            useSoftTabs: true
          }"
          [readOnly]="control?.disabled"
          (textChanged)="setCurrentValue($event)"
          class="code-editor__editor"
          [id]="field.name | appUniqueId: idToken"
        ></div>
      </div>
      <!--      <div *ngIf="children?.length" class="code-editor__main-side">-->
      <!--        <div class="code-editor__structure">-->
      <!--          <app-token-structure-item-->
      <!--            *ngFor="let item of children; let last = last"-->
      <!--            [item]="item"-->
      <!--            [itemLast]="last"-->
      <!--            (inserted)="editorInsert($event.insert)"-->
      <!--          >-->
      <!--          </app-token-structure-item>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</app-field-wrapper>
<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  {{ formatEmpty(currentValue) }}
</app-field-wrapper>
