import { Pipe, PipeTransform } from '@angular/core';

import { ActionElementItem, ActionElementStyles, getActionElementStyles } from '@modules/customize';

@Pipe({
  name: 'appActionElementStyles',
  pure: false
})
export class ActionElementStylesPipe implements PipeTransform {
  transform(element: ActionElementItem): ActionElementStyles {
    return getActionElementStyles(element);
  }
}
