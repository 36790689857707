var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { colors, defaultAccentColor } from '@modules/colors';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { ThemeContext } from '@modules/theme-components';
import { isSet } from '@shared';
import { FieldComponent } from '../field/field.component';
var ColorFieldComponent = /** @class */ (function (_super) {
    __extends(ColorFieldComponent, _super);
    function ColorFieldComponent(themeContext, cd) {
        var _this = _super.call(this) || this;
        _this.themeContext = themeContext;
        _this.cd = cd;
        _this.items = colors;
        _this.editPopoverOpened = false;
        _this.defaultAccentColor = defaultAccentColor;
        return _this;
    }
    Object.defineProperty(ColorFieldComponent.prototype, "allowEmpty", {
        get: function () {
            return this.field && this.field.params ? !!this.field.params['allow_empty'] : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorFieldComponent.prototype, "emptyColor", {
        get: function () {
            return this.field && this.field.params ? this.field.params['empty_color'] : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorFieldComponent.prototype, "emptyAccentColor", {
        get: function () {
            return this.field && this.field.params ? !!this.field.params['empty_accent_color'] : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorFieldComponent.prototype, "hasEmptyColor", {
        get: function () {
            return isSet(this.emptyColor) || isSet(this.emptyAccentColor);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorFieldComponent.prototype, "currentValueEffective", {
        get: function () {
            var value = this.currentValue;
            if (!isSet(value) && this.emptyAccentColor) {
                var accentColor = this.themeContext ? this.themeContext.getColorHex('accentColor') : undefined;
                return accentColor || defaultAccentColor;
            }
            else if (!isSet(value) && isSet(this.emptyColor)) {
                return this.emptyColor;
            }
            return value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorFieldComponent.prototype, "color", {
        get: function () {
            var _this = this;
            if (!this.currentValue) {
                return;
            }
            return this.items.find(function (item) { return item.name == _this.currentValue; });
        },
        enumerable: true,
        configurable: true
    });
    ColorFieldComponent.prototype.openEditPopover = function (value) {
        if (this.control && this.control.disabled) {
            return;
        }
        this.editPopoverOpened = value;
        this.cd.markForCheck();
    };
    ColorFieldComponent.prototype.setColor = function (color) {
        this.control.patchValue(color);
    };
    return ColorFieldComponent;
}(FieldComponent));
export { ColorFieldComponent };
registerFieldComponent(FieldType.Color, ColorFieldComponent);
