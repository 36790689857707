import { generateAlphanumeric } from '@shared';

export class GradientStop {
  id: string;
  position = 0;
  color: string;

  constructor(options: Partial<GradientStop> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.position = data['position'];
    this.color = data['color'];

    if (!this.id) {
      this.generateId();
    }

    return this;
  }

  serialize(): Object {
    return {
      position: this.position,
      color: this.color
    };
  }

  generateId() {
    this.id = generateAlphanumeric(4, { letterFirst: true });
  }
}
