<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <!--<input class="input"-->
  <!--[class.input_error]="(form | appFormFieldErrors:field.name)?.length"-->
  <!--[class.input_wide]="field.params['wide']"-->
  <!--[ngClass]="field.params['classes']"-->
  <!--type="text"-->
  <!--[formControlName]="field.name"-->
  <!--[id]="field.name | appUniqueId:idToken">-->
  <ng-container *ngIf="!modelDescription">Choose Collection first</ng-container>
  <ng-container *ngIf="filterItems && modelDescription">
    <!--    <app-filters [filterItems]="filterItems"-->
    <!--                 [columns]="modelDescription.dbFields"-->
    <!--                 (changed)="changeFilterItems($event)">-->
    <!--    </app-filters>-->

    <a
      href="javascript:void(0)"
      class="button button_small"
      [style.margin.px]="0"
      (click)="addFilter(filter_add)"
      #filter_add
    >
      <span class="icon button__icon_left icon-plus button__icon"></span>
      <span class="button__label">{{ 'Add filter' | localize }}</span>
    </a>
  </ng-container>
</app-field-wrapper>
<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  {{ formatEmpty(currentValue) }}
</app-field-wrapper>
