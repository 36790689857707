import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { InputFormatModule } from '@common/input-format';
import { TipsModule } from '@common/tips';
import { ColorsSharedModule } from '@modules/colors-shared';
import { SidebarModule } from '@modules/sidebar';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ColorOptionsComponent } from './components/color-options/color-options.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ColorSelectorComponent } from './components/color-selector/color-selector.component';
import { FillSelectorComponent } from './components/fill-selector/fill-selector.component';
import { GradientPositionPointComponent } from './components/gradient-position-point/gradient-position-point.component';
import { GradientPositionComponent } from './components/gradient-position/gradient-position.component';
import { GradientSelectorStopPositionDirective } from './components/gradient-selector-stop-position/gradient-selector-stop-position.directive';
import { GradientSelectorComponent } from './components/gradient-selector/gradient-selector.component';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    SharedModule,
    UiModule,
    SidebarModule,
    ReactiveFormsModule,
    TipsModule,
    ColorsSharedModule,
    InputFormatModule
  ],
  declarations: [
    ColorSelectorComponent,
    ColorPickerComponent,
    ColorOptionsComponent,
    FillSelectorComponent,
    GradientSelectorComponent,
    GradientSelectorStopPositionDirective,
    GradientPositionComponent,
    GradientPositionPointComponent
  ],
  exports: [
    ColorSelectorComponent,
    ColorPickerComponent,
    ColorOptionsComponent,
    FillSelectorComponent,
    GradientPositionComponent
  ]
})
export class ColorsComponentsModule {}
