/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../customize-bar/components/customize-bar-header/customize-bar-header.component.ngfactory";
import * as i2 from "../../../customize-bar/components/customize-bar-header/customize-bar-header.component";
import * as i3 from "../../../customize-bar/services/customize-bar-context/customize-bar.context";
import * as i4 from "../../../sidebar/components/sidebar-section/sidebar-section.component.ngfactory";
import * as i5 from "../../../sidebar/components/sidebar-section/sidebar-section.component";
import * as i6 from "../../../customize-bar/components/styles-element-wrapper-edit/styles-element-wrapper-edit.component.ngfactory";
import * as i7 from "../../../customize-bar/components/styles-element-wrapper-edit/styles-element-wrapper-edit.component";
import * as i8 from "../../../sidebar/components/sidebar-submit/sidebar-submit.component.ngfactory";
import * as i9 from "../../../sidebar/components/sidebar-submit/sidebar-submit.component";
import * as i10 from "../../../customize/services/customize/customize.service";
import * as i11 from "@angular/common";
import * as i12 from "./customize-bar-appearance-edit-element-wrappers.component";
import * as i13 from "../../../projects/stores/current-project.store";
import * as i14 from "../../../theme/services/theme/theme.service";
import * as i15 from "../../../routing/services/routing/routing.service";
var styles_CustomizeBarAppearanceEditElementWrappersComponent = [];
var RenderType_CustomizeBarAppearanceEditElementWrappersComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarAppearanceEditElementWrappersComponent, data: {} });
export { RenderType_CustomizeBarAppearanceEditElementWrappersComponent as RenderType_CustomizeBarAppearanceEditElementWrappersComponent };
export function View_CustomizeBarAppearanceEditElementWrappersComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-customize-bar-header", [], null, [[null, "backClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backClick" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CustomizeBarHeaderComponent_0, i1.RenderType_CustomizeBarHeaderComponent)), i0.ɵdid(1, 180224, null, 0, i2.CustomizeBarHeaderComponent, [[2, i3.CustomizeBarContext]], { title: [0, "title"], backEnabled: [1, "backEnabled"], backPopSettingsComponent: [2, "backPopSettingsComponent"], subtitle: [3, "subtitle"] }, { backClick: "backClick" }), (_l()(), i0.ɵeld(2, 0, null, 0, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "button-group"]], [[4, "margin", null]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "button-group__item"]], [[2, "button-group__item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.themeService.theme = "default") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "button-group__item-icon icon-sun"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "button-group__item"]], [[2, "button-group__item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.themeService.theme = "dark") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "button-group__item-icon icon-moon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 5, "div", [["class", "sidebar__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 4, "app-sidebar-section", [], null, null, null, i4.View_SidebarSectionComponent_0, i4.RenderType_SidebarSectionComponent)), i0.ɵdid(10, 114688, null, 0, i5.SidebarSectionComponent, [], null, null), (_l()(), i0.ɵeld(11, 0, null, 3, 2, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "app-styles-element-wrapper", [], null, null, null, i6.View_StylesElementWrapperEditComponent_0, i6.RenderType_StylesElementWrapperEditComponent)), i0.ɵdid(13, 245760, null, 0, i7.StylesElementWrapperEditComponent, [], { control: [0, "control"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 4, "app-sidebar-submit", [], null, [[null, "cancelClick"], [null, "submitClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancelClick" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } if (("submitClick" === en)) {
        var pd_1 = (_co.submit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_SidebarSubmitComponent_0, i8.RenderType_SidebarSubmitComponent)), i0.ɵdid(15, 770048, null, 0, i9.SidebarSubmitComponent, [i10.CustomizeService], { saveDisabled: [0, "saveDisabled"], saveLoading: [1, "saveLoading"], saveLabel: [2, "saveLabel"], cancelDisabled: [3, "cancelDisabled"], cancelLabel: [4, "cancelLabel"], hidden: [5, "hidden"] }, { cancelClick: "cancelClick", submitClick: "submitClick" }), i0.ɵpid(131072, i11.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i11.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i11.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Cards"; var currVal_1 = true; var currVal_2 = false; var currVal_3 = "Appearance"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); _ck(_v, 10, 0); var currVal_7 = _co.context.controls.appearance.controls.element_wrapper_styles; _ck(_v, 13, 0, currVal_7); var currVal_8 = !i0.ɵunv(_v, 15, 0, i0.ɵnov(_v, 16).transform(_co.hasChanges$)); var currVal_9 = _co.submitLoading; var currVal_10 = "Save changes"; var currVal_11 = !i0.ɵunv(_v, 15, 3, i0.ɵnov(_v, 17).transform(_co.hasChanges$)); var currVal_12 = "Reset changes"; var currVal_13 = !i0.ɵunv(_v, 15, 5, i0.ɵnov(_v, 18).transform(_co.hasChanges$)); _ck(_v, 15, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = "-5px 0"; _ck(_v, 3, 0, currVal_4); var currVal_5 = _co.themeService.isDefaultTheme; _ck(_v, 4, 0, currVal_5); var currVal_6 = _co.themeService.isDarkTheme; _ck(_v, 6, 0, currVal_6); }); }
export function View_CustomizeBarAppearanceEditElementWrappersComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-appearance-edit-element-wrappers", [], null, null, null, View_CustomizeBarAppearanceEditElementWrappersComponent_0, RenderType_CustomizeBarAppearanceEditElementWrappersComponent)), i0.ɵdid(1, 245760, null, 0, i12.CustomizeBarAppearanceEditElementWrappersComponent, [i10.CustomizeService, i13.CurrentProjectStore, i14.ThemeService, i15.RoutingService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarAppearanceEditElementWrappersComponentNgFactory = i0.ɵccf("app-customize-bar-appearance-edit-element-wrappers", i12.CustomizeBarAppearanceEditElementWrappersComponent, View_CustomizeBarAppearanceEditElementWrappersComponent_Host_0, { context: "context" }, {}, []);
export { CustomizeBarAppearanceEditElementWrappersComponentNgFactory as CustomizeBarAppearanceEditElementWrappersComponentNgFactory };
