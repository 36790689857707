<app-field-wrapper
  *ngIf="!readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <div
    *ngIf="!field.params['display_fields'] || !rootNode"
    class="field__editor field__editor_json"
    [class.field__editor_disabled]="control?.disabled"
    #editor_element
    (wheel)="onWheel($event)"
  ></div>
  <app-json-field-node
    *ngIf="field.params['display_fields'] && rootNode"
    [node]="rootNode"
    [form]="structureForm"
    [editable]="true"
    [theme]="field.params['theme']"
  >
  </app-json-field-node>
</app-field-wrapper>
<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <app-json-field-node
    *ngIf="field.params['display_fields'] && rootNode"
    [node]="rootNode"
    [form]="structureForm"
    [editable]="false"
    [theme]="field.params['theme']"
  >
  </app-json-field-node>
  <span *ngIf="!field.params['display_fields']" class="text-truncate">
    {{ formatEmpty(serializeValue(currentValue)) }}
  </span>
</app-field-wrapper>
