import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { ListModelDescriptionDataSource } from '@modules/data-sources';
import {
  BaseField,
  createFormFieldFactory,
  FieldDescription,
  FieldDescriptionLookup,
  getFieldDescriptionByType
} from '@modules/fields';
import { FilterItem2 } from '@modules/filters';
import { isSet } from '@shared';

import { ModelOption } from '../../data/model-option';
import { ModelOptionSelectedEvent } from '../select-model-field/select-model-field.component';
import { FieldValue, FilterEditForm } from './filter-edit.form';

@Component({
  selector: 'app-filter-edit',
  templateUrl: './filter-edit.component.html',
  providers: [FilterEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterEditComponent implements OnInit, OnDestroy {
  @Input() dataSource: ListModelDescriptionDataSource;
  @Input() filters: FilterItem2[] = [];
  @Input() filter: FilterItem2;
  @Input() field: string[];
  @Input() onlyVisible = false;
  @Input() optionsFilter: (option: ModelOption, path: ModelOption[]) => boolean;
  @Input() theme = false;
  @Output() added = new EventEmitter<FilterItem2>();
  @Output() edited = new EventEmitter<FilterItem2>();
  @Output() deleted = new EventEmitter<FilterItem2>();
  @Output() filtersUpdated = new EventEmitter<FilterItem2[]>();

  loading = false;
  fieldValue: FieldValue;
  fieldValueDescription: FieldDescription;
  lookups: FieldDescriptionLookup[] = [];
  excludeLookupsSupported = false;
  valueField: BaseField;
  createField = createFormFieldFactory();
  addedValueControl: FormControl;

  constructor(public form: FilterEditForm, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.loading = true;
    this.cd.markForCheck();

    this.form
      .init({
        dataSource: this.dataSource,
        filter: this.filter,
        field: this.field
      })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        combineLatest(this.form.getLookups$(), this.form.getValueField$())
          .pipe(untilDestroyed(this))
          .subscribe(
            ([lookups, valueField]) => {
              this.lookups = lookups.lookups;
              this.excludeLookupsSupported = lookups.excludeSupported;

              if (valueField) {
                const classes = valueField.params && valueField.params['classes'] ? valueField.params['classes'] : [];

                this.valueField = {
                  ...valueField,
                  params: {
                    ...valueField.params,
                    classes: ['select_fill', 'select_small', 'input_fill', 'input_small', ...classes],
                    theme: true
                  }
                };
              } else {
                this.valueField = undefined;
              }

              this.loading = false;
              this.cd.markForCheck();
            },
            () => {
              this.loading = false;
              this.cd.markForCheck();
            }
          );
      });

    this.form
      .getField$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.fieldValue = value;
        this.fieldValueDescription = value ? getFieldDescriptionByType(value.field.field) : undefined;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  selectField(e: ModelOptionSelectedEvent) {
    if (!e.field) {
      return;
    }

    this.form.controls.field.patchValue({
      path: e.path.map(item => item.name),
      field: e.field
    } as FieldValue);
  }

  public submit() {
    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(filterItem => {
        if (!filterItem) {
          return;
        }

        const applyFilterItem = isSet(filterItem.value) ? filterItem : undefined;

        if (this.filter) {
          const filters = this.filters
            .map(item => {
              if (item === this.filter) {
                return applyFilterItem;
              } else {
                return item;
              }
            })
            .filter(item => item);

          this.edited.emit(filterItem);
          this.filtersUpdated.emit(filters);
        } else {
          const filters = [...this.filters];

          if (applyFilterItem) {
            filters.push(applyFilterItem);
          }

          this.added.emit(filterItem);
          this.filtersUpdated.emit(filters);
        }

        return filterItem;
      });
  }

  removeFilter() {
    const filters = this.filters.filter(item => item !== this.filter);
    this.deleted.emit(this.filter);
    this.filtersUpdated.emit(filters);
  }

  addValueControl() {
    this.addedValueControl = this.form.controls.value_array.pushNew();
  }

  removeValueControl(index: number) {
    this.form.controls.value_array.removeAt(index);
  }
}
