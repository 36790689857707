var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CHART_COLORS, getDatasetsEffectiveColors } from '@modules/charts';
import { GROUP1_OUTPUT, GROUP2_OUTPUT, GROUP3_OUTPUT, VALUE_OUTPUT } from '@modules/dashboard';
import { BooleanFieldStyle } from '@modules/field-components';
import { SidebarCollapseContext } from '@modules/sidebar';
import { ThemeService } from '@modules/theme';
import { controlValue, isSet } from '@shared';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarBaseWidgetEditComponent } from '../customize-bar-base-widget-edit/customize-bar-base-widget-edit.component';
import { CustomizeBarChartWidgetEditDatasetComponent } from './customize-bar-chart-widget-edit-dataset/customize-bar-chart-widget-edit-dataset.component';
import { CustomizeBarChartWidgetEditForm } from './customize-bar-chart-widget-edit.form';
var CustomizeBarChartWidgetEditComponent = /** @class */ (function (_super) {
    __extends(CustomizeBarChartWidgetEditComponent, _super);
    function CustomizeBarChartWidgetEditComponent(cd, form, projectSettingsStore, themeService, customizeBarContext, analyticsService) {
        var _this = _super.call(this, form, customizeBarContext, analyticsService) || this;
        _this.cd = cd;
        _this.form = form;
        _this.projectSettingsStore = projectSettingsStore;
        _this.themeService = themeService;
        _this.collapseContext = new SidebarCollapseContext();
        _this.booleanFieldStyle = BooleanFieldStyle;
        _this.xDateLookup = false;
        _this.datasetsColors = [];
        _this.itemContextElementPaths = [[GROUP1_OUTPUT], [GROUP2_OUTPUT], [GROUP3_OUTPUT], [VALUE_OUTPUT]];
        _this.defaultColors$ = new BehaviorSubject(CHART_COLORS);
        return _this;
    }
    CustomizeBarChartWidgetEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        combineLatest(this.form.firstResource$(), this.form.firstModelDescription$(), this.form.xDateLookup$())
            .pipe(auditTime(1000 / 60), untilDestroyed(this))
            .subscribe(function (_a) {
            var resource = _a[0], modelDescription = _a[1], xDateLookup = _a[2];
            _this.resource = resource;
            _this.modelDescription = modelDescription;
            _this.xDateLookup = xDateLookup;
            _this.cd.markForCheck();
        });
        combineLatest(this.projectSettingsStore.getAllSettings$(), this.themeService.isDarkTheme$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var settings = _a[0], isDarkTheme = _a[1];
            var defaultColors = CHART_COLORS.map(function (defaultColor, i) {
                if (isDarkTheme) {
                    return settings && isSet(settings.accentColorDark[i]) ? settings.accentColorDark[i] : defaultColor;
                }
                else {
                    return settings && isSet(settings.autoColors[i]) ? settings.autoColors[i] : defaultColor;
                }
            });
            _this.defaultColors$.next(defaultColors);
        });
        combineLatest(controlValue(this.form.controls.datasets), this.defaultColors$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var datasets = _a[0], defaultColors = _a[1];
            var datasetColors = datasets.map(function (item) { return item.color; });
            _this.datasetsColors = getDatasetsEffectiveColors(datasetColors, defaultColors);
            _this.cd.markForCheck();
        });
        this.actionsValid$ = this.form.actionsValid$();
        var firstDataset = this.form.controls.datasets.controls[0];
        if (this.setupOnCreate && firstDataset) {
            this.editDataset(firstDataset, 0);
        }
    };
    CustomizeBarChartWidgetEditComponent.prototype.editDataset = function (control, index) {
        var datasetColors = this.form.controls.datasets.controls.map(function (item) { return item.controls.color.value; });
        var defaultColors = this.defaultColors$.value;
        this.customizeBarContext.appendSettingsComponent({
            component: CustomizeBarChartWidgetEditDatasetComponent,
            inputs: {
                widget: this.widget,
                chartType: this.form.controls.chart_type.value,
                form: control,
                groupsControl: this.form.controls.groups,
                datasetColumnEnabled: true,
                datasetColumnAllowed: index === 0,
                datasetColumnControl: this.form.controls.dataset_column,
                defaultTitle: "Dataset " + (index + 1),
                context: this.context,
                defaultColor: getDatasetsEffectiveColors(datasetColors, defaultColors, index)[index],
                defaultColors: defaultColors,
                singleColorDataset: this.form.isSingleColorDataset(),
                firstInit: this.firstInit,
                setupOnCreate: this.setupOnCreate
            },
            outputs: {
                delete: [function (e) { }]
            }
        });
    };
    CustomizeBarChartWidgetEditComponent.prototype.addDataset = function () {
        var item = this.form.createDataset();
        this.form.controls.datasets.append(item);
        this.editDataset(item, this.form.controls.datasets.controls.length - 1);
    };
    CustomizeBarChartWidgetEditComponent.prototype.getDatasetTitle = function (form, index) {
        if (form.controls.name.value) {
            return form.controls.name.value;
        }
        else {
            return "Dataset " + (index + 1);
        }
    };
    CustomizeBarChartWidgetEditComponent.prototype.dragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.controls.datasets.controls, event.previousIndex, event.currentIndex);
            this.form.controls.datasets.updateValueAndValidity();
        }
    };
    return CustomizeBarChartWidgetEditComponent;
}(CustomizeBarBaseWidgetEditComponent));
export { CustomizeBarChartWidgetEditComponent };
