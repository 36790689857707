import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { DragDrop2Module } from '@common/drag-drop2';
import { DynamicComponentModule } from '@common/dynamic-component';
import { EditableContentModule } from '@common/editable-content';
import { FormUtilsModule } from '@common/form-utils';
import { InputFormatModule } from '@common/input-format';
import { SelectModule } from '@common/select';
import { TipsModule } from '@common/tips';
import { AdminHeaderModule } from '@modules/admin-header';
import { ChartsComponentsModule } from '@modules/charts-components';
import { ColorsComponentsModule } from '@modules/colors-components';
import { ColorsSharedModule } from '@modules/colors-shared';
import { CustomizeBarModule } from '@modules/customize-bar';
import { CustomizeComponentsModule } from '@modules/customize-components';
import { CustomizeSharedModule } from '@modules/customize-shared';
import { CustomizeUiModule } from '@modules/customize-ui';
import { DomainComponentsModule } from '@modules/domain-components';
import { EmailComponentsModule } from '@modules/emails-components';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { IconsModule } from '@modules/icons';
import { IconsComponentsModule } from '@modules/icons-components';
import { IntegrationsComponentsModule } from '@modules/integrations-components';
import { LayoutComponentsModule } from '@modules/layout-components';
import { MenuComponentsModule } from '@modules/menu-components';
import { ParametersComponentsModule } from '@modules/parameters-components';
import { ProjectSettingsComponentsModule } from '@modules/project-settings-components';
import { RoutingModule } from '@modules/routing';
import { SidebarModule } from '@modules/sidebar';
import { TableModule } from '@modules/table';
import { ThemeComponentsModule } from '@modules/theme-components';
import { TutorialModule } from '@modules/tutorial';
import { UsersRoutesModule } from '@modules/users-routes';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { AddMenuItemMenuComponent } from './components/add-menu-item-menu/add-menu-item-menu.component';
import { AdminTemplatePageComponent } from './components/admin-template-page/admin-template-page.component';
import { AdminTemplateComponent } from './components/admin-template/admin-template.component';
import { AutoMenuItemEditComponent } from './components/auto-menu-item-edit/auto-menu-item-edit.component';
import { ButtonMenuItemEditComponent } from './components/button-menu-item-edit/button-menu-item-edit.component';
import { ButtonMenuItemPopoverComponent } from './components/button-menu-item-popover/button-menu-item-popover.component';
import { CustomMenuItemEditComponent } from './components/custom-menu-item-edit/custom-menu-item-edit.component';
import { CustomMenuItemPopoverComponent } from './components/custom-menu-item-popover/custom-menu-item-popover.component';
import { CustomizeBarAppearanceEditButtonsComponent } from './components/customize-bar-appearance-edit-buttons/customize-bar-appearance-edit-buttons.component';
import { CustomizeBarAppearanceEditElementWrappersComponent } from './components/customize-bar-appearance-edit-element-wrappers/customize-bar-appearance-edit-element-wrappers.component';
import { CustomizeBarAppearanceEditFieldsComponent } from './components/customize-bar-appearance-edit-fields/customize-bar-appearance-edit-fields.component';
import { CustomizeBarAppearanceEditGeneralComponent } from './components/customize-bar-appearance-edit-general/customize-bar-appearance-edit-general.component';
import { CustomizeBarAppearanceEditComponent } from './components/customize-bar-appearance-edit/customize-bar-appearance-edit.component';
import { CustomizeBarAuthEditComponent } from './components/customize-bar-auth-edit/customize-bar-auth-edit.component';
import { CustomizeBarCustomCodeEditComponent } from './components/customize-bar-custom-code-edit/customize-bar-custom-code-edit.component';
import { CustomizeBarDomainEditComponent } from './components/customize-bar-domain-edit/customize-bar-domain-edit.component';
import { CustomizeBarEmailsEditComponent } from './components/customize-bar-emails-edit/customize-bar-emails-edit.component';
import { CustomizeBarFeaturesEditComponent } from './components/customize-bar-features-edit/customize-bar-features-edit.component';
import { CustomizeBarGeneralEditComponent } from './components/customize-bar-general-edit/customize-bar-general-edit.component';
import { CustomizeBarIntegrationsEditComponent } from './components/customize-bar-integrations-edit/customize-bar-integrations-edit.component';
import { CustomizeBarLanguageRegionEditComponent } from './components/customize-bar-language-region-edit/customize-bar-language-region-edit.component';
import { CustomizeBarMenuEditComponent } from './components/customize-bar-menu-edit/customize-bar-menu-edit.component';
import { CustomizeBarPagesEditComponent } from './components/customize-bar-pages-edit/customize-bar-pages-edit.component';
import { CustomizeBarSharingEditComponent } from './components/customize-bar-sharing-edit/customize-bar-sharing-edit.component';
import { CustomizeBarSignUpCustomCodeEditComponent } from './components/customize-bar-sign-up-custom-code-edit/customize-bar-sign-up-custom-code-edit.component';
import { CustomizeBarSigninEditComponent } from './components/customize-bar-signin-edit/customize-bar-signin-edit.component';
import { CustomizeBarSignupAppearanceEditComponent } from './components/customize-bar-signup-appearance-edit/customize-bar-signup-appearance-edit.component';
import { CustomizeBarSignupEditComponent } from './components/customize-bar-signup-edit/customize-bar-signup-edit.component';
import { SignUpFieldControlComponent } from './components/customize-bar-signup-edit/sign-up-field-control/sign-up-field-control.component';
import { EditMenuItemActionComponent } from './components/edit-menu-item-action/edit-menu-item-action.component';
import { ImageMenuItemEditComponent } from './components/image-menu-item-edit/image-menu-item-edit.component';
import { ImageMenuItemPopoverComponent } from './components/image-menu-item-popover/image-menu-item-popover.component';
import { MenuBlockAddComponent } from './components/menu-block-add/menu-block-add.component';
import { MenuBlockEditComponent } from './components/menu-block-edit/menu-block-edit.component';
import { MenuBlockLayoutOverlayComponent } from './components/menu-block-layout-overlay/menu-block-layout-overlay.component';
import { MenuSettingsComponent } from './components/menu-settings/menu-settings.component';
import { ModelMenuItemEditComponent } from './components/model-menu-item-edit/model-menu-item-edit.component';
import { ModelMenuItemPopoverComponent } from './components/model-menu-item-popover/model-menu-item-popover.component';
import { ProjectSettingsComponent } from './components/project-settings/project-settings.component';
import { SectionMenuItemEditComponent } from './components/section-menu-item-edit/section-menu-item-edit.component';
import { SectionMenuItemPopoverComponent } from './components/section-menu-item-popover/section-menu-item-popover.component';
import { SeparatorMenuItemEditComponent } from './components/separator-menu-item-edit/separator-menu-item-edit.component';
import { SeparatorMenuItemPopoverComponent } from './components/separator-menu-item-popover/separator-menu-item-popover.component';
import { ShareMenuItemEditComponent } from './components/share-menu-item-edit/share-menu-item-edit.component';
import { ShareMenuItemPopoverComponent } from './components/share-menu-item-popover/share-menu-item-popover.component';
import { SignUpBuilderComponent } from './components/sign-up-builder/sign-up-builder.component';
import { SimpleMenuItemEditComponent } from './components/simple-menu-item-edit/simple-menu-item-edit.component';
import { SimpleMenuItemPopoverComponent } from './components/simple-menu-item-popover/simple-menu-item-popover.component';
import { StylesPreviewButtonStyleComponent } from './components/styles-preview-button-style/styles-preview-button-style.component';
import { StylesPreviewButtonComponent } from './components/styles-preview-button/styles-preview-button.component';
import { StylesPreviewFieldComponent } from './components/styles-preview-field/styles-preview-field.component';
import { ThemeGalleryItemPreviewComponent } from './components/theme-gallery-item-preview/theme-gallery-item-preview.component';
import { ThemeGalleryItemComponent } from './components/theme-gallery-item/theme-gallery-item.component';
import { ThemeGalleryComponent } from './components/theme-gallery/theme-gallery.component';
import { ColorThemeOptionsDirective } from './directives/color-theme-options/color-theme-options.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutComponentsModule,
    NgGxScrollableModule,
    DynamicComponentModule,
    SidebarModule,
    CustomizeBarModule,
    DragDropModule,
    EditableContentModule,
    OverlayModule,
    FieldsModule,
    MatMenuModule,
    SelectModule,
    RoutingModule,
    DragDrop2Module,
    TipsModule,
    UiModule,
    IconsModule,
    SharedModule,
    FieldComponentsModule,
    ProjectSettingsComponentsModule,
    DomainComponentsModule,
    EmailComponentsModule,
    IntegrationsComponentsModule,
    AdminHeaderModule,
    UsersRoutesModule,
    MenuComponentsModule,
    FormUtilsModule,
    TutorialModule,
    ParametersComponentsModule,
    ColorsComponentsModule,
    IconsComponentsModule,
    InputFormatModule,
    ThemeComponentsModule,
    CustomizeUiModule,
    ColorsSharedModule,
    ChartsComponentsModule,
    TableModule,
    CustomizeComponentsModule,
    CustomizeSharedModule
  ],
  declarations: [
    ProjectSettingsComponent,
    CustomizeBarPagesEditComponent,
    AutoMenuItemEditComponent,
    SectionMenuItemEditComponent,
    SectionMenuItemPopoverComponent,
    SimpleMenuItemEditComponent,
    SimpleMenuItemPopoverComponent,
    ModelMenuItemEditComponent,
    ModelMenuItemPopoverComponent,
    ImageMenuItemEditComponent,
    ImageMenuItemPopoverComponent,
    ButtonMenuItemEditComponent,
    ButtonMenuItemPopoverComponent,
    SeparatorMenuItemEditComponent,
    SeparatorMenuItemPopoverComponent,
    ShareMenuItemEditComponent,
    ShareMenuItemPopoverComponent,
    CustomMenuItemEditComponent,
    CustomMenuItemPopoverComponent,
    SignUpBuilderComponent,
    CustomizeBarGeneralEditComponent,
    CustomizeBarSignupEditComponent,
    CustomizeBarCustomCodeEditComponent,
    CustomizeBarSignUpCustomCodeEditComponent,
    CustomizeBarMenuEditComponent,
    CustomizeBarDomainEditComponent,
    CustomizeBarFeaturesEditComponent,
    CustomizeBarAppearanceEditComponent,
    CustomizeBarAppearanceEditGeneralComponent,
    CustomizeBarAppearanceEditButtonsComponent,
    CustomizeBarAppearanceEditElementWrappersComponent,
    CustomizeBarAppearanceEditFieldsComponent,
    CustomizeBarLanguageRegionEditComponent,
    CustomizeBarSignupAppearanceEditComponent,
    CustomizeBarSigninEditComponent,
    CustomizeBarSharingEditComponent,
    CustomizeBarAuthEditComponent,
    CustomizeBarEmailsEditComponent,
    CustomizeBarIntegrationsEditComponent,
    SignUpFieldControlComponent,
    AddMenuItemMenuComponent,
    MenuBlockEditComponent,
    MenuBlockAddComponent,
    MenuBlockLayoutOverlayComponent,
    EditMenuItemActionComponent,
    MenuSettingsComponent,
    ColorThemeOptionsDirective,
    ThemeGalleryItemComponent,
    ThemeGalleryComponent,
    AdminTemplateComponent,
    StylesPreviewButtonComponent,
    StylesPreviewButtonStyleComponent,
    StylesPreviewFieldComponent,
    AdminTemplatePageComponent,
    ThemeGalleryItemPreviewComponent
  ],
  exports: [StylesPreviewButtonComponent],
  entryComponents: [
    CustomizeBarGeneralEditComponent,
    CustomizeBarPagesEditComponent,
    CustomizeBarSignupEditComponent,
    CustomizeBarCustomCodeEditComponent,
    CustomizeBarSignUpCustomCodeEditComponent,
    CustomizeBarMenuEditComponent,
    CustomizeBarDomainEditComponent,
    CustomizeBarFeaturesEditComponent,
    CustomizeBarAppearanceEditComponent,
    CustomizeBarAppearanceEditGeneralComponent,
    CustomizeBarAppearanceEditButtonsComponent,
    CustomizeBarAppearanceEditElementWrappersComponent,
    CustomizeBarAppearanceEditFieldsComponent,
    CustomizeBarLanguageRegionEditComponent,
    CustomizeBarSignupAppearanceEditComponent,
    CustomizeBarSigninEditComponent,
    CustomizeBarSharingEditComponent,
    CustomizeBarAuthEditComponent,
    CustomizeBarEmailsEditComponent,
    CustomizeBarIntegrationsEditComponent
  ]
})
export class LayoutRoutesModule {}
