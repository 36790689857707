import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { getColorHexStr } from '@modules/colors';
import { TextStyle } from '@modules/customize';
import { getFontFamilyVariable } from '@modules/styles';
import { ThemeService } from '@modules/theme';
import { isSet } from '@shared';
var TextStyleDirective = /** @class */ (function () {
    function TextStyleDirective(projectSettingsStore, themeService, sanitizer, cd) {
        this.projectSettingsStore = projectSettingsStore;
        this.themeService = themeService;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.color = null;
        this.backgroundColor = null;
    }
    Object.defineProperty(TextStyleDirective.prototype, "style", {
        get: function () {
            return this.customStyle || this.globalStyle;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextStyleDirective.prototype, "fontFamily", {
        get: function () {
            if (this.style && isSet(this.style.fontFamily)) {
                var variable = getFontFamilyVariable(this.style.fontFamily);
                if (isSet(variable)) {
                    return this.sanitizer.bypassSecurityTrustStyle("var(--" + variable + ")");
                }
                else {
                    return this.style.fontFamily;
                }
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextStyleDirective.prototype, "fontSizePx", {
        get: function () {
            if (this.style && isSet(this.style.fontSize)) {
                return this.style.fontSize;
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextStyleDirective.prototype, "fontWeight", {
        get: function () {
            if (this.style && isSet(this.style.fontWeight)) {
                return this.style.fontWeight;
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextStyleDirective.prototype, "fontStyle", {
        get: function () {
            if (this.style && isSet(this.style.fontStyle)) {
                return this.style.cssFontStyle();
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextStyleDirective.prototype, "textBackgroundCls", {
        get: function () {
            return isSet(this.backgroundColor);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextStyleDirective.prototype, "textBackgroundThemeCls", {
        get: function () {
            return isSet(this.backgroundColor);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextStyleDirective.prototype, "letterSpacingPx", {
        get: function () {
            if (this.style && isSet(this.style.letterSpacing)) {
                return this.style.letterSpacing;
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextStyleDirective.prototype, "textTransform", {
        get: function () {
            if (this.style && isSet(this.style.transform)) {
                return this.style.cssTextTransform();
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextStyleDirective.prototype, "textDecoration", {
        get: function () {
            if (this.style && isSet(this.style.decoration)) {
                return this.style.cssTextDecoration();
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    TextStyleDirective.prototype.ngOnInit = function () {
        // const settings$ = this.projectSettingsStore
        //   ? this.projectSettingsStore.getAllSettings$()
        //   : of<AllProjectSettings>(undefined);
        //
        // settings$.pipe(untilDestroyed(this)).subscribe(settings => {
        //   this.globalStyle = settings ? (settings[this.globalSetting] as TextStyle) : undefined;
        //   this.cd.markForCheck();
        //   this.updateColor();
        // });
        var _this = this;
        var theme$ = this.themeService ? this.themeService.theme$ : of(undefined);
        theme$.pipe(untilDestroyed(this)).subscribe(function (theme) {
            _this.updateColorForTheme(theme);
        });
    };
    TextStyleDirective.prototype.ngOnDestroy = function () { };
    TextStyleDirective.prototype.ngOnChanges = function (changes) {
        if (changes.customStyle && !changes.customStyle.firstChange) {
            this.updateColor();
        }
        if (changes.customColor && !changes.customColor.firstChange) {
            this.updateColor();
        }
        if (changes.customBackgroundColor && !changes.customBackgroundColor.firstChange) {
            this.updateColor();
        }
    };
    TextStyleDirective.prototype.updateColorForTheme = function (theme) {
        if (isSet(this.customColor)) {
            this.color = getColorHexStr(this.customColor);
        }
        else if (theme == 'dark' && this.style && isSet(this.style.colorDark)) {
            this.color = this.style.colorDark;
        }
        else if (theme != 'dark' && this.style && isSet(this.style.color)) {
            this.color = this.style.color;
        }
        else {
            this.color = null;
        }
        if (theme == 'dark' && this.style && isSet(this.style.backgroundColorDark)) {
            if (isSet(this.customBackgroundColor)) {
                this.backgroundColor = getColorHexStr(this.customBackgroundColor);
            }
            else {
                this.backgroundColor = this.style.backgroundColorDark;
            }
        }
        else if (theme != 'dark' && this.style && isSet(this.style.backgroundColor)) {
            if (isSet(this.customBackgroundColor)) {
                this.backgroundColor = getColorHexStr(this.customBackgroundColor);
            }
            else {
                this.backgroundColor = this.style.backgroundColor;
            }
        }
        else {
            this.backgroundColor = null;
        }
        this.cd.markForCheck();
    };
    TextStyleDirective.prototype.updateColor = function () {
        var theme = this.themeService ? this.themeService.theme : 'dark';
        this.updateColorForTheme(theme);
    };
    return TextStyleDirective;
}());
export { TextStyleDirective };
